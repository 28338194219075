import React, { useState, useEffect } from "react";
import { StyledVariantCon, StyledVariantItemCon } from "theme";
import { CardCom, TextCom, InputCom } from "../../common";
import { BiChevronDown } from "react-icons/bi";
import {
  useHandleVariant,
  useHandleCap,
  useTheme,
  useHandleOther,
  useOther,
  useProduct,
} from "hook";

type AttributeButtonModProps = {
  variant_type?: string | undefined;
  cap_type?: string | undefined;
  package_product_index?: any;
  handleUseState?: any;
  data?: any;
};

export const AttributeButtonMod: React.FC<AttributeButtonModProps> = ({
  data,
  package_product_index,
  ...props
}) => {
  const { getProductById_data, isLoading } = useProduct();
  const [variants, handleVariant] = useHandleVariant(props);
  const [caps, handleCap] = useHandleCap(props);
  const { langStore } = useOther();
  const langCode = langStore.code;
  const { themeContext } = useTheme();
  const { translate } = useHandleOther();
  const [clickcapselect, setclickcapselect] = useState<any | null>(null);
  const handleStyle = (z: any) => {
    let txt_obj = {
      marginRight: 5,
      borderRadius: 25,
      marginBottom: 7,
      borderColor: z.check
        ? themeContext?.color?.card?.primary || "#000"
        : themeContext?.color?.card?.line || "#dedede",
      paddingBlock: "3px",
      paddingInline: "10px",
      cursor: z.stock_balance === 0 ? "" : "pointer",
      minWidth: "60px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      opacity: z.stock_balance === 0 ? "0.5" : "1",
    };
    let photo_obj = {
      marginRight: 15,
      borderRadius: 5,
      marginBottom: 7,
      paddingLeft: 2,
      paddingRight: 2,
      paddingBottom: 2,
      paddingTop: 2,
      borderColor: z.check
        ? themeContext?.color?.card?.primary || "#000"
        : themeContext?.color?.card?.line || "#dedede",
      cursor: z.stock_balance === 0 ? "" : "pointer",
      opacity: z.stock_balance === 0 ? "0.5" : "1",
    };

    return z?.photo ? photo_obj : txt_obj;
  };

  useEffect(() => {}, [clickcapselect]);

  return (
    <div className="attribute-con">
      {props?.variant_type === "package_product"
        ? data?.attributes?.data?.length > 0 &&
          data?.attributes?.data?.map((item: any) => (
            <div className="d-flex gap-5 align-items-center">
              <TextCom
                weight="lg"
                style={{ marginBottom: 3, minWidth: "70px" }}
              >
                {item?.name} :{" "}
              </TextCom>
              <div className="d-flex">
                {item?.vals?.length > 0 &&
                  item?.vals?.map((z: any, index: number) => (
                    <CardCom
                      key={index}
                      style={handleStyle(z)}
                      onClick={() =>
                        z.stock_balance !== 0
                          ? handleVariant(
                              item?.id,
                              z?.id,
                              package_product_index,
                              data
                            )
                          : ""
                      }
                    >
                      {z?.photo ? (
                        <img
                          src={z?.photo}
                          data-tip
                          data-for="registerTip"
                          title={z?.name}
                          style={{ width: 50, height: 50 }}
                        />
                      ) : (
                        <TextCom size="xs">{z?.name}</TextCom>
                      )}
                    </CardCom>
                  ))}
              </div>
            </div>
          ))
        : variants?.length > 0 &&
          variants?.map((x: any, i: number) => (
            <StyledVariantCon
              key={i}
              className=""
              style={{ marginBottom: "10px" }}
            >
              <TextCom
                weight="xl"
                style={{ marginBottom: 5, minWidth: "70px" }}
              >
                {x?.name}
              </TextCom>
              {x?.vals?.length > 0 && (
                <StyledVariantItemCon
                  className={x?.vals[0]?.photo !== false ? "photo-var" : ""}
                >
                  {x?.vals[0]?.photo ? (
                    x?.vals?.map((z: any, index: number) => (
                      <CardCom
                        key={index}
                        style={handleStyle(z)}
                        onClick={() =>{
                          handleVariant(x?.id, z?.id, data, null, "variant", props?.handleUseState?.uid)
                          z.stock_balance == 0 && props.handleUseState.setHandleImage("Out of Stock")
                          }
                        }
                        className="att-card"
                      >
                        <img
                          src={z?.photo}
                          title={z.name}
                          style={{ width: 50, height: 50 }}
                        />
                      </CardCom>
                    ))
                  ) : (
                    <div className="select-wrap">
                      <BiChevronDown className="arrow" size={20} />
                      <InputCom
                        isValidate={false}
                        input_as="select"
                        onChange={(e: any) => {
                          handleVariant(
                            x?.id,
                            Number(e.target.value),
                            data,
                            null,
                            "variant", 
                            props?.handleUseState?.uid
                          );
                          props.handleUseState.setHandleAttr([
                            x?.id,
                            Number(e.target.value),
                          ]);
                        }}
                        className="select-variant"
                      >
                        <option
                          value={""}
                          selected
                          disabled
                          hidden
                        >{`Select ${x?.name}`}</option>
                        {x?.vals?.map((z: any, key: any) => {
                          return (
                            <option value={z?.id} key={key}>
                              {z?.name}
                            </option>
                          );
                        })}
                      </InputCom>
                    </div>
                  )}
                </StyledVariantItemCon>
              )}
            </StyledVariantCon>
          ))}
      {getProductById_data.cap_data &&
      getProductById_data.cap_data[0]?.length > 0 ? (
        <>
          <StyledVariantCon
            key={"CapType"}
            className=""
            style={{ marginBottom: "10px" }}
          >
            <TextCom weight="xl" style={{ marginBottom: 5, minWidth: "70px" }}>
              {`Cap Type`}
            </TextCom>

            {/* this is cap select box */}
            {caps?.length > 0 && (
              <StyledVariantItemCon>
                <div className="select-wrap">
                  <BiChevronDown className="arrow" size={20} />
                  <InputCom
                    isValidate={false}
                    input_as="select"
                    onChange={(e: any) => {
                      props.handleUseState.setHandleCapColor(null);
                      handleCap(
                        Number(e.target.value) || null,
                        null,
                        data,
                        null,
                        "cap", 
                        props?.handleUseState?.uid
                      );
                      props.handleUseState.setHandleCapType(
                        Number(e.target.value) || null
                      );
                      setclickcapselect(Number(e.target.value));
                    }}
                    className="select-variant"
                  >
                    <option
                      value={""}
                      selected
                      disabled={
                        props.handleUseState.handleCapType == null
                          ? true
                          : false
                      }
                      hidden={
                        props.handleUseState.handleCapType == null
                          ? true
                          : false
                      }
                      key={0}
                    >
                      {props.handleUseState.handleCapType == null
                        ? `Select Cap`
                        : `Unselect Cap`}
                    </option>
                    {caps?.map(
                      (x: any, i: number) =>
                        x?.color_info?.length > 0 && (
                          <option value={x?.id} key={x?.id}>
                            {x?.name}
                          </option>
                        )
                    )}
                  </InputCom>
                </div>
              </StyledVariantItemCon>
            )}
          </StyledVariantCon>

          <StyledVariantCon
            key={1}
            className=""
            style={{ marginBottom: "10px" }}
          >
            {
              <StyledVariantItemCon>
                <TextCom
                  weight="xl"
                  style={{ marginBottom: 5, minWidth: "70px" }}
                >
                  {`Cap Color`}
                </TextCom>
                <div className="select-wrap">
                  <BiChevronDown className="arrow" size={20} />
                  <InputCom
                    isValidate={false}
                    input_as="select"
                    onChange={(e: any) => {
                      handleCap(
                        props.handleUseState.handleCapType || null,
                        Number(e.target.value) || null,
                        data,
                        null,
                        "cap_color", 
                        props?.handleUseState?.uid
                      );
                      props.handleUseState.setHandleCapColor(
                        Number(e.target.value) || null
                      );
                    }}
                    className="select-variant"
                    disabled={
                      props.handleUseState.handleCapType != null ? false : true
                    }
                  >
                    <option
                      value={""}
                      selected={props.handleUseState.handleCapColor == null && true}
                      disabled={
                        props.handleUseState.handleCapType == null
                          ? true
                          : false
                      }
                      hidden={false}
                      key={0}
                    >{`Select Cap Color`}</option>
                    {
                      caps
                        ? caps.find(
                            (x: any) =>
                              x.id === props.handleUseState.handleCapType
                          )?.color_info?.length > 0 &&
                          caps
                            .find(
                              (y: any) =>
                                y.id === props.handleUseState.handleCapType
                            )
                            ?.color_info?.map((z: any, key: any) => {
                              return (
                                <option value={z?.id} key={
                                  caps.find(
                                    (y: any) =>
                                      y.id === props.handleUseState.handleCapType
                                    ).id
                                  + z?.id
                                  }>
                                  {z?.color}
                                  {/* {caps.find(
                                    (y: any) =>
                                      y.id === props.handleUseState.handleCapType
                                    ).id
                                  +""+z?.id} */}
                                </option>
                              );
                            })
                        : null
                      // caps?.map((x: any, i: number) => (
                      //   x?.color_info?.length > 0 && (

                      //     x?.color_info?.map((z: any, key: any) => {
                      //       return (
                      //         <option value={z?.id} key={key}>
                      //           {z?.color}
                      //         </option>
                      //       );
                      //     })
                      //   )))
                    }
                  </InputCom>
                </div>
              </StyledVariantItemCon>
            }
          </StyledVariantCon>
        </>
      ) : null}
    </div>
  );
};
