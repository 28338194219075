import styled from "styled-components";

export const StyledSearchCom = styled.div`
      display: flex;
      width: fit-content;
      position: relative;
      width: 60%;
      @media(max-width: 1399px){
        width: 55%;
      }
      @media(max-width: 1200px){
        width: 48%;
      }
      @media(max-width: 1024px){
            width: 50%;
        }

      .drop-down{
        position: absolute;
        width: 100%;
        top: 100%;
        background: ${props => props?.theme?.color?.typo?.light || '#fff'};
        list-style: none;
        padding-inline: 10px;
        box-shadow: 0px 4px 10px #00000014;
        z-index: 10000;
        li{
          padding: 8px;
          border-bottom: 1px solid ${props => props?.theme?.color?.typo?.gray || '#ddd'};
          &:last-child{
            border: none;
          }
          cursor: pointer;
          @media(max-width: 425px){
            p{
              font-size: ${props => props?.theme?.fontSize?.xs || 12}px;
            }
          }
        }
      }
      .search{
        border: 1px solid ${props => props?.theme?.color?.header?.primary_light1 || '#ddd'};
        border-radius: 10px;
        /* overflow: hidden; */
        width: 100%;
        .field-wrapper{
          width: 100%;
        }
      }
      input {
          min-width: 100%;
          height: 43px;
          border: none!important;
          /* border-color: ${props => props?.theme?.color?.typo?.line}; */
          /* border-radius: 5px; */
          @media (max-width: 1300px) {
            height: 40px;
          }
          border-radius: 10px 0 0 10px;
          @media (max-width: 991px) {
            &::placeholder{
              font-size: ${props => props?.theme?.fontSize?.sm || 14}px;
            }
          }
        }
        
        
      img{
          /* position: absolute; */
          /* top: 8px;
          right: 5px; */
          width: 20px;
          height: 20px;
          max-width: 26px;
        }
      @media (max-width: 1300px) {
        gap: 10px;
      }
      button{
        border-radius: none!important;
        position: absolute;
        right: 0;
        min-width: fit-content;
        p{
          text-transform: initial;
        }
      }
      .icon-wrap{
        background: ${props => props?.theme?.color?.header?.primary_bold || '#ddd'};
        justify-content: center;
        align-items: center;
        padding-inline: 12px;
        border-radius: 0 10px 10px 0;
      }
      @media(max-width: 991px){
        width: 100%;
        .icon-wrap{
          p{
            display: none;
          }
        }
      }
`