import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FiUser, FiHeart, FiFileText, FiInfo } from "react-icons/fi";
import { FaQuestion } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import Media from "react-media";

import {
  TextCom,
  CardCom,
  ButtonCom,
  Container,
  NewTabCom,
  TableCom,
  ModalCom,
  QtyButtonMod,
  AddToCartButtonMod,
  StockVariantMsgMod,
  StaticSidebar,
  BreadcrumbCom,
} from "components";
import { useHandleOther, useHandleWishlist, useProduct } from "hook";
import { moneyFormat } from "utils";
import { StyledWishlistCon } from "theme";
import Image from "asset/icon/pladco";
import "./style.scss";



type IWishlistProps = {};

export const Wishlist: React.FC<IWishlistProps> = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { translate, langStore } = useHandleOther();
  const langCode = langStore?.code;
  const { isLoading } = useProduct();
  const { getWishlist_data, handleWislistItemDelete, getWishlist } =
    useHandleWishlist(props);
  const [isModal, setIsModal] = useState({ open: false, item: null });
  useEffect(() => {
    getWishlist();
  }, []);
  const dataSource = [
    {
      title: translate("profile", "profile"),
      key: "profile",
      desc: " ",
      link: "/user/profile",
      icon: <FiUser color="#262626" />,
    },
    {
      title: translate("wishlist", "Wishlist"),
      key: "wishlist",
      icon: <FiHeart color="#262626" />,
    },
    {
      title: translate("order-history", "Order History"),
      key: "order-history",
      link: "/user/my_orders",
      icon: <FiFileText color="#262626" />,
    },
  ];

  const columns = [
    {
      key: "name",
      width: 300,
      render: (x: any, y: any) => (
        <div className="product_name_wrap d-flex">
          <img src={x?.image || Image.DefaultCard} />
          <div>
            <TextCom weight="xl">{x?.name || Image.DefaultCard}</TextCom>
            <StockVariantMsgMod
              from="other"
              item={getWishlist_data[getWishlist_data.indexOf(x)]}
            />
            {x?.code && (
              <TextCom weight="xl">
                {translate("sku", "SKU")} : <span>{x?.code}</span>
              </TextCom>
            )}
          </div>
        </div>
      ),
    },
    {
      key: "price",
      width: 150,
      render: (x: any, y: any) => (
        <div className="price-wrap">
          <TextCom size="lg" textAlign="right" color="primary_light2">
            {moneyFormat(x?.list_price, langCode)} {translate("ks", "Ks")}
          </TextCom>
          {/* { x?.promotion?.length>0 &&
          <TextCom textAlign='center' color='paragraph'><del>1,500ks</del></TextCom>
        } */}
          {/* <TextCom textAlign='right' color='paragraph'><del>1,500{translate('ks', 'Ks')}</del></TextCom> */}
        </div>
      ),
    },
    {
      key: "qty",
      textAlign: "center",
      width: 150,
      render: (x: any, y: any) => (
        <div className="qty-wrap d-flex flex-column gap-2">
          <QtyButtonMod
            type="wishlist"
            item={x}
            w_index={getWishlist_data.indexOf(x)}
            disabled={isLoading}
          />
        </div>
      ),
    },
    {
      key: "add-to-cart",
      width: 150,
      render: (x: any, y: any) => (
        <div className="btn-wrap">
          <AddToCartButtonMod
            type_for="wishlist"
            type="addtocart"
            navigate={navigate}
            text={translate("add-to-cart", "Add to cart")}
            w_item={x}
            bgColor="primary_light2"
            color="light"
            btnBorderRadius="xxxs"
          />
        </div>
      ),
    },
    {
      key: "buy-now",
      width: 150,
      render: (x: any, y: any) => (
        <div className="btn-wrap buynow">
          <AddToCartButtonMod
            type_for="buynow"
            type="buynow"
            navigate={navigate}
            params={x?.product_template_id}
            w_item={x}
            bgColor="light"
            color="dark"
            outline
            borderColor="dark"
            text={translate("buy-now", "Buy now")}
            btnBorderRadius="xxxs"
          />
        </div>
      ),
    },
    {
      key: "action",
      width: 50,
      placement: "right",
      render: (x: any, y: any) => (
        <div className="cancel_btn" onClick={() => handleDelete(x, "delete")}>
          <AiFillCloseCircle color="#DDDDDD" size={25} />
        </div>
      ),
    },
  ];

  const mb_columns = [
    {
      key: "name",
      textAlign: "left",
      render: (x: any, y: any) => (
        <>
          <div className="product_name_wrap d-flex">
            <img src={x?.image || Image.DefaultCard} />
            <div>
              <TextCom weight="xl">{x?.name}</TextCom>
              {x?.code && (
                <TextCom weight="xl">
                  {translate("sku", "SKU")} : <span>{x?.code}</span>
                </TextCom>
              )}
              <StockVariantMsgMod
                from="other"
                item={getWishlist_data[getWishlist_data.indexOf(x)]}
              />
              <div className="price-wrap">
                <TextCom weight="xl" size="lg" color="primary_light2">
                  {moneyFormat(x?.list_price, langCode)} {translate("ks", "Ks")}
                </TextCom>
                {/* { x?.promotion?.length>0 &&
                <TextCom textAlign='center' color='paragraph'><del>1,500ks</del></TextCom>
                } */}
                <TextCom color="paragraph">
                  {/* <del>1,500{translate('ks', 'Ks')}</del> */}
                </TextCom>
                <div
                  className="cancel_btn"
                  onClick={() => handleDelete(x, "item")}
                >
                  <AiFillCloseCircle color="#DDDDDD" size={25} />
                </div>
              </div>
            </div>
          </div>
          <div className="btn-con d-flex">
            <div className="qty-wrap">
              <QtyButtonMod
                type="wishlist"
                item={x}
                w_index={getWishlist_data.indexOf(x)}
                disabled={isLoading}
              />
              {/* <QtyButtonCom /> */}
            </div>
            <div className="d-flex gap-2">
              <AddToCartButtonMod
                type_for="wishlist"
                type="addtocart"
                navigate={navigate}
                text={translate("add-to-cart", "Add to cart")}
                w_item={x}
                bgColor="primary_light2"
                color="light"
                btnBorderRadius="xxxs"
              />
              <div className="btn-wrap buynow">
                <AddToCartButtonMod
                  type_for="buynow"
                  navigate={navigate}
                  params={x?.product_template_id}
                  w_item={x}
                  bgColor="light"
                  color="dark"
                  outline
                  borderColor="dark"
                  text={translate("buy-now", "Buy now")}
                  btnBorderRadius="xxxs"
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  const handleDelete = async (item: any, type: any) => {
    setIsModal({ ...isModal, open: true, item: item });
  };

  const handleDeleteWishlist = async (w_id: any) => {
    await handleWislistItemDelete(w_id);
    setIsModal({ ...isModal, open: false });
  };

  const sidebar_data = [
    {
      name: translate("my-profile", "My Profile"),
      link: "/user/profile",
    },
    {
      name: translate("my-order", "My Order"),
      link: "/user/my_orders",
    },
    {
      name: translate("my-wishlist", "My Wishlist"),
      link: "/user/wishlist",
    },
    {
      name: translate("change-password", "My Wishlist"),
      link: "/user/change_password",
    },
    {
      name: translate("logout", "Logout"),
      link: "logout",
    },
  ];
  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    {
      name: translate("my-wishlist", "My Wishlist"),
      route: "#",
    },
  ];

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      <StyledWishlistCon>
        <Container className="wishlist-con container">
          <div className="row wishlist-row">
            <div className="col-md-12 col-lg-3">
              <StaticSidebar
                data={sidebar_data}
                route=""
                classname="side-bar"
              />
            </div>
            <div className="col-md-12 col-lg-9 wishlist-table">
              <div className="title">
                <TextCom size="xxl" weight="lg">
                  {translate("my-wishlist", "My Wishlist")}
                </TextCom>
              </div>
              {getWishlist_data ? (
                <Media query={{ minWidth: 992 }}>
                  {(matches) => (
                    <TableCom
                      dataSource={getWishlist_data}
                      columns={matches ? columns : mb_columns}
                    />
                  )}
                </Media>
              ) : (
                <div className="no-item">
                  <div className="d-flex justify-content-center align-items-center flex-column gap-3">
                    <img src={Image.NoWishlist} />
                    <TextCom>
                      {translate(
                        "empty-wishlist-information",
                        `There's no item in Wishlist`
                      )}{" "}
                      !
                    </TextCom>
                    <TextCom
                      as="a"
                      href="/"
                      className="shopping"
                      color="secondary"
                    >
                      {translate("continue-shopping", "Continue Shopping")}
                    </TextCom>
                  </div>
                </div>
              )}
            </div>
            <ModalCom isModal={isModal.open} handleIsModal={setIsModal}>
              <div className="inner_modal_con d-flex flex-column justify-content-center align-items-center">
                <FaQuestion size={30} />
                <TextCom className="desc_txt">
                  {translate("delete-this-item", "delete-this-item")}?
                </TextCom>
                <div className="modal_btn_con d-flex">
                  <ButtonCom
                    text={translate("cancel", "Cancel")}
                    bgcolor="primary_light2"
                    color="light"
                    btntype="outline"
                    borderColor="primary_light2"
                    onClick={() => setIsModal({ ...isModal, open: false })}
                    btnBorderRadius="xxxs"
                  />
                  <ButtonCom
                    color="light"
                    bgcolor="primary_light2"
                    text={translate("delete", "Delete")}
                    btnBorderRadius="xxxs"
                    onClick={() => handleDeleteWishlist(isModal.item)}
                  />
                </div>
              </div>
            </ModalCom>
          </div>
        </Container>
      </StyledWishlistCon>
    </>
  );
};
