import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { configKey } from "./controller/constant/config";

import { Loading, ToastCom } from "./components";
import { useTheme, useOther } from "./hook";
import AppLayout from "./router";

type AppProps = {};
const App: React.FC<AppProps> = (props) => {
  const { baseTheme } = useTheme();
  const { langStore } = useOther();

  let BaseTheme = {
    ...baseTheme,
    langStore,
  };
  if (window.location.pathname.toLowerCase() === "/admin") {
    window.location.href = configKey.apiUrl;
    return null;
  }
  return (
    <ThemeProvider theme={BaseTheme}>
      <ToastCom />
      <Loading />
      <AppLayout />
    </ThemeProvider>
  );
};

export default App;
