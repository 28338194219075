import React, { useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Media from "react-media";

import { BsEye } from "react-icons/bs";
import { GoTriangleRight } from "react-icons/go";

import { useHandleOther, useProduct, usePreview } from "hook";
import { CardCom, TextCom, SkeletonWrap, PriceHandleMod } from "components";
import { moneyFormat, en2mm_num } from "utils";
import { StyledProductCardCom } from "theme";

import Image from "asset/icon/pladco";

import { promotion_price } from "hook/custom/product/pricehandler";

type ProductCardProps = {
  children?: any;
  className?: any;
  style?: any;
  data?: any;
  key?: any;
  bgColor?: any;
  has_item?: string;
  no_border?: boolean;
  replace?: boolean;
  hideName?: boolean;
  wrap?: any;
  id?: any;
};

export const ProductCard: React.FC<ProductCardProps> = ({
  data,
  has_item,
  no_border,
  hideName,
  className,
}) => {
  const {
    main_img,
    item_name,
    item_price,
    discount_percent,
    promo_text,
    promo_data,
    route,
    discount_price,
    item,
  } = data;
  const { showPreview } = usePreview();
  const navigate = useNavigate();
  const { ProductAction, dispatch } = useProduct();
  const { translate, langStore } = useHandleOther();
  const langCode = langStore?.code;
  const loc = useLocation();

  const handleRouteChange = (route: any) => {
    navigate(route, { replace: true, state: { instock: has_item } });
  };

  const PreviewHandler = async (item: any) => {
    await dispatch(
      ProductAction.getProductById({
        id: item?.id ? item?.id : item?.product_id || item?.ID,
        langCode,
      })
    );
    await showPreview();
  };

  const cardRef = useRef<HTMLDivElement>(null);
  {
    /* // --------- arg for promotion_price => price, discounttype, discountamount */
  }
  // promotion_price(data.price, data.promotion.discounttype, data.promotion.discount_percent)

  return (
    <StyledProductCardCom className={`${no_border ? "slider-card" : ""}`}>
      <CardCom className={`product-card ${className}`}>
        {route && (
          <a
            className="route"
            onClick={() => handleRouteChange(route)}
            // href={route}
          />
        )}

        {/* {has_item === "false" && (
                  <>
                    <div
                      className="no-item"
                      onClick={() => handleRouteChange(route)}
                    ></div>
                    <TextCom
                      className="out-of-stock"
                      textAlign="center"
                      color="light"
                      size="sm"
                    >
                      {translate("out-of-stock", "out of stock")}{" "}
                    </TextCom>
                  </>
                )} */}

        <div className="image-promo" ref={cardRef}>
          <SkeletonWrap
            minHeight={`${className === "landscape" ? "" : "300px"}`}
          >
            {has_item === "false" && (
              <div className="no-item" onClick={() => handleRouteChange(route)}>
                <TextCom className="out-of-stock" size="md">
                  {translate("sold-out", "Sold Out")}{" "}
                </TextCom>
              </div>
            )}
            <img src={main_img || Image.DefaultCard} width="100%" alt="" />
            {promo_text ? (
              <CardCom bgColor="primary_light2" className="gift-text-1">
                {langCode === "en" ? (
                  <TextCom
                    // textAlign="center"
                    color="white"
                    className="m-auto"
                    size="xs"
                  >
                    Buy {en2mm_num(promo_text[0], langCode)} Get{" "}
                    {en2mm_num(promo_text[1], langCode)}
                  </TextCom>
                ) : (
                  <TextCom
                    // textAlign="center"
                    color="white"
                    className="m-auto"
                    size="xs"
                  >
                    {en2mm_num(promo_text[0], langCode)} ခု ဝယ်{" "}
                    {en2mm_num(promo_text[1], langCode)} ခု လက်ဆောင် 
                  </TextCom>
                )}
              </CardCom>
            ) : (
              <></>
            )}
            {promo_data ? (
              <CardCom className="gift-main-con" bgColor="primary_light2">
                <div className="gift-text">
                  {langCode === "en" ? (
                    <TextCom
                      as="h6"
                      // textAlign="center"
                      color="white"
                      size="xs"
                      className="display-center receive-text"
                    >
                      Buy {en2mm_num(promo_data[0], langCode)} Get{" "}
                      {en2mm_num(promo_data[1], langCode)}
                    </TextCom>
                  ) : (
                    <TextCom
                      as="h6"
                      // textAlign="center"
                      color="white"
                      size="xs"
                      className="display-center receive-text"
                    >
                      {en2mm_num(promo_data[0], langCode)} ခု ဝယ်{" "}
                      {en2mm_num(promo_data[1], langCode)} ခု လက်ဆောင်
                    </TextCom>
                  )}
                  <div className="free-gift">
                    <img src={promo_data[2] || Image.DefaultCard} alt="logo" />
                    <TextCom
                      as="h4"
                      className="free-item"
                      color="light"
                      size="xs"
                    >
                      +{en2mm_num(promo_data[1], langCode)}
                    </TextCom>
                  </div>
                </div>
              </CardCom>
            ) : (
              <></>
            )}
            {discount_percent ? (
              <div className="display-center percent-dis">
                <TextCom color="light" size="lg">
                  {en2mm_num(discount_percent, langCode)}
                  <TextCom
                    as="span"
                    weight="lg"
                    color="light"
                    size={langCode === "en" ? "lg" : "xs"}
                    style={{ marginLeft: "2px" }}
                  >
                    %
                  </TextCom>{" "}
                </TextCom>
              </div>
            ) : (
              <></>
            )}
            {/* {!no_border && !loc.pathname.includes("/product/") && (
              <div className="display-center active eye-con">
                <BsEye
                  size="5px"
                  className="eye"
                  onClick={() => PreviewHandler(item)}
                />
                <div className="eye d-text">
                  <TextCom color="dark" size="sm">
                    {translate("preview", "Preview")}
                  </TextCom>
                </div>
              </div>
            )} */}
          </SkeletonWrap>
        </div>

        <div className="description">
          {!hideName && item_name && (
            <SkeletonWrap
              wrapperRef={cardRef}
              minHeight={"30px"}
              maxHeight={"40px"}
            >
              <TextCom
                className={` ${
                  item_name?.includes(`\\n`) ? "white-space" : ""
                }`}
                // textAlign="center"
                size="lg"
              >
                {item_name?.includes(`\\n`)
                  ? item_name.split("\\n").join("\r\n")
                  : item_name}
              </TextCom>
            </SkeletonWrap>
          )}
          <div className="" style={{ marginTop: "10px" }}>
            <SkeletonWrap
              wrapperRef={cardRef}
              style={{ maxWidth: "80%", minHeight: "30px" }}
            >
              {discount_price ? (
                <Media query={{ minWidth: 992 }}>
                  {(matches) =>
                    matches ? (
                      <>
                        <div className="price d-flex align-items-center justify-content-start gap-3">
                          <TextCom
                            textDecoration="line-through"
                            textAlign="left"
                            size="lg"
                          >
                            {moneyFormat(Math.ceil(item_price), langCode)}
                            <TextCom
                              as="span"
                              weight="lg"
                              size={langCode === "en" ? "lg" : "md"}
                            >
                              {translate("ks", "Ks")}
                            </TextCom>
                          </TextCom>
                          <TextCom size="xl" weight="md" className="dis-text">
                            {en2mm_num(discount_percent, langCode)}
                            <TextCom
                              as="span"
                              weight="lg"
                              size={langCode === "en" ? "xl" : "sm"}
                              style={{marginLeft:'2px'}}
                            >
                              %
                            </TextCom>{" "}
                            {translate("off", "OFF")}
                          </TextCom>
                        </div>
                        <TextCom
                          size="xl"
                          weight="lg"
                          className="price"
                          color="primary"
                        >
                          {moneyFormat(Math.ceil(discount_price), langCode)}
                          <TextCom
                            as="span"
                            color="primary"
                            weight="lg"
                            size={langCode === "en" ? "xl" : "md"}
                            style={{marginLeft:'5px'}}
                          >
                            {translate("ks", "Ks")}
                          </TextCom>
                        </TextCom>
                      </>
                    ) : (
                      <>
                        <div className="price d-flex align-items-center justify-content-start gap-3 flex-wrap">
                          <TextCom
                            textDecoration="line-through"
                            textAlign="left"
                            size="lg"
                          >
                            {moneyFormat(Math.ceil(item_price), langCode)}
                            {translate("ks", "Ks")}
                          </TextCom>
                          <TextCom size="xl" weight="lg">
                            {moneyFormat(Math.ceil(discount_price), langCode)}
                            <TextCom
                              as="span"
                              weight="lg"
                              size={langCode === "en" ? "xl" : "sm"}
                            >
                              {translate("ks", "Ks")}
                            </TextCom>
                          </TextCom>
                        </div>
                        <TextCom color="primary" size="xl" weight="md">
                          {en2mm_num(discount_percent, langCode)}
                          <TextCom
                            as="span"
                            weight="lg"
                            color="primary"
                            size={langCode === "en" ? "xl" : "xs"}
                          >
                            %
                          </TextCom>{" "}
                          {translate("off", "OFF")}
                        </TextCom>
                      </>
                    )
                  }
                </Media>
              ) : (
                item_price && (
                  <TextCom
                    // textAlign="center"
                    color="primary"
                    size="xl"
                    weight="lg"
                    className="price"
                  >
                    {moneyFormat(Math.ceil(item_price), langCode)}{" "}
                    <TextCom as='span' color="primary" weight='lg' size={langCode==='en'?'xl':'md'}>{translate("ks", "Ks")}</TextCom>
                  </TextCom>
                )
              )}
            </SkeletonWrap>
          </div>
        </div>
      </CardCom>
    </StyledProductCardCom>
  );
};
export const ProductCardMap: React.FC<ProductCardProps> = ({
  className,
  data,
  has_item,
  no_border,
}) => {
  const cardRef1 = useRef<HTMLDivElement>(null);
  return data?.map((item: any, key: any) => {
    const is_product_promo =
      item?.promotion && item?.promotion[0]?.promotion_type === "product";
    const is_same_product = is_product_promo
      ? (item?.product_id || item?.id) ===
        item?.promotion[0].reward_product[0]?.product_template_id
      : false;
    return (
      <div className={className} key={key}>
        {className === "card-landscape" ? (
          <SkeletonWrap className="main-skeleton">
            <ProductCard
              data={{
                item_name: item.name,
                main_img: item.product_image || item.image || null,
                item_price:
                  item.list_price ||
                  item?.price[0]?.price ||
                  item?.price ||
                  null,
                discount_price: item.promo_price,
                promo_data:
                  is_product_promo && is_same_product === false
                    ? [
                        item?.promotion?.[0].buy_product?.[0]?.min_qty,
                        item?.promotion?.[0].reward_product?.[0]?.reward_qty,
                        item?.promotion?.[0].reward_product?.[0]?.reward_image,
                      ]
                    : "",
                discount_percent:
                  item.cal_discount_percent ||
                  (item?.promotion && item?.promotion[0]?.discount_percent),
                promo_text:
                  is_product_promo && is_same_product === true
                    ? [
                        item?.promotion[0].buy_product[0].min_qty,
                        item?.promotion[0].reward_product[0].reward_qty,
                      ]
                    : "",
                item,
                route: item.product_id
                  ? `/product/${item.product_id}`
                  : `/product/${item.id}` || `/products?brand_ids=${item.ID}`,
                replace: true,
              }}
              has_item={item?.in_stock === false ? "false" : "true"}
              no_border={no_border ? no_border : no_border}
              key={key}
              className="landscape"
            />
          </SkeletonWrap>
        ) : (
          <ProductCard
            data={{
              item_name: item.name,
              main_img: item.product_image || item.image || null,
              item_price:
                item.list_price || item?.price[0]?.price || item?.price || null,
              discount_price: item.promo_price,
              promo_data:
                is_product_promo && is_same_product === false
                  ? [
                      item?.promotion?.[0].buy_product?.[0]?.min_qty,
                      item?.promotion?.[0].reward_product?.[0]?.reward_qty,
                      item?.promotion?.[0].reward_product?.[0]?.reward_image,
                    ]
                  : "",
              discount_percent:
                item.cal_discount_percent ||
                (item?.promotion && item?.promotion[0]?.discount_percent),
              promo_text:
                is_product_promo && is_same_product === true
                  ? [
                      item?.promotion[0].buy_product[0].min_qty,
                      item?.promotion[0].reward_product[0].reward_qty,
                    ]
                  : "",
              item,
              route: item.product_id
                ? `/product/${item.product_id}`
                : `/product/${item.id}` || `/products?brand_ids=${item.ID}`,
              replace: true,
            }}
            has_item={item?.in_stock === false ? "false" : "true"}
            no_border={no_border ? no_border : no_border}
            key={key}
          />
        )}
      </div>
    );
  });
};
