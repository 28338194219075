import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FiUser, FiHeart, FiFileText, FiExternalLink } from "react-icons/fi";
import { TbCrown } from "react-icons/tb";
import { BsArrowLeft } from "react-icons/bs";
import Media from "react-media";
import {
  Container,
  TextCom,
  ButtonCom,
  NewTabCom,
  CardCom,
  TableCom,
  StaticSidebar,
  BreadcrumbCom,
} from "components";
import { useHandleOther, useOther, useCart } from "hook";
import { StyledOrderDetailCon } from "theme";
import Icon from "asset/icon/pladco";
import { splitedTime, moneyFormat, joinSpace, en2mm_num } from "utils";
import Image from "asset/icon/pladco";
import "./style.scss";

type OrderDetailProps = {};

export const OrderDetail: React.FC<OrderDetailProps> = (props) => {
  const { translate } = useHandleOther();
  const { getContactUsPage_data, dispatch, langStore } = useOther();
  const { getOrder_data, CartAction, getOrderById_data } = useCart();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const langCode = langStore?.code;

  useEffect(() => {
    dispatch(CartAction.getOrderById(orderId));
  }, []);

  //---------order-line-table-------------
  const columns = [
    {
      title: translate("product", "Product"),
      key: "name",
      width: 400,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div
          className="product_name_wrap d-flex"
          style={{ paddingLeft: "20px" }}
        >
          {x?.product?.image !== false ? (
            <img src={x?.product?.image || Image.DefaultCard} />
          ) : (
            <>
              {x?.product?.reward_type === "discount" ? (
                <img src={Image.PromotionTag} />
              ) : x?.product?.is_delivery_line === true ? (
                <img src={Image.Delivery} />
              ) : (
                <img src={Image.DefaultCard} />
              )}
            </>
          )}
          <div>
            <TextCom weight="xl">
              {joinSpace(x?.product?.name) || "Product Name"}
            </TextCom>
            {x?.product?.code && x?.product?.is_delivery_line !== true && (
              <TextCom weight="xl">
                {translate("sku", "SKU")}: <span>{x?.product?.code}</span>
              </TextCom>
            )}
          </div>
        </div>
      ),
    },
    {
      title: translate("price", "Price"),
      key: "price",
      width: 100,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="price_wrap">
          {x?.product?.is_delivery_line === false && (
            <TextCom weight="lg" color="primary" size="xl" textAlign="center">
              {moneyFormat(Math.ceil(x?.price_unit), langCode)}
              <TextCom
                as="span"
                weight="lg"
                color="primary"
                size={langCode === "en" ? "xl" : "md"}
                style={{ marginLeft: "5px" }}
              >
                {translate("ks", "Ks")}
              </TextCom>
            </TextCom>
          )}
        </div>
      ),
    },
    {
      title: translate("quantity", "Quantity"),
      key: "qty",
      width: 100,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="qty_wrap">
          {x?.product?.is_delivery_line === false && (
            <TextCom
              color="border"
              textAlign="center"
              size={"xl"}
              weight="lg"
            >
              {moneyFormat(x?.quantity, langCode)}
            </TextCom>
          )}
        </div>
      ),
    },
    {
      title: translate("amount", "Amount"),
      key: "total",
      width: 100,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="total_price_wrap">
          <TextCom weight="lg" size="xl" textAlign="center">
            {moneyFormat(Math.ceil(x?.total_price), langCode)}
            <TextCom
              as="span"
              size={langCode === "en" ? "xl" : "md"}
              weight="lg"
              style={{ marginLeft: "5px" }}
            >
              {translate("ks", "Ks")}
            </TextCom>
          </TextCom>
        </div>
      ),
    },
  ];

  const mb_columns = [
    {
      title: translate("product", "Product"),
      key: "name",
      width: 200,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="product_name_wrap" style={{ paddingLeft: "15px" }}>
          <TextCom weight="xl">
            {joinSpace(x?.product?.name) || "Product Name"}
          </TextCom>
          {x?.product?.code && x?.product?.is_delivery_line !== true && (
            <TextCom weight="xl">
              {translate("sku", "SKU")} : <span>{x?.product?.code}</span>
            </TextCom>
          )}
        </div>
      ),
    },
    {
      title: translate("quantity", "Quantity"),
      key: "qty",
      width: 100,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="qty_wrap">
          {x?.product?.is_delivery_line === false && (
            <TextCom
              color="border"
              textAlign="center"
              size={ "xl"}
              weight="lg"
            >
              {moneyFormat(x?.quantity, langCode)}
            </TextCom>
          )}
        </div>
      ),
    },
    {
      title: translate("total", "Total"),
      key: "total",
      width: 100,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="total_price_wrap">
          <TextCom weight="lg" color="primary" size="lg" textAlign="center">
            {moneyFormat(Math.ceil(x?.total_price), langCode)}
            <TextCom
              as="span"
              weight="lg"
              color="primary"
              size={langCode === "en" ? "lg" : "sm"}
              style={{ marginLeft: "3px" }}
            >
              {translate("ks", "Ks")}
            </TextCom>
          </TextCom>
        </div>
      ),
    },
  ];

  //---------gift-table-------------------
  const giftcolumns = [
    {
      key: "name",
      width: 300,
      render: (x: any, y: any) => (
        <div className="product_name_wrap d-flex">
          <img src={x?.image || Image.DefaultCard} />
          <div>
            <TextCom weight="xl">{x?.gift_name || "Product Name"}</TextCom>
          </div>
        </div>
      ),
    },
    {
      key: "price",
      width: 200,
      render: (x: any, y: any) => (
        <div className="price_wrap">
          <TextCom weight="xl" color="secondary" size="lg" textAlign="right">
            {x?.value_point || "0"} {translate("points", "points")}
          </TextCom>
        </div>
      ),
    },
    {
      key: "qty",
      width: 200,
      render: (x: any, y: any) => (
        <div className="qty_wrap">
          <TextCom color="border" textAlign="center">
            {translate("qty", "Qty")} : {x?.quantity || "1"}
          </TextCom>
        </div>
      ),
    },
    {
      key: "total",
      width: 100,
      render: (x: any, y: any) => (
        <div className="total_price_wrap">
          <TextCom weight="xl" size="xl" textAlign="right">
            {x?.value_point * x?.quantity || "0"}{" "}
            {translate("points", "points")}
          </TextCom>
        </div>
      ),
    },
  ];

  const giftmb_columns = [
    {
      key: "name",
      width: 200,
      render: (x: any, y: any) => (
        <div className="product_name_wrap">
          <TextCom weight="xl">{x?.gift_name || "Product Name"}</TextCom>
        </div>
      ),
    },
    {
      key: "qty",
      width: 100,
      render: (x: any, y: any) => (
        <div className="qty_wrap">
          <TextCom color="border" textAlign="right">
            {translate("qty", "Qty")} : {x?.quantity || "1"}
          </TextCom>
        </div>
      ),
    },
    {
      key: "total",
      width: 100,
      render: (x: any, y: any) => (
        <div className="total_price_wrap">
          <TextCom weight="xl" size="xl" textAlign="right">
            {x?.value_point * x?.quantity || "0"}
            {translate("points", "points")}
          </TextCom>
        </div>
      ),
    },
  ];

  const sidebar_data = [
    {
      name: translate("my-profile", "My Profile"),
      link: "/user/profile",
    },
    {
      name: translate("my-order", "My Order"),
      link: "/user/my_orders",
    },
    {
      name: translate("my-wishlist", "My Wishlist"),
      link: "/user/wishlist",
    },
    {
      name: translate("change-password", "My Wishlist"),
      link: "/user/change_password",
    },
    {
      name: translate("logout", "Logout"),
      link: "logout",
    },
  ];

  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    {
      name: translate("order-detail", "Order Detail"),
      route: "#",
    },
  ];

  // let item_con = document.querySelectorAll(".item_con");
  // if (item_con) item_con[0]?.classList.add("active");

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      <StyledOrderDetailCon>
        <Container className="orderDetail-con container">
          <div className="row orderlist-row">
            <div className="col-md-12 col-lg-3">
              <StaticSidebar
                data={sidebar_data}
                route=""
                classname="side-bar"
              />
            </div>
            <div className="col-md-12 col-lg-9 order-detail-content">
              <div className="title">
                <TextCom size="xxl" weight="lg">
                  {translate("order-detail", "Order Detail")}
                </TextCom>
              </div>
              {getOrderById_data && (
                <CardCom className="order-detail-card">
                  <div className="order-title">
                    <TextCom weight="lg">
                      {translate("order-no", "Order No")} -{" "}
                      {getOrderById_data?.order_id}
                    </TextCom>
                    <TextCom>
                      {translate("delivered-to", "Delivered To")} :{" "}
                      {getOrderById_data?.shipping_info?.customer_name
                        ? `${getOrderById_data?.shipping_info?.customer_name}, `
                        : ""}
                      {getOrderById_data?.shipping_info?.mobile
                        ? `${getOrderById_data?.shipping_info?.mobile}, `
                        : ""}
                      {getOrderById_data?.shipping_info?.street
                        ? `${getOrderById_data?.shipping_info?.street}, `
                        : ""}
                      {getOrderById_data?.shipping_info?.township?.name
                        ? `${getOrderById_data?.shipping_info?.township?.name}, `
                        : ""}
                      {getOrderById_data?.shipping_info?.state?.name
                        ? `${getOrderById_data?.shipping_info?.state?.name}, `
                        : ""}
                      {getOrderById_data?.shipping_info?.country?.name
                        ? `${getOrderById_data?.shipping_info?.country?.name} `
                        : ""}
                    </TextCom>
                    {getOrderById_data?.payment_status && (
                      <TextCom>
                        {translate("payment-status", "payment-status")} :{" "}
                        {translate(
                          getOrderById_data?.payment_status,
                          getOrderById_data?.payment_status
                        )}
                      </TextCom>
                    )}
                  </div>
                  <div className="order-table">
                    <Media query={{ minWidth: 992 }}>
                      {(matches) => (
                        <TableCom
                          dataSource={getOrderById_data?.order_lines}
                          columns={matches ? columns : mb_columns}
                        />
                      )}
                    </Media>
                  </div>
                  <div className="sub-total d-flex">
                    <div>
                      <TextCom size="xl" weight="xl">
                        {translate("subtotal", "Subtotal")}
                      </TextCom>
                      {getOrderById_data?.tax > 0 && (
                        <TextCom size="xl" weight="xl">
                          {translate("tax", "Tax")}
                        </TextCom>
                      )}
                      {getOrderById_data?.use_point !== 0 && (
                        <TextCom size="xl" weight="xl">
                          {translate("point-redeemed", "Point redeemed")}
                        </TextCom>
                      )}
                      {getOrderById_data?.discount_percent > 0 && (
                        <TextCom size="xl" weight="xl">
                          {translate("discount-amount", "Discount Amount")} (
                          {getOrderById_data?.discount_percent}%)
                        </TextCom>
                      )}
                    </div>
                    <div>
                      <TextCom size="xl" weight="xl" textAlign="right">
                        {moneyFormat(Math.ceil(getOrderById_data?.subtotal),langCode)}{" "}
                        <TextCom as="span" size={langCode==='en'?'xl':'md'} weight={'xl'} style={{marginLeft:'5px'}}>{translate("ks", "Ks")}</TextCom>
                      </TextCom>
                      {getOrderById_data?.tax > 0 && (
                        <TextCom size="xl" weight="xl" textAlign="right">
                          {moneyFormat(Math.ceil(getOrderById_data?.tax))}{" "}
                          {translate("ks", "Ks")}
                        </TextCom>
                      )}
                      {getOrderById_data?.use_point !== 0 && (
                        <TextCom size="xl" weight="xl" textAlign="right">
                          {getOrderById_data?.use_point}
                        </TextCom>
                      )}
                      {getOrderById_data?.discount_percent > 0 && (
                        <TextCom size="xl" weight="xl" textAlign="right">
                          -{getOrderById_data?.discount_amount}
                        </TextCom>
                      )}
                    </div>
                  </div>
                  <div className="total d-flex">
                    <div>
                      <TextCom size="xxl" weight="xl">
                        {translate("total", "Total")}
                      </TextCom>
                      {/* <div className="d-flex point">
                        <TbCrown size={30} color="#F27043" fill="#F27043" />
                        <TextCom color="secondary">
                          {translate("points-earned", "Points earned")}
                        </TextCom>
                      </div> */}
                    </div>
                    <div>
                      <TextCom size="xxxl" weight="xl" textAlign="right">
                        {moneyFormat(Math.ceil(getOrderById_data?.total),langCode)}{" "}
                        <TextCom as='span' size={langCode==='en'?'xxxl':'xl'} weight={'xl'} style={{marginLeft:'5px'}}>{translate("ks", "Ks")}</TextCom>
                      </TextCom>
                      {/* <TextCom
                        textAlign="right"
                        className="point-earn"
                        color="light"
                      >
                        {getOrderById_data?.reward_point}
                      </TextCom> */}
                    </div>
                  </div>
                </CardCom>
              )}
              {/* {getOrderById_data?.gifts?.length > 0 && (
                <>
                  <TextCom size="xxxl" weight="lg">
                    {translate("gift", "Gift")}
                  </TextCom>
                  <CardCom className="gift-card">
                    <div className="gift-table">
                      <Media query={{ minWidth: 992 }}>
                        {(matches) => (
                          <TableCom
                            dataSource={getOrderById_data?.gifts}
                            columns={matches ? giftcolumns : giftmb_columns}
                          />
                        )}
                      </Media>
                    </div>
                    <div className="total d-flex">
                      <TextCom size="xxxl" weight="xl">
                        {translate(
                          "total-exchange-points",
                          "Total Exchange Points"
                        )}
                      </TextCom>
                      <TextCom size="xxxl" weight="xl">
                        {getOrderById_data?.use_point}{" "}
                        {translate("points", "points")}
                      </TextCom>
                    </div>
                  </CardCom>
                </>
              )} */}
              <ButtonCom
                type="outline"
                bgcolor="background"
                className="back-btn"
                btnBorderRadius="xxs"
                onClick={() => {
                  navigate(`../user/my_orders`);
                }}
                style={{ paddingInline: "10px", textTransform: "unset" }}
              >
                <BsArrowLeft />{" "}
                {translate("back-to-my-order", "Back to my order")}
              </ButtonCom>
            </div>
          </div>
        </Container>
      </StyledOrderDetailCon>
    </>
  );
};
