const setProduct = (data: any) => {
  localStorage.setItem('recent_view', JSON.stringify(data))
}
const getProduct = () => {
  let tmp: any = localStorage.getItem('recent_view')
  return JSON.parse(tmp);
}
const removeProduct = () => {
  localStorage.removeItem('recent_view')
}

export default {
  setProduct,
  getProduct,
  removeProduct,
}