import styled from "styled-components";

export const StyledAuthCon = styled.div`
  .log-in-con,.sign-up-con{
  input{
    border-radius: 5px;
    margin-bottom: 16px;
    &.check{
      margin: 0;
    }
    &[name="confirm_password"]{
      margin-bottom: 30px;
    }
  }
  .field-wrapper{
    &:last-child{
      margin-bottom: 30px;
    }
  }
  .title_txt{
    margin-bottom: 0!important;
    margin-top: 15px!important;
    @media(min-width: 992px){
      margin-top: 0!important;
      margin-bottom: 10px!important;
    }
  }
  .center-text{
    width: 80%;
  }
  .vlc_con{
    /* margin-bottom: 25px; */
  }
  padding-block: 50px;
  @media(max-width: 991px){
    padding-block: 0;
    padding-bottom: 20px;
  }
  .auth_con {
  margin-bottom: 40px;
  @media screen and (max-width: 992px) {
    padding-top: 20px;
  }

    margin: auto;
    .row-wrap{
      max-width: 70%;
      margin: auto;
      overflow: hidden;
      border: 1px solid #EEEEEE;
      border-radius: 10px;
      padding-block: 50px;
      @media(max-width: 991px){
        max-width: 100%;
      }
    }
    .left-sec{
      background: ${props => props?.theme?.color?.typo?.primary || '#ddd'};
      height: 500px;
      @media(max-width: 991px){
        height:fit-content;
        padding-block: 40px;
      }
      .d-flex{
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        height: 100%;
      }
        button{
          background: transparent;
          }
      }
    a{cursor: pointer;}
    .form_card {
      flex-direction: column;
      padding-inline: 25px;

      .title_txt {
        text-transform: unset;
        margin-bottom: 50px;
        @media(max-width: 991px){
          margin-bottom: 15px;
          }
      }
      
      .desc_txt {
        margin-bottom: 40px;
      }

      .form_foot_first {
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        margin-bottom: 25px;

        p {
          cursor: pointer;
        }
      }

      .form_foot_sec {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(max-width: 991px){
          margin-bottom: 20px;
        }
        button{
          width:100%;
        }
        .send_btn {
          margin-bottom: 20px;
        }
        
        .timer_txt {
          margin-bottom: 30px;
          border: 1px solid red;
        }

        button {
          min-width: 243px;
        }

        .signup_btn{
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }

    .signup_success_con {
      .icon_wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .animation{
          animation: splash 1s ;
          @keyframes splash {
            from {
                
                transform: scale(0);
            }
            to {
              transform: scale(1);                
            }
        }
        }
        .pos_tick {
          position: absolute;
          align-items: center;
          justify-content: center;
          height: 100%;
          display: flex;
        }
      }

      .wel_txt {
        margin-bottom: 40px;
      }

      .desc_txt {
        margin-bottom: 25px;
      }

      .btn_wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        button{
          width: 100%;
        }
      }
    }
  }
}
.sign-up-con{
  .left-sec{
    height: 630px!important;
    @media(max-width: 991px){
        height:fit-content!important;
        padding-block: 40px!important;
    }
  }
  .form_foot_sec{
    margin-top: 20px;
  }
}
.otp-con{
  .send-code-btn{
    background: transparent!important;
          p{margin-bottom: 0!important;}
    }
}
.otp-con,.forgot-con,.change-con{
  .form_card{
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 34px;
    border: 1px solid ${props => props?.theme?.color?.typo?.border2 || '#DDD'};
    border-radius: 15px;
    .label_txt{
      margin-bottom: 5px;
    }
    input{
      border-radius: 5px;
      border: 1px solid ${props => props?.theme?.color?.typo?.border2 || '#DDD'};
      background: ${props => props?.theme?.color?.typo?.background || '#ddd'};
      margin-bottom: 15px;
    }
    .timer_txt{
      width: fit-content;
      margin: auto;
      padding-inline: 30px;
      padding-block: 5px;
      margin-bottom: 25px;
      border: 1px solid ${props => props?.theme?.color?.typo?.border2 || '#DDD'};
      border-radius: 10px;
      margin-top: 10px;
    }
    .form_foot_sec{
      p{
        text-transform: unset;
      }
    }
    button{
      width: 100%;
      text-transform: unset;
    }
  }
}
.forgot-con,.change-con{
  margin: auto;
}
`