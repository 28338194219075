import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FormContainerCom, ButtonCom, InputCom, TextCom } from "components";
import { useHandleAuth, useHandleOther, useAuth } from "hook";
import "./style.scss";
import { StyledAuthCon } from "theme";

type SignUpProps = {};

export const SignUp: React.FC<SignUpProps> = (props) => {
  const navigate = useNavigate();
  const { handleSignUp } = useHandleAuth(props);
  const { translate } = useHandleOther();
  const { signUp_data } = useAuth();
  const [check, setCheck]: any = useState(false);

  let initialValues: any = {
    name: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
  };

  const validationSchema = (yup: any) =>
    yup.object({
      name: yup
        .string()
        .required(translate("name-is-required", "Name is Required")),
      phone: yup
        .string()
        .required(translate("phone-is-required", "Phone is Required")),
      email: yup
        .string()
        .email(translate("email-is-invalid", "Email is Invalid")),
      company: yup.string(),
      // .required(translate("company-is-required", "Company is Required")),
      password: yup
        .string()
        .required(translate("password-is-required", "Password is Required")),
      confirm_password: yup
        .string()
        .required(
          translate(
            "confirm-password-is-required",
            "Confirm password is required"
          )
        )
        .oneOf(
          [yup.ref("password"), null],
          translate("password-must-match", "Password Must Match")
        ),
    });

  const onDataSubmit = (values: any, actions: any) => {
    setTimeout(async () => {
      let post_data = {
        name: values.name,
        email: values.email,
        company: values.company,
        mobile: values.phone,
        password: values.password,
      };
      handleSignUp(post_data);
      if (signUp_data?.status === "fail") {
        actions.resetForm(initialValues);
        setCheck(false);
      }
    }, 1000);
    actions.setSubmitting(false);
  };

  const handleRouteChange = (route?: any, params?: any) => {
    navigate(route);
  };

  return (
    <StyledAuthCon>
      {/* <div className="sign-up-con">
        <div className="auth_con container">
          <div className="row">
            <div className="col-lg-6 form_wrap">
              <div className="form_card">
                <TextCom
                  size="xxxxl"
                  textAlign="center"
                  weight="xl"
                  className="title_txt"
                >
                  {translate("signup", "Sign Up")}
                </TextCom>
                <FormContainerCom
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values: any, actions: any) =>
                    onDataSubmit(values, actions)
                  }
                >
                  {(formikProps: any) => (
                    <>
                      <InputCom
                        label={translate("name", "Name")}
                        name="name"
                        type="text"
                        required
                      />
                      <InputCom
                        label={translate("phone", "Phone")}
                        name="phone"
                        type="tel"
                        required
                      />
                      <InputCom
                        label={translate("email", "Email")}
                        name="email"
                        type="email"
                      />
                      <InputCom
                        label={translate("password", "Password")}
                        name="password"
                        type="password"
                        required
                      />
                      <InputCom
                        label={translate(
                          "confirm-password",
                          "Confirm Password"
                        )}
                        name="confirm_password"
                        type="password"
                        required
                      />
                      <div className="form_foot_sec">
                        <div className="signup_btn">
                          <ButtonCom
                            type="submit"
                            color="light"
                            bgColor="dark"
                            btnBorderRadius="xxxs"
                            text={
                              formikProps.isSubmitting
                                ? translate("signup...", "Sign Up...")
                                : translate("signup", "Sign Up")
                            }
                          />
                        </div>
                        <div className="d-flex justify-content-center gap-3">
                          <TextCom>
                            {translate(
                              `already-have-an-account`,
                              `Already have an account?`
                            )}
                          </TextCom>
                          <TextCom
                            as="a"
                            link
                            color="primary"
                            onClick={() => handleRouteChange("/user/signin")}
                          >
                            {translate("signin", "SIGN IN")}
                          </TextCom>
                        </div>
                      </div>
                    </>
                  )}
                </FormContainerCom>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="sign-up-con">
        <div className="auth_con container">
          <div className="row row-wrap justify-content-center align-items-center">
            {/* <div className="col-lg-6 left-sec">
              <div className="d-flex">
                <TextCom weight="xl" size="xxl">
                  {translate("hello-friend", "Hello, friend")}
                </TextCom>
                <TextCom textAlign="center" className="center-text">
                  Do you already have an account? Please login with personal
                  info
                </TextCom>
                <ButtonCom
                  btntype="outline"
                  btnBorderRadius="5"
                  onClick={() => handleRouteChange("/user/signin")}
                >
                  {translate("sign-in", "Sign in")}
                </ButtonCom>
              </div>
            </div> */}
            <div className="col-lg-6 form_card">
              <TextCom
                size="xxl"
                weight="xl"
                className="title_txt"
                textAlign="center"
              >
                {translate("sign-up", "Sign up")}
              </TextCom>
              <TextCom
                size="lg"
                // weight="xl"
                color="border"
                className="mb-3"
                textAlign="center"
              >
                {translate(
                  "register-as-customer",
                  "Register as a Chain Pro Customer"
                )}
              </TextCom>
              <FormContainerCom
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: any, actions: any) =>
                  onDataSubmit(values, actions)
                }
              >
                {(formikProps: any) => (
                  <>
                    <InputCom
                      label={translate("name", "Name")}
                      name="name"
                      type="text"
                      required
                    />
                    <InputCom
                      label={translate("phone", "Phone")}
                      name="phone"
                      type="tel"
                      required
                    />
                    <InputCom
                      label={translate("email", "Email")}
                      name="email"
                      type="email"
                    />
                    <InputCom
                      label={translate("company", "Company")}
                      name="company"
                      type="text"
                    />
                    <InputCom
                      label={translate("password", "Password")}
                      name="password"
                      type="password"
                      required
                    />
                    <InputCom
                      label={translate("confirm-password", "Confirm Password")}
                      name="confirm_password"
                      type="password"
                      required
                    />
                    <div className="d-flex gap-2 align-items-center">
                      <InputCom
                        name="terms"
                        type="checkbox"
                        className="check"
                        onClick={() => setCheck(!check)}
                      />
                      <TextCom size="sm">
                        I agree to the{" "}
                        <TextCom
                          size="sm"
                          as="a"
                          href="/page/terms_and_condition"
                          color="primary_light2"
                        >
                          Terms & Conditions
                        </TextCom>{" "}
                        and{" "}
                        <TextCom
                          size="sm"
                          as="a"
                          href="/page/privacy_policy"
                          color="primary_light2"
                        >
                          Privacy Policy
                        </TextCom>
                      </TextCom>
                    </div>
                    <div className="form_foot_sec">
                      <div className="signup_btn">
                        <ButtonCom
                          type="submit"
                          color="light"
                          bgcolor="primary_light2"
                          btnBorderRadius="xxxs"
                          text={
                            formikProps.isSubmitting
                              ? translate("signup...", "Sign Up...")
                              : translate("signup", "Sign Up")
                          }
                          disabled={!check}
                        />
                      </div>
                    </div>
                  </>
                )}
              </FormContainerCom>
              <TextCom textAlign="center" className="mt-2">
                {translate("already-have-account", "Already have an account?")}
                <TextCom
                  as="span"
                  color="primary"
                  onClick={() => handleRouteChange("/user/signin")}
                  style={{ marginLeft: "5px" }}
                  textDecoration="underline"
                >
                  {translate("log-in", "Login")}
                </TextCom>
              </TextCom>
            </div>
          </div>
        </div>
      </div>
    </StyledAuthCon>
  );
};
