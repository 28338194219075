export const colors: any = {
  //site theme
  primary: '#364DE8',
  primary_bold: '#12249B',
  primary_light1: '#C3CAF8',
  primary_light2: '#5E71EC',
  primary_light3: '#f1f3ff',
  secondary: '#F75759',
  bg1: '#F1F0EB',
  background: '#F5F5F5',
  background2: '#F1F1F1',
  disabled: '#CBCBCB',
  danger: '#F41A1D',
  danger2: '#AE0002',
  paragraph: '#444444',
  gray: '#D9D9D9',
  gray1: '#DDDDDD',
  light: '#FFFFFF',
  dark: '#000000',
  text: '#333339',
  viber: '#9069AE',

  //other
  bg2: '#FFE3DD',
  bg3: '#FFC838',
  bg4: '#FFBEFF',
  dark2: '#0A0A0A',

  line: '#D9D9D9',
  line2: '#e2e2e2',
  border: '#707070',
  border2: '#EEEEEE',
  status: '#FF4200',
  mellow: '#FFF1CD',
  green: '#ECF8F2',
  light_green: '#95D6B6',
  dark_green: '#85bda1',
  out_stock: '#646464',
  shadow: '#00000024'
}

export const darkcolor: any = {
  mode: 'light',

  typo: {
    ...colors,
    gray: '#EEEEEE',
    pink: '#f8b8a083',
    dark_mellow: '#DCAD30'
  },

  statusBar: {
    ...colors,
  },

  breadcrumb: {
    ...colors,
  },

  header: {
    ...colors,
    hpBgColor: '#EBEBEB',
  },

  refresh: {
    ...colors,
  },

  container: {
    ...colors,
    light_yellow: '#ffc83880',
    light_pink: '#ffe3ddcc',
    pink: '#ffe3ddcc',
    green: '#ECF8F2',
    light_green: '#95D6B6',
    dark_green: '#A1E6C4'
  },

  footer: {
    ...colors,
  },

  drawer: {
    ...colors,
  },

  button: {
    ...colors,
  },

  form: {
    ...colors,
    borderColor: '#D9D9D9',
    light_gray: '#e2e2e2',
    light_gray2: '#EDEDED'
  },

  card: {
    ...colors,
    yellow: ' #FEB800',
    pink: '#F8B7A0',

  },

  list: {
    ...colors,
  },

  modal: {
    ...colors,
    light_green: '#E1F2E5'
  },

  loading: {
    ...colors,
  },

  icon: {
    ...colors,
  },

  table: {
    ...colors,
  },
}

export const lightcolor: any = {
  mode: 'light',

  typo: {
    ...colors,
    gray: '#EEEEEE',
    pink: '#f8b8a083',
    dark_mellow: '#DCAD30'
  },

  statusBar: {
    ...colors,
  },

  breadcrumb: {
    ...colors,
  },

  header: {
    ...colors,
    hpBgColor: '#fff',
  },

  refresh: {
    ...colors,
  },

  container: {
    ...colors,
    light_yellow: '#ffc83880',
    light_pink: '#ffe3ddcc',
    pink: '#ffe3ddcc',
    green: '#ECF8F2',
    light_green: '#95D6B6',
    dark_green: '#A1E6C4'
  },

  footer: {
    ...colors,
  },

  drawer: {
    ...colors,
  },

  button: {
    ...colors,
  },

  form: {
    ...colors,
    borderColor: '#D9D9D9',
    light_gray: '#e2e2e2',
    light_gray2: '#EDEDED'
  },

  card: {
    ...colors,
    yellow: '#FEB800',
    pink: '#F8B7A0',
    dark_pink: '#CC9784'
  },

  list: {
    ...colors,
  },

  modal: {
    ...colors,
    light_green: '#E1F2E5'
  },

  loading: {
    ...colors,
  },

  icon: {
    ...colors,
  },

  table: {
    ...colors,
  },
}
