import React, { useState, useEffect, RefObject, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { BiXCircle } from "react-icons/bi";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { CgShoppingCart } from "react-icons/cg";
import { BsTelephone } from "react-icons/bs";
import { RiShareForwardLine } from "react-icons/ri";
import { TbShare3 } from "react-icons/tb";
import { uom_price } from "hook/custom/product/pricehandler";
import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  ViberShareButton,
} from "react-share";
import {
  LinkedinIcon,
  TwitterIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  ViberIcon,
} from "react-share";
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";

import {
  Loading,
  ProductGallerySlider,
  TextCom,
  AttributeButtonMod,
  AddToCartButtonMod,
  StockVariantMsgMod,
  QtyButtonMod,
  WishlistButtonMod,
  PriceHandleMod,
  SkeletonWrap,
} from "components";
import {
  useHandleOther,
  useProduct,
  useTheme,
  hidePreview,
  useOther,
} from "hook";
import { StyledPreview, ImageWrap, ContentWrap, lineHeight } from "theme";
import { joinSpace, moneyFormat } from "utils";
import Image from "asset/icon/pladco";

export type DetailPreviewProps = {
  alert?: any;
  props?: any;
  status?: any;
  wrap?: any;
};

export const DetailPreview: React.FC<DetailPreviewProps> = ({
  alert,
  status,
  props,
}) => {
  const navigate = useNavigate();
  const { translate, langStore } = useHandleOther();
  let langCode = langStore.code;
  const { getProductById_data, isLoading } = useProduct();
  const { CreatePreview_data, themeContext } = useTheme();
  const { getContactUsPage_data } = useOther();
  const [showremind, setShowRemind] = useState(false);
  const location = useLocation();
  const [loadstate, setLoadState] = useState(false);
  let ispreview = CreatePreview_data?.PreviewModalVisible;
  const [calculated_price, set_calculated_price] = useState<uomPrice | null>(
    null
  );
  const [additional_calculated_price, set_additional_calculated_price] =
    useState<any | null>(null);
  const [handleAttr, setHandleAttr] = useState<any | null>(null);
  const [handleCapType, setHandleCapType] = useState<any | null>(null);
  const [handleCapColor, setHandleCapColor] = useState<any | null>(null);
  const [HandleImage, setHandleImage] = useState<any | null>(null);
  const [UOM_type, set_UOM_type]: any = useState();
  const product_state = useSelector((state: any) => state?.product);

  const galleryRef = useRef<HTMLDivElement>(null);
  const current_url = window.location.href;
  // console.log(current_url)
  if (showremind === true) {
    setTimeout(() => {
      setShowRemind(false);
    }, 3000);
  }

  let imagearr = [
    { image: getProductById_data?.image || Image.DefaultDetailCard },
  ];

  const handleDetail = () => {
    navigate(`/product/${getProductById_data?.product_id}`);
    hidePreview();
  };

  //check all variants are selected or not
  let selected: any = null;
  let variants: any = [];
  let checkvariant: any = [];

  getProductById_data?.package_products?.data?.map((item: any) => {
    if (item?.attributes?.data?.length > 0) {
      checkvariant.push(item);
    }
    if (item?.attributes?.selected_variant_ids) {
      if (
        item?.attributes?.selected_variant_ids.length ===
        item?.attributes?.data?.length
      ) {
        variants.push(item?.attributes?.selected_variant_ids);
      }
    }
  });

  if (getProductById_data?.package_product_type === true) {
    selected = variants.length === checkvariant.length;
  } else {
    // selected = getProductById_data?.check_stock?.desc ? false : true
  }

  const [uid, setUid] = useState(null);

  interface uomPrice {
    price: string;
    discounttype: string;
    discountamount: string;
    hello: string;
  }
  // console.log(UOM_type)
  useEffect(() => {
    if (getProductById_data != null) {
      let uomPrice = uom_price(
        getProductById_data,
        UOM_type,
        getProductById_data?.quantity
      );
      set_calculated_price(uomPrice);
    }
  }, [UOM_type, getProductById_data]);
  // console.log(getProductById_data)
  const styles = {
    myStyleClassName: {
      padding: "16px 0px 16px 0px",
      "& a": {
        textDecoration: "none",
        color: "#0000ee",
      },
      "& a:hover": {
        textDecoration: "underline",
      },
    },
    myButtonClass: {
      "&:hover": {
        textDecoration: "underline",
      },
    },
  };

  // isMobile?
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = () => setIsMobile(mediaQuery.matches);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // console.log(
  //   getProductById_data?.uom?.find((uom: any) => uom.display == true)?.id
  // );
  return (
    <>
      <Loading loadstate={loadstate} />
      <StyledPreview
        className={ispreview === true ? "preview" : "product-detail-wrap"}
      >
        <div className={`remind ${showremind ? "active" : ""}`}>
          <TextCom>
            " {joinSpace(getProductById_data?.name)} " has been added to your
            cart.
            <BiXCircle
              size={20}
              className="mx-2"
              onClick={() => setShowRemind(!showremind)}
            />
          </TextCom>
        </div>
        <div className="d-flex preview-content" ref={galleryRef}>
          <ImageWrap className="image_wrap">
            <SkeletonWrap minHeight="400px" wrapperRef={galleryRef}>
              {getProductById_data?.cap_data?.selected_variant_ids?.length ==
                2 &&
              getProductById_data?.attributes?.selected_variant_ids?.length ==
                getProductById_data?.attributes?.data?.length ? (
                // getProductById_data?.cap_data[0]?.find((x: any) => x.id === 2)
                // getProductById_data?.cap_data[getProductById_data?.cap_data?.selected_variant_ids[0]-1]?.[0]?.color_info[getProductById_data?.cap_data?.selected_variant_ids[1]-1]?.product[0]?.image
                <ProductGallerySlider
                  calculated_price={calculated_price}
                  pd={getProductById_data}
                  data={
                    getProductById_data?.cap_data[0]
                      ?.find(
                        (x: any) =>
                          x.id ===
                          getProductById_data?.cap_data?.selected_variant_ids[0]
                      )
                      ?.color_info.find(
                        (y: any) =>
                          y.id ===
                          getProductById_data?.cap_data?.selected_variant_ids[1]
                      )
                      ?.product.find(
                        (z: any) =>
                          z.variant_id ===
                          getProductById_data?.attributes?.selected_variant_ids.join(
                            ","
                          )
                      )?.image
                      ? [
                          {
                            image: getProductById_data?.cap_data[0]
                              ?.find(
                                (x: any) =>
                                  x.id ===
                                  getProductById_data?.cap_data
                                    ?.selected_variant_ids[0]
                              )
                              ?.color_info.find(
                                (y: any) =>
                                  y.id ===
                                  getProductById_data?.cap_data
                                    ?.selected_variant_ids[1]
                              )
                              ?.product.find(
                                (z: any) =>
                                  z.variant_id ===
                                  getProductById_data?.attributes?.selected_variant_ids.join(
                                    ","
                                  )
                              )?.image,
                          },
                        ]
                      : getProductById_data?.gallery.length != 0
                      ? getProductById_data?.gallery
                      : [{ image: getProductById_data?.image }]
                  }
                  instock={
                    location?.state !== null ? location?.state?.instock : ""
                  }
                  rightArrowSvg={
                    <FiChevronRight
                      style={{
                        strokeWidth: "2px",
                        width: "2rem",
                        height: "2rem",
                      }}
                    />
                  }
                  leftArrowSvg={
                    <FiChevronLeft
                      style={{
                        strokeWidth: "2px",
                        width: "2rem",
                        height: "2rem",
                      }}
                    />
                  }
                />
              ) : getProductById_data?.gallery?.length > 0 ? (
                <ProductGallerySlider
                  calculated_price={calculated_price}
                  pd={getProductById_data}
                  data={getProductById_data?.gallery}
                  instock={
                    location?.state !== null ? location?.state?.instock : ""
                  }
                  rightArrowSvg={
                    <FiChevronRight
                      style={{
                        strokeWidth: "2px",
                        width: "2rem",
                        height: "2rem",
                      }}
                    />
                  }
                  leftArrowSvg={
                    <FiChevronLeft
                      style={{
                        strokeWidth: "2px",
                        width: "2rem",
                        height: "2rem",
                      }}
                    />
                  }
                />
              ) : (
                <ProductGallerySlider
                  data={
                    getProductById_data?.gallery?.length > 0
                      ? getProductById_data?.gallery
                      : imagearr
                  }
                  instock={
                    location?.state !== null ? location?.state?.instock : ""
                  }
                  rightArrowSvg={
                    <FiChevronRight
                      style={{
                        strokeWidth: "2px",
                        width: "2rem",
                        height: "2rem",
                      }}
                    />
                  }
                  leftArrowSvg={
                    <FiChevronLeft
                      style={{
                        strokeWidth: "2px",
                        width: "2rem",
                        height: "2rem",
                      }}
                    />
                  }
                />
              )}
            </SkeletonWrap>
          </ImageWrap>
          <ContentWrap className="content-wrap">
            <SkeletonWrap wrapperRef={galleryRef} className="mb-2">
              <TextCom
                size="xxl"
                // className="mb-2"
                color="dark"
                weight="lg"
                style={{ fontSize: "24px" }}
                // style={{ position: "relative" }}
              >
                {joinSpace(getProductById_data?.name)}
              </TextCom>
            </SkeletonWrap>
            {getProductById_data?.summary && (
              <SkeletonWrap wrapperRef={galleryRef} className="mb-3">
                <TextCom
                  color="primary"
                  className="desc"
                  style={{ fontSize: "18px", lineHeight: "1.7" }}
                >
                  {getProductById_data?.summary}
                </TextCom>
              </SkeletonWrap>
            )}
            <SkeletonWrap wrapperRef={galleryRef} className="mb-3">
              {getProductById_data?.brand?.id && (
                <div className="d-flex">
                  <TextCom
                    color="paragraph"
                    weight="xl"
                    style={{ minWidth: "50px" }}
                  >
                    {translate("brand", "Brand")}
                  </TextCom>
                  {" : "}
                  <TextCom
                    as="a"
                    // href={`/products?brand_id=${getProductById_data?.brand?.id}`}
                    color="primary_light2"
                    textDecoration="underline"
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                    onClick={() =>
                      navigate(
                        `/products?brand_id=${getProductById_data?.brand?.id}`,
                        {
                          state: {
                            id: getProductById_data?.brand?.id,
                            name: getProductById_data?.brand?.name,
                          },
                        }
                      )
                    }
                  >
                    {getProductById_data?.brand?.name}
                  </TextCom>
                </div>
              )}
              {getProductById_data?.code && (
                <div className="d-flex">
                  <TextCom
                    color="paragraph"
                    weight="xl"
                    style={{ minWidth: "50px" }}
                  >
                    {translate("sku", "SKU")}
                  </TextCom>
                  {" : "}
                  <TextCom color="paragraph" style={{ marginLeft: "10px" }}>
                    {getProductById_data?.code}
                  </TextCom>
                </div>
              )}
            </SkeletonWrap>
            <SkeletonWrap wrapperRef={galleryRef} className="mb-2">
              <div className="price-mod">
                <PriceHandleMod
                  setid={setUid}
                  set_UOM_type={set_UOM_type}
                  UOM_type={UOM_type}
                  calculated_price={calculated_price}
                />
              </div>
            </SkeletonWrap>
            <SkeletonWrap wrapperRef={galleryRef} className="mb-2">

              
            {
            getProductById_data?.uom?.some(uom => uom.display)
            // getProductById_data?.uom?.filter((uom_e:any) => uom_e.display==true).length == 2
             ? 
            <div className="uom-price-table w-sm-70" style={{width: "100%"}}>
              <table style={{ borderCollapse: 'collapse', width: '100%', borderRadius: '40px' }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid rgba(0, 0, 0, 0.3)", padding: '10px' }}>Unit</th>
                    <th style={{ border: "1px solid rgba(0, 0, 0, 0.3)", padding: '10px' }}>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {getProductById_data?.uom?.map((item: any, index: any) => (
                    getProductById_data?.price?.map((v: any, i: any) => (
                      <>
                        {
                          (item.name == v.uom && item.display == true) &&

                          <tr key={index}>
                            <td style={{ border: "1px solid rgba(0, 0, 0, 0.3)", padding: '10px' }}>{v.uom}</td>
                            <td style={{ border: "1px solid rgba(0, 0, 0, 0.3)", padding: '10px' }}>{moneyFormat(v.price, langCode)}</td>
                          </tr>
                        }
                      </>
                    ))
                  ))}
                </tbody>
              </table>
            </div>
            :
            ""
            }
            </SkeletonWrap>

            {getProductById_data?.attributes?.data ||
            getProductById_data?.cap_data ? (
              <SkeletonWrap wrapperRef={galleryRef} className="mb-2">
                {getProductById_data?.attributes?.data?.length > 0 ||
                getProductById_data?.cap_data[0]?.length ? (
                  <div className="mt-2 mb-2">
                    <AttributeButtonMod
                      handleUseState={{
                        setHandleAttr: setHandleAttr,
                        handleCapType: handleCapType,
                        setHandleCapType: setHandleCapType,
                        handleCapColor: handleCapColor,
                        setHandleCapColor: setHandleCapColor,
                        setHandleImage: setHandleImage,
                        uid: uid,
                      }}
                    />
                  </div>
                ) : (
                  getProductById_data?.package_products?.data?.length > 0 &&
                  getProductById_data?.package_products?.data?.map(
                    (x: any, i: number) => (
                      <>
                        <div
                          className="package-variant"
                          key={i}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            borderBottomWidth:
                              getProductById_data?.package_products?.data
                                ?.length -
                                1 ===
                              i
                                ? 0
                                : 1,
                            borderBottomColor: themeContext?.color?.card?.line2,
                          }}
                        >
                          <TextCom weight="xl" style={{ marginBottom: 15 }}>
                            {x?.product_name}
                          </TextCom>
                          <AttributeButtonMod
                            variant_type="package_product"
                            package_product_index={i}
                            data={x}
                          />
                        </div>
                        <hr />
                      </>
                    )
                  )
                )}
              </SkeletonWrap>
            ) : null}

            {/* {getProductById_data?.brand?.name && (
            <div className="d-flex gap-5  mt-2 align-items-center">
              <TextCom
                weight="xl"
                style={{ marginBottom: 5, minWidth: "70px" }}
              >
                {translate("brand", "Brand")}:
              </TextCom>
              <TextCom
                color="primary"
                style={{
                  marginBottom: 5,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(
                    `/products?brand_id=${getProductById_data?.brand?.id}`
                  )
                }
              >
                {getProductById_data?.brand?.name || ""}
              </TextCom>
            </div>
          )}
          {getProductById_data?.code && getProductById_data?.code !== false && (
            <div className="d-flex gap-5 align-items-center">
              <TextCom
                weight="xl"
                style={{ marginBottom: 5, minWidth: "70px" }}
              >
                {translate("sku", "SKU")}:
              </TextCom>
              <TextCom style={{ marginBottom: 5 }}>
                {getProductById_data?.code}
              </TextCom>
            </div>
          )} */}

            <SkeletonWrap wrapperRef={galleryRef}>
              <div className="button-con mt-2">
                <div className="d-flex gap-2 align-items-center mb-4 qty-wrap">
                  <div className="d-flex gap-2 align-items-center">
                    <TextCom className="qty-text" weight="xl">
                      {translate("quantity", "quantity")}
                    </TextCom>
                    <TextCom className="qty-text" weight="xl">
                      :
                    </TextCom>
                    <QtyButtonMod
                      type="productbyid"
                      disabled={selected === false ? true : false}
                      btnbordercolor="#D9D9D9"
                      uid={uid}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column flex-sm-row gap-2 mt-2">
                  <div className="d-flex gap-2 add-to-cart-btns">
                    <AddToCartButtonMod
                      setLoadState={setLoadState}
                      disabled={
                        selected === false ||
                        (getProductById_data?.package_product_type !== true &&
                          (getProductById_data?.check_stock?.desc ||
                            getProductById_data?.check_stock?.available_qty ===
                              0)) ||
                        product_state?.isLoading === true ||
                        getProductById_data?.check_stock?.available_req_qty == 0
                          ? true
                          : false
                      }
                      htmlFor="productbyid"
                      type="addtocart"
                      // navigate={navigate}
                      params={getProductById_data?.product_id}
                      type_for={
                        getProductById_data?.package_product_type === true
                          ? "package_product"
                          : ""
                      }
                      bgColor="primary_light2"
                      // btntype="outline"
                      color="white"
                      btnBorderRadius="xxs"
                      borderColor="dark"
                      text={translate("add-to-cart", "Add to cart")}
                      set={setShowRemind}
                      SVGL={<CgShoppingCart size="22" color="#fff" />}
                      uomid={uid}
                    />
                    <AddToCartButtonMod
                      disabled={
                        selected === false ||
                        (getProductById_data?.package_product_type !== true &&
                          (getProductById_data?.check_stock?.desc ||
                            getProductById_data?.check_stock?.available_qty ===
                              0)) ||
                        product_state?.isLoading === true ||
                        getProductById_data?.check_stock?.available_req_qty == 0
                          ? true
                          : false
                      }
                      htmlFor="productbyid"
                      type="buynow"
                      type_for="buynow"
                      // navigate={navigate}
                      params={getProductById_data?.product_id}
                      bgColor="light"
                      btntype="outline"
                      color="dark"
                      btnBorderRadius="xxs"
                      borderColor="dark"
                      text={translate("buy-now", "Buy now")}
                      uomid={uid}
                    />
                  </div>
                  <div className="d-flex">
                    {getProductById_data?.quantity_control_step != 1 && (
                      <TextCom
                        color="primary"
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      >
                        {getProductById_data?.quantity_control_step} pcs per
                        package
                      </TextCom>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="stock_msg"
                style={{
                  fontStyle: "italic",
                  marginTop: "-10px",
                  marginBottom: "10px",
                }}
              >
                {location?.state !== null ? (
                  location?.state.instock === "false" ? (
                    <TextCom color="danger" className="" size="sm">
                      {translate("out-of-stock", "out-of-stock")}
                    </TextCom>
                  ) : (
                    <StockVariantMsgMod HandleImage={HandleImage} />
                  )
                ) : (
                  <StockVariantMsgMod HandleImage={HandleImage} />
                )}
                {getProductById_data?.package_product_type === true &&
                  selected === false && (
                    <TextCom color="danger" className="" size="sm">
                      {translate(
                        "select-variant-each-attribute",
                        "Select Variant Each Attribute"
                      )}
                    </TextCom>
                  )}
              </div>

              <div className="other-btns flex-wrap">
                {getProductById_data?.package_products?.length === 0 && (
                  <WishlistButtonMod
                    product_id={getProductById_data?.product_id}
                    style="rounded reverse mt-2"
                  />
                )}
                {getContactUsPage_data?.hotline && (
                  <div
                    className="call-us-btn d-flex gap-2 align-items-center"
                    style={{ paddingBottom: "10px" }}
                  >
                    <div className="icon">
                      <BsTelephone size={15} color="#333" />
                    </div>
                    <TextCom
                      as="a"
                      href={`tel:${getContactUsPage_data?.hotline}`}
                      style={{ textDecoration: "none" }}
                    >
                      {translate("call-us", "Call Us")}
                      {" : "}
                      <TextCom as="span" color="primary_light2">
                        {getContactUsPage_data?.hotline}
                      </TextCom>
                    </TextCom>
                  </div>
                )}
                {
                  <div>
                    <Tooltip
                      trigger={["click"]}
                      placement={isMobile == true ? "bottom" : "top"}
                      overlayInnerStyle={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        padding: "4px 8px",
                      }}
                      overlay={
                        <StyledPreview>
                          <style>
                            {`
                          .hover-bounce{
                            transition: 0.3s;
                          }
                          .hover-bounce:hover{
                            scale: 1.1;
                            transition: 0.3s;
                          }
                          `}
                          </style>
                          <div className={"d-flex gap-2"}>
                            <FacebookShareButton
                              className="hover-bounce"
                              url={current_url}
                            >
                              <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>

                            {/* <FacebookMessengerShareButton
                              className="hover-bounce"
                              url={current_url}
                              appId=""
                            >
                              <FacebookMessengerIcon size={32} round={true} />
                            </FacebookMessengerShareButton> */}

                            <ViberShareButton
                              className="hover-bounce"
                              url={current_url}
                              separator={"separator"}
                            >
                              <ViberIcon size={32} round={true} />
                            </ViberShareButton>

                            <TwitterShareButton
                              className="hover-bounce"
                              url={current_url}
                            >
                              <TwitterIcon size={32} round={true} />
                            </TwitterShareButton>

                            <LinkedinShareButton
                              className="hover-bounce"
                              url={current_url}
                            >
                              <LinkedinIcon size={32} round={true} />
                            </LinkedinShareButton>
                          </div>
                        </StyledPreview>
                      }
                    >
                      <div
                        className="call-us-btn d-flex gap-2 align-items-center"
                        style={{ paddingBottom: "10px", cursor: "pointer" }}
                      >
                        <div className="icon">
                          <TbShare3 size={17} color="#333" />
                        </div>
                        <TextCom
                          style={{ textDecoration: "none", userSelect: "none" }}
                        >
                          Share
                        </TextCom>
                      </div>
                    </Tooltip>
                  </div>
                }
              </div>
            </SkeletonWrap>

            {/* promotion con */}
            {/* {getProductById_data?.promotion?.length > 0 &&
            getProductById_data?.promotion.map((promotion_item: any) => (
              <CardCom
                className="discount-con flex-column p-2 mb-2 rounded-1"
                borderColor="status"
                cardBorderRadius="xxxs"
              >
                <div className="d-flex align-items-center gap-2">
                  {promotion_item?.promotion_type === "discount" && (
                    <>
                      <BsFillTagsFill color="#FF4200" />
                      <TextCom
                        className="percent"
                        color="status"
                        size="xl"
                        weight="lg"
                      >
                        {promotion_item?.discount_percent}% OFF
                      </TextCom>
                    </>
                  )}
                  {promotion_item?.promotion_type === "product" &&
                  promotion_item?.buy_product[0]?.product_template_id ===
                    promotion_item.reward_product[0]?.product_template_id ? (
                    <>
                      <BsFillTagsFill color="#FF4200" />
                      <TextCom
                        className="percent"
                        color="status"
                        size="xl"
                        weight="lg"
                      >
                        {translate("buy", "Buy")}{" "}
                        {promotion_item?.buy_product[0]?.min_qty}{" "}
                        {translate("get", "Get")}{" "}
                        {promotion_item?.reward_product[0]?.reward_qty}
                      </TextCom>
                    </>
                  ) : (
                    promotion_item?.promotion_type === "product" && (
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center gap-2">
                          <BsFillTagsFill color="#FF4200" />
                          {langCode === "en" ? (
                            <TextCom
                              className="percent"
                              color="status"
                              size="xl"
                              weight="lg"
                            >
                              {translate("buy", "Buy")}{" "}
                              {promotion_item?.buy_product[0]?.min_qty}{" "}
                              {translate("received", "Received")}{" "}
                              {promotion_item?.reward_product[0]?.reward_qty}{" "}
                              {translate("free-gift", "Free Gift")}
                            </TextCom>
                          ) : (
                            <TextCom
                              className="percent"
                              color="status"
                              size="xl"
                              weight="lg"
                            >
                              {en2mm_num(
                                promotion_item?.buy_product[0]?.min_qty,
                                langCode
                              )}{" "}
                              ခုဝယ်လျှင်{" "}
                              {en2mm_num(
                                promotion_item?.reward_product[0]?.reward_qty,
                                langCode
                              )}{" "}
                              ခုလက်ဆောင်
                            </TextCom>
                          )}
                        </div>
                        <div className="d-flex">
                          <BsFillTagsFill color="white" />
                          <TextCom
                            color="border"
                            className="promotion-name"
                            size="sm"
                          >
                            {promotion_item?.name}
                          </TextCom>
                        </div>
                        <img
                          src={
                            getProductById_data?.promotion[0]?.reward_product[0]
                              ?.reward_image || Image.DefaultDetailCard
                          }
                          alt=""
                          width="175px"
                        />
                      </div>
                    )
                  )}
                </div>
                {(promotion_item?.promotion_type === "discount" ||
                  promotion_item?.buy_product[0]?.product_template_id ===
                    promotion_item.reward_product[0]?.product_template_id) && (
                  <div className="d-flex align-items-center gap-2">
                    <BsFillTagsFill color="white" />
                    <TextCom color="border">{promotion_item?.name}</TextCom>
                  </div>
                )}
              </CardCom>
            ))} */}
          </ContentWrap>
        </div>
        {ispreview === true && (
          <div className="view-detail">
            <div className="detail">
              <TextCom as="a" color="primary" onClick={() => handleDetail()}>
                {translate("view-full-detail", "View Full Detail")}
              </TextCom>
            </div>
          </div>
        )}
      </StyledPreview>
    </>
  );
};
