import styled from "styled-components";

export const StyledProfileEditCon = styled.div`
 padding-block: 50px;
  @media(max-width: 991px){
    padding-top: 0;
  }
  .profile-edit-con{
    .title {
            margin-bottom: 20px;
            border-bottom: 1px solid ${props => props?.theme?.color?.typo?.line || '#ddd'};
            p{
                border-bottom: 2px solid ${props => props?.theme?.color?.typo?.primary};
                padding-bottom: 10px;
                width: fit-content;
                margin-bottom: -1px;
            }
            }

    input,select,textarea{ 
      background: '#ddd';
      border-radius: 5px;
      @media(min-width: 992px){
      width: 50%;}
    }

    input{
      font-weight: 400;
      &#phone::placeholder{
        /* color: red; */
        opacity: 1;
        color: ${props => props?.theme?.color?.typo?.text || '#333'};
        font-weight: 400;
      }
    }

    .buttons{
      a{ text-decoration: none;}
      button{ text-transform: uppercase; border-radius: 5px; width: 150px; min-width: fit-content; padding-inline: 30px;}
      button:first-child{
        border: none;
        }
    }
    .field-wrapper{
      margin-bottom: 15px;
    }
}
`