import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IoChevronUpOutline } from "react-icons/io5";
import Media from "react-media";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import { TbChevronLeft, TbChevronRight } from "react-icons/tb";

import {
  Container,
  TextCom,
  NewArrivalSlider,
  PaginationWrap,
  ProductSideBarFilter,
  InputCom,
  ProductCardMap,
  ButtonCom,
  RSSliderCom,
  BreadcrumbCom,
} from "components";
import { useHandleOther, useProduct, useHandleProduct, useOther } from "hook";
import { StyledProductListing } from "theme";
import { moneyFormat } from "utils";
import Icon from "asset/icon/pladco";

type ProductListProps = {};

export const ProductList: React.FC<ProductListProps> = (props) => {
  const location = useLocation();
  const [current, setCurrent] = useState(1);
  const {
    getProduct_data,
    getPackageProduct_data,
    getProductGroup_data,
    getProductCategory_data,
    getBrandList_data,
    getCountryOrigin_data,
    ProductAction,
    dispatch,
  } = useProduct();
  const { sort_by, sorting, setSorting, handleProductFilterChange } =
    useHandleProduct();
  const { getWebsiteSlider_data, getWebsiteSliderMobile_data } = useOther();
  const { translate, langStore } = useHandleOther();
  let langCode = langStore?.code;

  let package_products_obj = getProductGroup_data?.data?.filter(
    (x: any) => x.code === "package-products"
  )?.[0];

  let defaultPrice: any = {};

  const [clear, isClear] = useState(false);
  const [price, setPrice] = useState({
    min_price: "",
    max_price: "",
  });

  let rsProps1 = {
    dots: false,
    // fade: true,
    arrows: true,
    adaptiveHeight: true,
    infinite: false,
    slidesToShow: 9,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true
        },
      },
    ],
  };

  let brand_id: any = [];
  if (location.search.includes("brand")) {
    brand_id.push(location.search.split("=")[1]);
  }

  let cate_id: any = [];
  if (location.search.includes("category")) {
    cate_id.push(location.search.split("=")[1]);
  }

  useEffect(() => {
    // dispatch(ProductAction.getProductGroup());
    // dispatch(ProductAction.getPackageProduct());
  }, []);

  let minText: any = document.querySelector(".price-input .min-text");
  let maxText: any = document.querySelector(".price-input .max-text");
  // if (maxText) maxText.innerText = getProductCategory_data?.max_price

  useEffect(() => {
    if (clear === true) {
      setSorting("");
    } else {
      setSorting(sorting);
    }

    let req_obj = {
      lang: langCode,
      page_number: current,
      per_page: 12,
      category_id:
        Number(cate_id[0]) || getProductCategory_data?.selected_ids || "",
      brand_id: getBrandList_data?.selected_ids || Number(brand_id[0]) || "",
      country_id: getCountryOrigin_data?.selected_ids,
      min_price: defaultPrice?.min_price || price?.min_price || 0,
      max_price:
        defaultPrice?.max_price ||
        price?.max_price ||
        getProductCategory_data?.max_price ||
        "",
      sort_by: sorting,
    };
    if (location?.search?.includes("category")) {
      dispatch(ProductAction.getProduct(req_obj));
    } else {
      dispatch(
        ProductAction.getProduct({
          brand_id: Number(brand_id[0]),
          lang: langCode,
          page_number: current,
          per_page: 12,
          sort_by: sorting,
        })
      );
      dispatch(ProductAction.getBrandList(""));
    }
    isClear(false);

    //for currency format display
    if (maxText) {
      minText.innerText =
        moneyFormat(Math.ceil(Number(defaultPrice?.min_price)), langCode) ||
        moneyFormat(Math.ceil(Number(price?.min_price)), langCode) ||
        0;
      maxText.innerText =
        moneyFormat(Math.ceil(Number(defaultPrice?.max_price)), langCode) ||
        moneyFormat(Math.ceil(Number(price?.max_price)), langCode) ||
        moneyFormat(Math.ceil(Number(getProductCategory_data?.max_price)), langCode);
    }
  }, [
    getCountryOrigin_data?.selected_ids,
    Number(brand_id[0]),
    getProductCategory_data?.max_price,
    getBrandList_data?.selected_ids,
    getProductCategory_data?.selected_ids,
    current,
    price,
    sorting,
    langCode,
  ]);

  const onChangePaginate = (pagenumber: any) => {
    setCurrent(pagenumber);
    window.scroll(0, 0);
  };

  const handleSelectChange = (e: any) => {
    setSorting(e.target.value);
  };

  const onHandleChange = (filter_change_obj: any) => {
    handleProductFilterChange(filter_change_obj);
  };

  const handleClear = () => {
    isClear(true);
    onHandleChange({ type: "clear_all" });
    let sort = document.querySelector("select");
    if (sort) {
      sort.value = "";
    }
    setPrice({
      ...price,
      min_price: "",
      max_price: "",
    });
    let rangemin = document.querySelector("input.range-min");
    let pricemin = document.querySelector("input#price-min");
    rangemin.value = 0;
    pricemin.value = 0;
    let rangemax = document.querySelector("input.range-max");
    let pricemax = document.querySelector("input#price-max");
    rangemax.value = getProductCategory_data?.max_price;
    pricemax.value = getProductCategory_data?.max_price;
  };

  let tmp: any = null;
  const handlePriceChange = (data?: any) => {
    if (data.type === "min_price") {
      let min_price: any = Number(data.value) || "0";
      clearTimeout(tmp);
      tmp = setTimeout(() => {
        setPrice({
          ...price,
          min_price,
        });
      }, 1000);
    } else if (data.type === "max_price") {
      let max_price: any =
        Number(data.value) || getProductCategory_data.max_price || "";
      clearTimeout(tmp);
      tmp = setTimeout(() => {
        setPrice({
          ...price,
          max_price,
        });
      }, 1000);
    }
  };

  let min_input = document.querySelector("input#price-min");
  let max_input = document.querySelector("input#price-min");

  useEffect(() => {
    dispatch(
      ProductAction.getBrandList(getProductCategory_data?.selected_ids || "")
    );
  }, [getProductCategory_data?.selected_ids]);

  let handleCate = (data: any, id: any) => {
    let cate: any = null;
    let tmp = data?.filter((cate: any) => cate?.category_id === id);
    if (tmp?.length > 0) {
      cate = { ...cate, id: tmp[0]?.category_id, name: tmp[0]?.category_name };
    } else {
      data.map((item: any) => {
        tmp = item?.sub_category?.filter((sub: any) => sub?.category_id === id);
        if (tmp?.length === 0) {
          return;
        } else {
          cate = {
            ...cate,
            mainid: item?.category_id,
            mainname: item?.category_name,
            id: tmp[0]?.category_id,
            name: tmp[0]?.category_name,
          };
        }
      });
    }
    return cate;
  };

  let category_data = null;
  if (getProductCategory_data && cate_id[0]) {
    category_data = handleCate(
      getProductCategory_data?.data,
      Number(cate_id[0])
    );
  }

  let brand_data = null;
  if (getProduct_data && getProduct_data?.product_list?.length > 0) {
    brand_data = getProduct_data?.product_list[0]?.brand?.name;
  }

  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    (location?.state?.main_id || category_data?.mainid) && {
      name: location?.state
        ? location?.state?.main_name
        : category_data?.mainname,
      id: location?.state ? location?.state?.main_id : category_data?.mainid,
      route: `/products?category_ids=`,
    },
    {
      name: location?.state
        ? location?.state?.name
        : category_data?.name || brand_data,
      route: "#",
    },
  ];

  let rsProps = {
    dots: false,
    // fade: true,
    arrows: true,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      <StyledProductListing>
        <Container className="container product">
          <div className="row">
            {
              location.search.includes("category") && getProduct_data && (
                // getProduct_data.product_list.length > 0 && (
                <div className="col-xl-3 col-lg-5 filter-con">
                  <ProductSideBarFilter
                    priceVal={setPrice}
                    handleprice={handlePriceChange}
                    setprice={defaultPrice}
                  />
                </div>
              )
              // )
            }
            {
              getProduct_data && getProduct_data?.product_list && (
                // Number(location?.state) ===
                //   Number(getProduct_data?.product_list[0]?.categories[0]?.id) ? (
                <div
                  className={
                    location.search.includes("category")
                      ? `col-lg-9 product-list-con`
                      : "col-xl-12 col-lg-12 product-list-con brand"
                  }
                >
                  {location.search.includes("category") ? (
                    <div className="title-section">
                      <TextCom
                        as="h1"
                        size="xxxl"
                        weight="xl"
                        className="title"
                      >
                        {location?.state?.name ||
                          category_data?.name ||
                          getProduct_data?.product_list[0]?.categories[0]
                            ?.name ||
                          translate("all-product", "All Product")}
                      </TextCom>
                      {getProduct_data?.product_list?.length > 0 && (
                        <div className="sort-by d-flex align-items-center">
                          <TextCom weight="xl">
                            {translate("sort-by", "Sort by")}:
                          </TextCom>
                          <IoChevronUpOutline
                            color="#707070"
                            className="d-arrow"
                          />
                          <InputCom
                            isValidate={false}
                            input_as="select"
                            onChange={(e: any) => handleSelectChange(e)}
                            className="select-input"
                          >
                            <option value="all">
                              {translate("all", "All")}
                            </option>
                            {sort_by?.map((item: any, key: any) => {
                              return (
                                <option value={item?.value} key={key}>
                                  {item?.name}
                                </option>
                              );
                            })}
                          </InputCom>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <Container as="section" className="brand">
                        <RSSliderCom
                          rsProps={rsProps1}
                          data={
                            getBrandList_data ? getBrandList_data?.all_data : ""
                          }
                          route="/products?brand_id="
                          leftArrowSvg={
                            <TbChevronLeft size={30} color="#000000" />
                          }
                          rightArrowSvg={
                            <TbChevronRight size={30} color="#000000" />
                          }
                        />
                      </Container>
                      <div className="title-section brand">
                        <TextCom
                          as="h1"
                          size="xxxl"
                          weight="xl"
                          className="title"
                        >
                          {translate("product-for", "Product For")}{" "}
                          {location?.state?.name ||
                            getProduct_data?.product_list[0]?.brand?.name ||
                            translate("all-product", "All Product")}{" "}
                          {translate("brand", "Brand")}
                        </TextCom>

                        {getProduct_data &&
                          getProduct_data?.product_list?.length > 0 && (
                            <>
                              <IoChevronUpOutline
                                color="#707070"
                                className="d-arrow"
                              />
                              <div className="sort-by d-flex align-items-center">
                                <TextCom weight="xl">
                                  {translate("sort-by", "Sort by")}:
                                </TextCom>
                                <InputCom
                                  isValidate={false}
                                  input_as="select"
                                  onChange={(e: any) => handleSelectChange(e)}
                                >
                                  <option value="">
                                    {translate("all", "All")}
                                  </option>
                                  {sort_by?.map((item: any, key: any) => {
                                    return (
                                      <option value={item?.value} key={key}>
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                                </InputCom>
                              </div>
                            </>
                          )}
                      </div>
                    </>
                  )}

                  <div className="product-con">
                    <div className="row">
                      {getProduct_data &&
                      getProduct_data?.product_list?.length > 0 ? (
                        <ProductCardMap
                          data={getProduct_data?.product_list}
                          className={
                            location?.search.includes("brand")
                              ? `col-lg-3 col-6 p-card`
                              : `col-lg-4 col-6 p-card`
                          }
                        />
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center p-5 gap-3">
                          <img src={Icon.NoProduct} />
                          <TextCom size="lg">
                            {translate(
                              `no-product-found`,
                              `Sorry, we don’t have product currently. please check later.`
                            )}
                          </TextCom>
                        </div>
                      )}
                    </div>
                    <div className="pagination-con">
                      <PaginationWrap
                        onChange={onChangePaginate}
                        current={current}
                        total={getProduct_data?.total_products}
                        defaultPageSize={12}
                      />
                    </div>
                  </div>
                </div>
              )
              // ) : (
              //   <div className="col-lg-9 d-flex justify-content-center align-items-center text-center">
              //     {getProduct_data?.product_list?.length > 0 ? (
              //       <></>
              //     ) : (
              //       <TextCom>
              //         {translate(
              //           `no-product-found`,
              //           `Sorry, we don’t have product currently. please check
              //       later.`
              //         )}
              //       </TextCom>
              //     )}
              //   </div>
              // )
            }
          </div>
        </Container>
      </StyledProductListing>
    </>
  );
};
