import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { useAuth, useCart } from "hook";
import { CardCom, TextCom } from "components";
import "./style.scss";

type StaticSideBarProps = {
  data?: any[];
  route?: any;
  classname?: any;
  style?: any;
};
export const StaticSidebar: React.FC<StaticSideBarProps> = ({
  data,
  route,
  classname,
  style,
  ...props
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { AuthAction, dispatch } = useAuth();
  const { createCart_data, CartAction } = useCart();

  const OnHandleRoute = (id?: any, link?: any) => {
    if (id) navigate(route + id);
    if (link) {
      if (link === "logout") {
        HandleSignOut();
      } else {
        navigate(route + link);
      }
    }
  };

  const HandleSignOut = async () => {
    await dispatch(AuthAction.signOut());
    if (createCart_data?.order_id) {
      await dispatch(
        CartAction.deleteAllInCart({ order_id: createCart_data?.order_id })
      );
      await dispatch(CartAction.resetCart());
    }

    navigate(`/`);
  };

  const checkId = () => {
    if (isNaN(Number(id))) {
      return id;
    } else {
      return Number(id);
    }
  };

  return (
    <CardCom className={`${classname} static-sidebar`} style={style}>
      {data?.map((x, i) => (
        <div
          className={`item_con
        ${
          location?.pathname === x?.link || location?.pathname.includes(x?.link)
            ? "active"
            : ""
        }
        `}
          key={i}
        >
          <TextCom weight="xl" onClick={() => OnHandleRoute(x?.id, x?.link)}>
            {x.name}
          </TextCom>
          <hr />
        </div>
      ))}
    </CardCom>
  );
};
