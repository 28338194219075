import React, { useEffect } from "react";
import { BiSend } from "react-icons/bi";

import {
  TextCom,
  CardCom,
  FormContainerCom,
  InputCom,
  ButtonCom,
  BreadcrumbCom,
} from "components";
import { useHandleOther, useOther } from "hook";
import Icon from "asset/icon/pladco";
import "./style.scss";

export type IContactUsProps = {};

export const ContactUs: React.FC<IContactUsProps> = (props) => {
  const { translate, handleSentContactUs } = useHandleOther();
  const { getContactUsPage_data } = useOther();
  const { getMetaData_data, OtherAction, dispatch, langStore } = useOther();
  let langCode = langStore?.code;

  useEffect(() => {
    dispatch(OtherAction.getMetaData({ langCode }));
  }, [langCode]);
  let initialValues: any = {
    name: "",
    phone: "",
    email: "",
    message: "",
  };

  const validationSchema = (yup: any) =>
    yup.object({
      name: yup
        .string()
        .required(translate("name-is-required", "Name is required")),
      phone: yup
        .string()
        .required(
          translate("phone-number-is-required", "Phone Number is Required")
        ),
      email: yup.string().email(translate("invalid-email", "Invalid Email")),
      message: yup
        .string()
        .required(translate("message-required", "Message Required")),
    });

  const onHandleDataSubmit = (values: any, actions: any) => {
    setTimeout(async () => {
      handleSentContactUs(values);
      actions.resetForm(initialValues);
      actions.setSubmitting(false);
    }, 1000);
  };

  let address_arr: any = null;
  if (getContactUsPage_data && getContactUsPage_data?.branches) {
    address_arr = getContactUsPage_data?.branches[0]?.address?.split("\n");
  }

  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    {
      name: translate("contact-us", "Contact us"),
      route: "#",
    },
  ];

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      <div className="container contactus-container">
        {getMetaData_data?.["contact_info"]?.text_one && (
          <TextCom as="h1" size="xxxxl" weight="xl" textAlign="center">
            {getMetaData_data?.["contact_info"]?.text_one}
          </TextCom>
        )}
        {getMetaData_data?.["contact_info"]?.text_two && (
          <TextCom textAlign="center" className="mt-2">
            {getMetaData_data?.["contact_info"]?.text_two}
          </TextCom>
        )}
        <div className="row">
          <div className="col-lg-6">
            <TextCom as="h2" size="xxl" weight="xl" className="title">
              {translate("contact-information", "Contact Information")}
            </TextCom>
            <hr />
            {getContactUsPage_data &&
              getContactUsPage_data?.branches &&
              getContactUsPage_data?.branches.map((item: any, key: any) => (
                <div className="branch" key={key}>
                  <TextCom weight="xl" size="lg" className="title">
                    {item.name}
                  </TextCom>

                  <div className="phone d-flex gap-2 mb-3 mt-2">
                    <img src={Icon.phone} alt="" />
                    {item?.phone &&
                      item?.phone?.split(",").map((ph: any, key: any) => (
                        <TextCom
                          key={key}
                          as="a"
                          link
                          color="primary"
                          href={"tel:" + ph}
                        >
                          {ph}
                        </TextCom>
                      ))}
                  </div>
                  <div className="mail d-flex gap-2  mb-3">
                    <img src={Icon.mail} alt="" />
                    {item?.email &&
                      item?.email?.split(",").map((mail: any, key: any) => (
                        <TextCom
                          key={key}
                          as="a"
                          link
                          color="primary"
                          href={"mailto:" + mail}
                        >
                          {mail}
                        </TextCom>
                      ))}
                  </div>
                  <div className="address d-flex gap-2  mb-3 align-items-start">
                    <img src={Icon.map} alt="" />
                    <div>
                      {address_arr &&
                        address_arr?.map((add: any, key: any) => (
                          <TextCom className="mb-0" key={key}>
                            {add}
                          </TextCom>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            <div className="d-flex gap-2 mb-3">
              <img src={Icon.Globe} />
              <TextCom
                as="a"
                href="http://chainproshop.com"
                family="dm"
                target="_blank"
              >
                chainproshop.com
              </TextCom>
            </div>
            <div className="d-flex gap-2 mb-4">
              <img src={Icon.Globe} />
              <TextCom
                as="a"
                href="http://chainpromm.com"
                family="dm"
                target="_blank"
              >
                chainpromm.com
              </TextCom>
            </div>
            <div className="social d-flex align-items-center gap-2">
              <TextCom as="h2" size="lg" weight="xl" className="title">
                {translate("follow-us", "Follow Us")} :{" "}
              </TextCom>

              {getContactUsPage_data?.social_links?.map(
                (social: any, key: any) => (
                  <a href={social?.link} target="_blank" key={key}>
                    <img src={social?.social_image} />
                  </a>
                )
              )}
            </div>
            {getMetaData_data &&
              getMetaData_data["map-link"] &&
              getMetaData_data["map-link"]?.text_one !== false && (
                <>
                  <TextCom
                    as="h2"
                    size="xxl"
                    weight="xl"
                    className="title mt-5"
                  >
                    {translate("pickup-address", "Pickup Address")}
                  </TextCom>
                  <hr />
                  <iframe
                    src={getMetaData_data["map-link"].text_one}
                    width="100%"
                    height="350"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    className="mt-2"
                  ></iframe>
                </>
              )}
          </div>
          <div className="col-lg-6 message-form">
            <TextCom as="h2" size="xxl" weight="xl" className="title">
              {translate("send-a-message", "Send a message")}
            </TextCom>
            <hr />
            <FormContainerCom
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values: any, actions: any) =>
                onHandleDataSubmit(values, actions)
              }
            >
              {(formikProps: any) => (
                <>
                  <InputCom
                    name="name"
                    type="text"
                    label={translate("name", "Name")}
                    required
                    className="mb-2"
                  />
                  <InputCom
                    name="phone"
                    type="tel"
                    label={translate("phone", "Phone")}
                    required
                  />
                  <InputCom
                    type="email"
                    name="email"
                    label={translate("email", "Email")}
                  />
                  <InputCom
                    name="message"
                    type="text"
                    input_as="textarea"
                    rows="4"
                    label={translate("message", "Message")}
                    required
                  />
                  <ButtonCom
                    type="submit"
                    color="light"
                    bgcolor="primary_light2"
                    btnBorderRadius="xxxs"
                    text={
                      formikProps.isSubmitting
                        ? translate("sending", "Sending")
                        : translate("send", "Send")
                    }
                    SVGR={<BiSend color="white" />}
                  />
                </>
              )}
            </FormContainerCom>
          </div>
        </div>
      </div>
    </>
  );
};
