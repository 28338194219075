import styled from "styled-components";

export const StyledCartCon = styled.div`
  margin-top: -20px;
  padding-top: 20px;
  padding-bottom: 30px;
  /* background: ${props => props?.theme?.color?.typo?.background || '#fff'}; */
  .cart-con{
    /* background: ${props => props?.theme?.color?.typo?.background || '#fff'}; */
    padding-inline: 20px;
    @media(max-width: 768px){
      padding-inline: 10px;
    }
  }
  .inner_modal_con{
      padding-block: 30px;
      gap: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      aling-items: center;
      svg{
        margin: auto;
      }
      .modal_btn_con{
        gap: 10px;
        justify-content: center!important;
        button{
          @media(max-width: 425px){
            min-width: auto;
          }
          }
        }
  }
  .shopping-cart-con{
     /* margin-block: -70px; */
    .cart-table{
      padding-top: 30px;
      padding-right: 20px;
      position: relative;
      .clear-btn{
        margin-right: 0;
      }
      @media(max-width: 991px){
        padding-top: 0;
        padding-right: 12px;
      }
      @media(max-width: 375px){
        padding-inline: 0;
      }
      table{
        thead{
          text-transform: uppercase;
          th{
            p{text-align: center;}
          }
          th:first-child{
            p{text-align: left;}
            }
          @media(max-width: 991px){
            display: none;
          }
        }
        tr{
          border-bottom: 1px solid #e2e2e2;
        }
        td{
          position: relative;
          padding-block: 12px;
        }
      }
      .price-wrap,.qty-wrap,.subtotal-wrap{
        @media(min-width: 992px){
          padding-block: 25px;
        }
        @media(min-width: 1440px){
          padding-block: 50px;
        }
      }
      .qty-wrap{
        margin-top: -12px;
        @media(max-width: 1200px){
          margin-top: -6px;
        }
      }
      
      .promo{
        width: 100%;
        margin-top: 10px;
        &.product{
          height: 100%;
          img{
            width: 60px;
            margin-left: 36px;
            @media(min-width: 1441px){
              width: 100px;
              margin-left: 50px;
            }
          }
          p{
            padding-left: 28px;
            @media(max-width: 1200px){
              padding-left: 20px;
            }
            @media(min-width: 1441px){
              padding-left: 30px;
            }
          }
        }
        &.price,&.qty,&.subtotal,&.delete{
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-block: 25px;
        }
        &.qty{
          margin-top: -10px;
        }
        &.price{
          &.product-free{
            p{
              border: 1px solid ${props => props?.theme?.color?.typo?.primary_light2 || '#ddd'};
              background:  ${props => props?.theme?.color?.typo?.primary_light1 || '#ddd'};
              border-radius: 26px;
              line-height: 20px;
              padding-inline: 8px;
            }
          }
        }
      }
      .btn-con{
        margin-bottom: 10px!important;
      }
      .mobile-promo{
        img{
          max-width: 50px!important;
          margin-inline: 22px;
          &.promo-tag{
            margin-top: 15px;
          }
        }
        .product-free{
              border: 1px solid ${props => props?.theme?.color?.typo?.primary_light2 || '#ddd'};
              background:  ${props => props?.theme?.color?.typo?.primary_light1 || '#ddd'};
              border-radius: 26px;
              line-height: 16px;
              padding-inline: 6px;
              height: fit-content;
              margin-top: 3px;
              margin-right: 20px;
        }
      }

      .title{ margin-bottom: 10px;}
      .cancel_btn{
          /* margin-top: 3px; */
          cursor: pointer;
      }
      .product_name_wrap, .mb_product_name_wrap{
        align-items: center;
        cursor: pointer;
        position: relative;
        img{
          max-width: 160px;
          &.deli-image{
            width: 160px;
          }
          @media(max-width: 1200px){
            max-width: 130px;
          }
        }
        p{
          padding-left: 30px;
          text-transform: unset;
          @media(max-width: 425px){
            min-width: fit-content;
            /* max-width: 90%; */
          }
          span{ 
            font-weight: 400; 
            color: ${props => props?.theme?.color?.typo?.dark};
          }
          margin-bottom: 14px;
          @media(max-width: 1200px){
            padding-left: 20px;
          }
          @media(max-width: 375px){
            padding-left: 10px;
          }
        }
        @media(max-width: 1440px){
          img{max-width: 100px;}
        }
        @media(max-width: 992px){
          align-items: flex-start;
          p{ margin-bottom: 0;}
        }
        .cancel_btn{
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .product_name_wrap{
        @media(min-width: 992px){
          min-height: 120px;
          &.promo{
            min-height: fit-content;
          }
        }
        p.name{
          &:hover{
            color: ${props => props?.theme?.color?.typo?.primary_light2 || '#ddd'};
          }
        }
        p{
          margin: 0;
        }
        &.mb{
         
          p:first-child{
            @media(max-width: 375px){
              width: 160px !important;
            }
          }
        }
      }

      .btn-con{
        justify-content: space-between;
        align-items: center;
        margin-block: 20px;
        flex-direction: row-reverse;
        /* p{
          margin-left: 120px;
          @media(max-width: 375px){
            margin-left: 110px;
          }
        } */
      }
      .qty-wrap{
          .qty-btn-wrap{
            border-radius: 5px;
            input{ height: 48px; min-width:30px;}
            .center{ border-color: ${props => props?.theme?.color?.typo?.line};}
            border-color: ${props => props?.theme?.color?.typo?.line};
          }
          @media(max-width: 1200px){
            input{ max-height: 40px;}
          }
          &.promo{
            position: absolute;
            right: 0;
            width: fit-content;
          }
          @media(max-width: 375px){
            .left_btn_wrap,.right_btn_wrap{
              max-width: 20px;
            }
            .qty-input{
              max-width: 38px;
            }
          }
      }
      button{
        /* float: right; */
        border: 1px solid ${props => props?.theme?.color?.typo?.text || '#262626'};
        position: absolute;
        /* right: 20px; */
        left: 0;
        top: 100%;
        margin-top: 20px;
        &:hover,&:focus{
          background: ${props => props?.theme?.color?.typo?.line};
        }
        @media(max-width: 991px){
          min-width: fit-content;
        }
      }
    }
    
    .promo-price-wrap,.promo-qty-wrap,.promo-subtotal-wrap{
      position: absolute;
       top: 9%;
       left: 0;
       right: 0;
       @media(max-width: 1439px){
        top: 15%;
       }
    }
    .cancel_btn{
      @media(min-width: 992px){
        position: absolute;
        top: 43%;
        left: 0;
        right: 0;

      }
    }

    .cart-total{
      padding-block: 50px;
      padding-inline: 20px;
      background: ${props => props?.theme?.color?.typo?.bg1};
      border: 1px solid ${props => props?.theme?.color?.typo?.line};
      min-height: 70vh;
      .title{
        text-transform: uppercase;
      }
      p{ margin-bottom: 10px;}
      
      .point-input{
        .vlc_con{ 
          width: 75%;
          input{ width: 100%; height: 45px;}
          margin-bottom: 0;
          .vlc_con{ width: 95%;}
        }
        button{
        padding-inline: 5px;
        min-width: 25%; min-height: 45px;
        }
        margin-bottom: 40px;
      }

      .sub-total,.redeem{
        justify-content: space-between;
      }
      .redeem{ 
        margin-bottom: 10px;
        position: relative;
        .error-msg{
          position: absolute;
          right: 0;
          top: 55%;
        }
      }

      .total{
        justify-content: space-between;
        p{ margin-bottom: 0;}
        hr{ 
          width: 100%; 
          height: 1px; 
          color: ${props => props?.theme?.color?.typo?.line}
        }
      }
      .point-earned{
        justify-content: space-between;
        padding-bottom: 10px;
        /* margin-bottom: 25px; */
        /* border-bottom: 1px solid ${props => props?.theme?.color?.typo?.line}; */
        svg{margin-right: 10px;}
        p{ margin: 0;}
        .point{
          background: ${props => props?.theme?.color?.typo?.secondary};
          padding-block: 5px;
          padding-inline: 7px;
          border-radius: 70%;
        }
      }
      button{ 
        min-width: 100%;
        p{font-weight: 400; text-align: center; margin: 0; text-transform: uppercase;}
      }
      @media(max-width: 991px){
        margin-bottom: 30px;
        padding-inline: 10px;
      }
    }

    .no-item{
      padding-block: 30px;
      .d-flex{
        margin-top: 30px;
        padding: 50px;
        flex-direction: column;
        gap: 10px;
        p,a{ text-align: center;}
        svg{
          margin-bottom: 30px;
        }
        a:hover,a:focus{
          color: #F27043;
          opacity: 0.8;
        }
      }
      button{
        min-width: 25%;
        margin-top: 10px;
      }
    }
  .price-wrap,.qty-wrap,.subtotal-wrap{
    @media(min-width: 992px){
      /* position: absolute;
      top: 10%;
      left: 0;
      right: 0;    */
    }
  }
  .promo{
    &.price,&.qty,&.subtotal{
      @media(min-width: 992px){
        //  position: absolute;
        // bottom: 18%;
        // height: auto!important; 
      }
    }
  }
}
.total{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .total-card{
    width: 30%;
    border-radius: 10px;
    @media(max-width: 991px){
      padding-inline: 0;
    }
  }
  margin-top: 20px;
  padding-bottom: 30px;
  @media(max-width: 991px){
    .total-card{width: 100%;}
    margin-top: 90px;
  }
}
`