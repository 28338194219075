import styled from "styled-components";

interface StyledHeaderProps {
  props?: any;
  hpBgColor?: any;
  hsBgColor?: any;
  mbhBgColor?: any;
}

export const StyledHeaderCon = styled.div`
  width: 100%;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  box-shadow: 0px 1px 10px
    ${(props) => props?.theme?.color?.header?.shadow || "#d9d9d9"};
`;

export const StyledPrimaryHeaderCon = styled.div<StyledHeaderProps>`
  width: 100%;
  background: ${(props) =>
    props?.theme?.color?.header?.[props?.hpBgColor] ||
    props?.hpBgColor ||
    props?.theme?.color?.header?.background};
`;

export const StyledPrimaryHeaderContent = styled.div`
  .scrollable {
    overflow-y: scroll;
    scrollbar-width: thin;
    display: block;
  }
  .drop-down-scroll {
    scrollbar-color: #12249b transparent;
    height: 70vh;
    max-height: 70vh;
    margin-right: 15px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  ::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #6e6e6e;
  }

  .primary-header-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    ul {
      margin: 0;
      padding: 0;
    }
    a {
      text-decoration: none;
    }

    .logo-con {
      cursor: pointer;
      img {
        max-width: 200px;
        transition: all 0.3s ease-in;
      }
    }
    .right-con {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 1.5rem;
      transition: all 0.3s ease-in;
      .navigation {
        transition: all 0.3s ease-in;
        ul {
          list-style: none;
          &.main-nav {
            display: flex;
            gap: 1.5rem;
            @media (max-width: 1399px) {
              gap: 1rem !important;
            }
            @media (max-width: 1200px) {
              gap: 13px !important;
              .product-nav {
                /* border: 1px solid red; */
                gap: 2px !important;
              }
            }
          }
          li {
            &.nav-link {
              padding-bottom: 20px;
              @media (max-width: 1600px) {
                p.navlink {
                  font-size: 17px !important;
                }
              }
              @media (max-width: 1400px) {
                p.navlink {
                  font-size: ${(props) =>
                    props?.theme?.fontSize?.sm}px!important;
                }
              }
              .drop-down {
                position: absolute;
                top: 90%;
                border-radius: 3px;
                /* overflow: hidden; */
                box-shadow: 0px 12px 10px #0000000f;
                display: flex;
                flex-wrap: wrap;
                max-width: 400px;
                justify-content: flex-start;
                background: ${(props) =>
                  props?.theme?.color?.typo?.light || "#fff"};
                padding: 10px 5px;
                display: none;

                li {
                  padding: 5px 15px;
                  width: 50%;
                  p {
                    padding: 0;
                  }
                  p,
                  a {
                    @media (max-width: 1600px) {
                      font-size: 17px;
                    }
                  }
                  &:first-child {
                    padding: 0;
                    background: transparent;
                    width: 0;
                    svg {
                      width: 45px;
                      height: 45px;
                      position: absolute;
                      top: -26px;
                      left: 40%;
                    }
                  }
                  &:hover {
                    a {
                      color: ${(props) => props?.theme?.color?.typo?.primary};
                    }
                  }
                }
                &.care {
                  li {
                    width: 100%;
                  }
                }
              }
              &:hover {
                .drop-down {
                  display: flex;
                  &.care {
                    display: block;
                  }
                }
              }
            }
            p {
              cursor: pointer;
              padding-bottom: 3px;
              &.active {
                border-bottom: 3px solid
                  ${(props) => props.theme?.color?.typo?.primary};
              }
              &:hover,
              &:focus {
                color: ${(props) => props.theme?.color?.typo?.primary};
              }
            }

            .large-menu {
              position: absolute;
              width: 100vw;
              left: 0;
              top: 99%;
              background: ${(props) =>
                props?.theme?.color?.header?.background2 || "#fff"};
              box-shadow: 0px 10px 10px #00000012;
              height: 0;
              overflow-y: scroll;
              transition: all 0.3s ease;
              .wrap {
                display: flex;
                flex-wrap: wrap;
                gap: 5%;
                padding-block: 40px;
                row-gap: 10px;
                /* overflow-y: scroll; */
                .cate {
                  width: 150px;
                  @media (max-width: 1600px) {
                    p.main-cate {
                      font-size: 17px !important;
                    }
                    p {
                      font-size: 15px;
                    }
                  }
                  .sub2 {
                  }
                }
                .brand {
                  height: fit-content;
                  width: 160px;
                  @media (max-width: 1600px) {
                    p.main-cate {
                      font-size: 17px !important;
                    }
                    p {
                      font-size: 15px;
                    }
                  }
                }
                position: relative;
              }
              hr {
                width: 10%;
                height: 2.5px;
                background: ${(props) =>
                  props?.theme?.color?.header?.primary || "#fff"};
                opacity: 1;
                margin: auto;
                margin-bottom: 20px;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
              }
            }
            &.product-nav:hover {
              .large-menu {
                height: 75vh;
              }
              .large-menu.care {
                height: 50vh;
                overflow: unset;
              }
            }
          }
          @media (max-width: 1200px) {
            gap: 2rem;
          }
        }
      }
    }
    .user-con {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      /* gap: 30px; */
      height: 55px;
      transition: all 0.3s ease-in;
      .account-con {
        cursor: pointer;
        position: relative;
        a:hover {
          p {
            color: ${(props) =>
              props?.theme?.color?.typo?.primary_light2 || "#ddd"};
          }
        }
        img.profile-icon {
          width: 29px;
          cursor: pointer;
        }
        .user-dropdown {
          position: absolute;
          top: 100%;
          left: -42%;
          border-radius: 3px;
          /* overflow: hidden; */
          box-shadow: rgb(203 203 203 / 28%) 0.5px 5.5px 5px 0px;
          display: none;
          background: transparent;
          svg.arrow {
            position: absolute;
            top: -30px;
            left: 35%;
            /* right: 10%; */
            z-index: 5;
            width: 50px;
            height: 50px;
            color: #fff;
          }
          ul {
            list-style: none;
            background: ${(props) =>
              props?.theme?.color?.header?.light || "#fff"};
            &.user {
              min-width: 160px;
            }
            &.non-user {
              min-width: 100px;
            }
            li {
              padding: 10px 15px;
              cursor: pointer;
              &:not(:last-child) {
                border-bottom: 1px solid
                  ${(props) => props?.theme?.color?.header?.gray || "#fff"};
              }
              &:hover {
                background: ${(props) =>
                  props?.theme?.color?.header?.primary_light1 || "#ddd"};
                a {
                  p {
                    color: ${(props) =>
                      props?.theme?.color?.header?.text || "#333"};
                  }
                }
              }
              p {
                font-size: 15px;
              }
              img {
                width: 25px;
                height: 25px;
              }
              a,
              div {
                gap: 10px;
              }
            }
          }
        }
        &:hover,
        &:focus {
          .user-dropdown {
            display: block;
          }
        }
      }
      .user {
        position: relative;
        a {
          color: ${(props) => props?.theme?.color?.typo?.text || "#333"};
          p {
            text-transform: initial;
          }
        }
        .user_icon {
          cursor: pointer;
        }
        .user-menu {
          z-index: 100;
          &.active {
            display: block;
          }
        }
      }
      @media (max-width: 1300px) {
        p {
          /* font-size: 16px; */
        }
        hr {
          margin-inline: 13px;
        }
      }
      @media (max-width: 1024px) {
        hr {
          margin-inline: 8px;
        }
      }
      .user-menu {
        display: none;
        border: 1px solid ${(props) => props?.theme?.color?.typo?.line};
        position: absolute;
        top: 90%;
        z-index: 80;
        background: ${(props) => props?.theme?.color?.typo?.light};
        padding: 20px;
        box-shadow: 0.5px 0.5px 2px
          ${(props) => props?.theme?.color?.typo?.disabled};
        li {
          list-style: none;
          text-transform: uppercase;
          border-bottom: 1px solid ${(props) => props?.theme?.color?.typo?.line};
          align-items: center;
          display: flex;
          gap: 15px;
          &:not(:first-child) {
            cursor: pointer;
          }
          img {
            cursor: default;
          }
          .profile_icon {
            color: ${(props) => props?.theme?.color?.typo?.primary || "#ddd"};
          }
          p,
          .profile_icon {
            cursor: pointer;
          }
          .name,
          .phone {
            text-transform: none;
            cursor: default;
          }
          a {
            display: flex;
            align-items: center;
            gap: 15px;
          }
          &:first-child {
            align-items: flex-start;
            padding-top: 0;
            p {
              margin-bottom: 5px;
            }
          }
          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            cursor: pointer;
          }
          padding-block: 18px;
          button {
            width: max-content;
            text-transform: uppercase;
            padding-block: 8px;
            padding-inline: 15px;
            border-radius: 5px;
            p {
              padding: 0;
              margin-bottom: 0 !important;
            }
          }
        }
        @media (max-width: 1300px) {
          p {
            font-size: 14px;
          }
        }
      }

      .cart {
        cursor: pointer;
        position: relative;
        &:hover {
          p {
            /* color: ${(props) =>
              props?.theme?.color?.typo?.primary_light2 || "#ddd"}; */
          }
        }
        img {
          width: 29px;
        }
        .cart-badge {
          position: absolute;
          top: -8px;
          right: -8px;
          background: ${(props) => props?.theme?.color?.typo?.danger};
          width: 20px;
          height: 20px;
          border-radius: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            color: ${(props) =>
              props?.theme?.color?.typo?.light || "#fff"}!important;
          }
        }
        .cart-popup {
          position: absolute;
          display: none;
          min-width: 250px;
          background: #fff;
          top: 100%;
          z-index: 5;
          box-shadow: 0px 3px 10px #00000012;
          padding: 5px;
          .wrap {
            max-height: 260px;
            overflow-y: scroll;
          }
          svg.arrow {
            position: absolute;
            top: -30px;
            left: 42%;
            /* right: 10%; */
            z-index: 5;
            width: 50px;
            height: 50px;
            color: #fff;
          }
          ul {
            list-style: none;
          }
          li {
            /* padding: 10px; */
            gap: 10px;
            min-width: 220px;
            cursor: default;
            padding: 10px;
            border-bottom: 1px solid
              ${(props) => props?.theme?.color?.typo?.gray || "#ddd"};
            .promo {
              border: none;
              margin-top: 10px;
            }
            img {
              width: 50px;
              /* height: fit-content; */
              &.discount {
                padding: 9px;
              }
            }
            span.free {
              color: ${(props) => props?.theme?.color?.typo?.primary_light2};
              border: 1px solid
                ${(props) => props?.theme?.color?.typo?.primary_light2};
              border-radius: 25px;
              background: #c3caf8;
              padding-inline: 10px;
            }
          }
          .total {
            padding: 10px;
            button {
              margin-top: 10px;
              min-height: fit-content;
              padding: 8px 5px;
              width: 100%;
              border-radius: 5px;
              min-width: fit-content;
              p {
                color: ${(props) => props?.theme?.color?.typo?.light || "#fff"};
                font-size: 15px;
              }
            }
          }
        }
        &:hover {
          .cart-popup {
            display: block;
          }
        }
      }

      .wishlist {
        position: relative;
        cursor: pointer;
        &:hover {
          p {
            color: ${(props) =>
              props?.theme?.color?.typo?.primary_light2 || "#ddd"};
          }
        }
        img {
          margin-top: 3px;
          width: 28px;
          height: 28px;
          margin: auto;
        }
        .wish-badge {
          position: absolute;
          background: ${(props) => props?.theme?.color?.typo?.danger};
          top: -6px;
          right: 0;
          width: 20px;
          height: 20px;
          border-radius: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            color: ${(props) =>
              props?.theme?.color?.typo?.light || "#fff"}!important;
          }
        }
      }
      .language-select {
        .current-lang {
          flex-direction: column;
          gap: 0 !important;
          .drop-down {
            min-width: 80px;
            li {
              padding-inline: 10px;
              &:not(.active) {
                &:hover {
                  background: ${(props) =>
                    props?.theme?.color?.header?.primary_light1 || "#ddd"};
                }
              }
            }
          }
          img {
            max-width: 28px !important;
            height: 28px;
          }
          svg {
            display: none;
          }
        }
      }
    }

    /* ------- scroll header css ---------*/
    &.scroll-header {
      padding-top: 8px;
      .logo-con {
        padding-block: 5px;
        img {
          max-width: 100px;
        }
      }
      .right-con {
        gap: 0;
        .user-con {
          /* opacity: 0;
          visibility: hidden;
          height: 0;
          overflow: hidden; */
        }
        .navigation {
          opacity: 0;
          visibility: hidden;
          height: 0;
          overflow: hidden;
          .main-nav {
            li {
              /* padding-bottom: 6px; */
            }
          }
        }
      }
    }
  }
`;

export const StyledSecondaryHeaderCon = styled.div<StyledHeaderProps>`
  width: 100%;
`;

export const StyledSecondaryHeaderContent = styled.div``;

export const StyledMobileHeaderCon = styled.div<StyledHeaderProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) =>
    props?.theme?.color?.header?.[props?.hpBgColor] ||
    props?.hpBgColor ||
    props?.theme?.color?.header?.background};
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  box-shadow: 0px 1px 10px
    ${(props) => props?.theme?.color?.header?.shadow || "#d9d9d9"};
  padding-block: 5px;
`;

export const StyledMobileHeaderContent = styled.div`
  width: 100%;
  a {
    text-decoration: none;
  }
  .mb_header_content {
    display: flex;
    flex-directions: row;
    justify-content: space-between;
    align-items: center;

    .left_con {
      display: flex;
      width: 50%;
      img {
        max-width: 90px;
      }
      @media (min-width: 425px) {
        width: 30%;
      }
    }

    .right_con {
      display: flex;
      justify-content: flex-end;
      position: relative;
      gap: 16px;
      align-items: center;
      @media (max-width: 425px) {
        gap: 12px;
      }
      .user {
        position: relative;
        cursor: pointer;
        img.profile-icon {
          width: 26px;
          height: 26px;
        }
        .user-dropdown {
          position: absolute;
          top: 90%;
          right: -30px;
          background: ${(props) =>
            props?.theme?.color?.header?.light || "#fff"};
          border-radius: 3px;
          overflow: hidden;
          box-shadow: rgb(203 203 203 / 28%) 0.5px 5.5px 5px 0px;
          display: none;

          ul {
            list-style: none;
            &.user {
              min-width: 160px;
            }
            &.non-user {
              min-width: 100px;
            }
            li {
              padding: 10px 15px;
              cursor: pointer;
              &:not(:last-child) {
                border-bottom: 1px solid
                  ${(props) => props?.theme?.color?.header?.gray || "#fff"};
              }
              &:hover {
                background: ${(props) =>
                  props?.theme?.color?.header?.primary_light1 || "#ddd"};
                /* p{color: ${(props) =>
                  props?.theme?.color?.header?.light || "#fff"};} */
              }
              p {
                font-size: 15px;
              }
              img {
                width: 25px;
                height: 25px;
              }
              a,
              div {
                gap: 10px;
              }
            }
          }
        }
        &:hover,
        &:focus {
          .user-dropdown {
            display: block;
          }
        }
      }
    }
    .cart,
    .wishlist {
      position: relative;
      img {
        @media (max-width: 1300px) {
          width: 26px;
          height: 26px;
          /* border: 1px solid #fff; */
        }
      }
    }
    .cart {
      .cart-popup {
        position: absolute;
        display: none;
        min-width: 250px;
        background: #fff;
        top: 120%;
        right: -60px;
        z-index: 5;
        box-shadow: 0px 3px 10px #00000012;
        /* padding: 5px; */
        .wrap {
          max-height: 260px;
          overflow-y: scroll;
        }
        svg.arrow {
          position: absolute;
          top: -30px;
          left: 62%;
          /* right: 10%; */
          z-index: 5;
          width: 50px;
          height: 50px;
          color: #fff;
        }
        ul {
          list-style: none;
        }
        li {
          gap: 10px;
          min-width: 220px;
          cursor: default;
          padding: 10px;
          border-bottom: 1px solid
            ${(props) => props?.theme?.color?.typo?.gray || "#ddd"};
          .promo {
            border: none;
            margin-top: 10px;
          }
          img {
            width: 50px;
            /* height: fit-content; */
            height: auto;
            &.discount {
              padding: 9px;
            }
          }
          span.free {
            color: ${(props) => props?.theme?.color?.typo?.primary_light2};
            border: 1px solid
              ${(props) => props?.theme?.color?.typo?.primary_light2};
            border-radius: 25px;
            background: #c3caf8;
            padding-inline: 10px;
          }
        }
        .total {
          padding: 10px;
          border-top: 1px solid ${(props) => props?.theme?.color?.typo?.gray};
          button {
            margin-top: 10px;
            min-height: fit-content;
            padding: 8px 5px;
            width: 100%;
            border-radius: 5px;
            p {
              color: ${(props) => props?.theme?.color?.typo?.light || "#fff"};
            }
          }
        }
      }
      &:hover,
      &:focus {
        .cart-popup {
          display: block;
        }
      }
    }
    .wishlist {
      img {
        margin-top: 3px;
        width: 24px;
        height: 24px;
      }
    }
    .cart-badge,
    .wish-badge {
      position: absolute;
      top: -8px;
      right: -10px;
      background: ${(props) => props?.theme?.color?.typo?.danger};
      width: 20px;
      height: 20px;
      border-radius: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .menu_icon {
      color: ${(props) => props?.theme?.color?.header?.light};
      font-size: 25px;
      cursor: pointer;
    }
    .user_icon {
      cursor: pointer;
    }
    .user-menu {
      z-index: 100;
      &.active {
        display: block;
      }
    }

    .user-menu {
      display: none;
      border: 1px solid ${(props) => props?.theme?.color?.typo?.line};
      position: absolute;
      top: 80%;
      right: 0;
      z-index: 99;
      background: ${(props) => props?.theme?.color?.typo?.light};
      padding: 20px;
      box-shadow: 0.5px 0.5px 2px
        ${(props) => props?.theme?.color?.typo?.disabled};
      ul {
        padding: 0;
      }
      li {
        list-style: none;
        text-transform: uppercase;
        border-bottom: 1px solid ${(props) => props?.theme?.color?.typo?.line};
        align-items: center;
        .profile_icon {
          color: ${(props) => props?.theme?.color?.typo?.primary || "#ddd"};
        }
        p,
        .profile_icon {
          cursor: pointer;
        }
        .name,
        .phone {
          text-transform: none;
          cursor: default;
        }
        display: flex;
        gap: 15px;
        a {
          display: flex;
          align-items: center;
          gap: 15px;
        }
        &:first-child {
          align-items: flex-start;
          padding-top: 0;
          p {
            margin-bottom: 5px;
          }
        }
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
          cursor: pointer;
        }
        padding-block: 18px;
        button {
          text-transform: uppercase;
          padding-block: 8px;
          padding-inline: 15px;
          border-radius: 5px;
          p {
            padding: 0;
            text-align: center;
            margin-bottom: 0 !important;
          }
        }
      }
      @media (max-width: 1300px) {
        p {
          font-size: 14px;
        }
      }
      .welcome {
        margin: 0;
        li {
          flex-direction: column;
          p.title {
            font-size: 16px !important;
            margin: 0;
            text-transform: initial;
          }
          hr {
            margin: 0;
            width: 100%;
            height: 1px;
            color: ${(props) => props?.theme?.color?.typo?.border};
          }
          .d-flex {
            gap: 10px;
            button {
              min-width: 120px !important;
            }
          }
        }
      }
    }
  }
`;

export const StyledMobileDrawerCon = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  .drawer-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
    visibility: hidden;
    opacity: 0;
  }
  .drawer-content {
    max-width: 90vw;
    width: 90%;
    height: 100%;
    position: absolute;
    top: 0;
    right: -100%;
    transition: all 0.6s ease;
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    background: ${(props) => props?.theme?.color?.header?.light};
  }
  &.active {
    visibility: visible;
    opacity: 1;
    .drawer-wrapper,
    .drawer-content {
      visibility: visible;
      opacity: 1;
    }
    .drawer-content {
      right: 0;
    }
  }
`;

export const StyledMobileDrawerContent = styled.div`
  a {
    text-decoration: none;
  }
  position: relative;

  .mobile-drawer {
    .head {
      align-items: center;
      gap: 15px;
      padding-inline: 20px;
      padding: 12px 20px 20px 20px;
      background: ${(props) =>
        props?.theme?.color?.header?.background || "#ddd"};
      .close-icon {
        cursor: pointer;
      }
      /* .search-con {
        position: relative;
        flex-grow: 1;
        border: 1px solid ${(props) =>
        props?.theme?.color?.header?.primary_light1 || "#ddd"};
        border-radius: 8px;
        .field-wrapper{
          width: 100%;
        }
        input {
          display: flex;
          height: 43px!important;
          padding-inline: 10px;
          @media (max-width: 1300px) {
            min-width: auto;
            height: 40px;
          }
        }
        img {
          width: 23px;
        }
        .icon-wrap{
          background: ${(props) =>
        props?.theme?.color?.header?.primary_bold || "#ddd"};
          justify-content: center;
          align-items: center;
          padding-inline: 12px;
        }
      } */
      .sub-drawer {
        min-height: 40px;
        svg {
          margin-right: 10px;
        }
      }
    }
    .content {
      padding: 20px;
      button {
        border: none;
        padding: 0;
        justify-content: unset;
        gap: 10px;
        text-transform: uppercase;
        min-height: fit-content;
      }
      ul {
        padding: 0;
      }
      li {
        list-style: none;
        margin-bottom: 20px;
        display: flex;
        gap: 10px;
        a {
          display: flex;
          gap: 10px;
          color: unset;
          width: 100%;
          .d-flex {
            svg {
              margin-right: 10px;
            }
          }
        }
        p {
          width: fit-content;
        }
        .arrow {
          position: absolute;
          right: 20px;
        }
      }
      hr {
        margin-block: 20px;
      }
      .language-switch {
        margin-top: 20px;
        width: 85px;
        display: flex;
        justify-content: space-between;
        padding: 6px;
        padding-left: 0;
        background: ${(props) => props?.theme?.color?.typo?.light};
        li {
          margin: 0;
          list-style: none;
          min-width: 70px;
          &:first-child {
            border-right: 1px solid
              ${(props) => props?.theme?.color?.typo?.disabled};
            padding-right: 12px;
          }
          &:not(.active) {
            opacity: 0.25;
          }
          &.active {
            cursor: not-allowed;
          }
          &:not(.active) {
            cursor: pointer;
            opacity: 0.25;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      .call {
        align-items: center;
        a {
          color: unset;
        }
      }

      .collapse-con {
        .collapse-item {
          margin-bottom: 10px;
          .collapse-header-wrap {
          }
          .collapse-content {
            margin-left: 10px;
          }

          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }

      .brand {
        img {
          max-width: 20%;
          margin-inline: 8px;
          margin-bottom: 15px;
          border: 1px solid
            ${(props) => props?.theme?.color?.typo?.line2 || "#d9d9d9"};
          border-radius: 5px;
          @media (max-width: 768px) {
            margin-inline: 5px;
          }
          &.active {
            border: 1px solid
              ${(props) => props?.theme?.color?.typo?.primary_light2 || "#ddd"};
          }
        }
      }
    }
    .lang-change {
      align-items: flex-start !important;
      margin-top: 20px;
    }
  }
`;

export const StyledLanguageSwitch = styled.div`
  img {
    height: fit-content !important;
  }
  .language-switch {
    display: flex;
    padding: 6px;
    background: ${(props) => props?.theme?.color?.typo?.light};
    li {
      list-style: none;
      min-width: 65px;
      &:first-child {
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid
          ${(props) => props?.theme?.color?.typo?.disabled};
        @media (max-width: 1300px) {
          padding-right: 5px;
          margin-right: 5px;
        }
      }
      &:last-child {
        padding-left: 10px;
      }
      &.active {
        cursor: not-allowed;
      }
      &:not(.active) {
        cursor: pointer;
        opacity: 0.25;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .language-select {
    .current-lang {
      img {
        max-width: 23px;
      }
      cursor: pointer;
      position: relative;
      .drop-down {
        li {
          padding: 8px 18px;
          img {
            margin-right: 5px;
            max-width: 23px;
          }
          &:not(:last-child) {
            border-bottom: 1px solid
              ${(props) => props?.theme?.color?.typo?.line2 || "#ddd"};
          }
          cursor: pointer;
          &.active {
            cursor: not-allowed;
          }
        }
        box-shadow: 0px 1px 10px
          ${(props) => props?.theme?.color?.header?.shadow || "#d9d9d9"};
        position: absolute;
        top: 100%;
        z-index: 9999999;
        background: ${(props) => props?.theme?.color?.typo?.light || "#fff"};
        display: none;
        border-radius: 5px;
        overflow: hidden;
      }
      &:hover {
        .drop-down {
          display: block;
        }
      }
    }
  }

  .lang-change {
    cursor: pointer;
  }
`;
