import styled from "styled-components";

export const StyledProductCardCom = styled.div`
  transition: box-shadow 0.4s ease;
  height: 100%;
  cursor: pointer;

  .white-space {
    white-space: pre;
  }
  .route {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
  &:not(.slider-card):hover {
    .image-promo {
      box-shadow: 0 -2px 20px #00000021;
      img {
        transform: scale(1);
      }
    }
  }
  &.slider-card {
    div {
      border: none;
      background: transparent;
    }
    .image-promo img {
      border-radius: 15px;
    }
    .gift-main-con {
      display: none !important;
    }
  }
  .product-card {
    height: 100%;
    position: relative;
    /* border: 1px solid ${(props) => props?.theme?.color?.typo?.gray}; */
    border-radius: 15px !important;
    .no-item {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgb(203 203 203 / 50%);
      z-index: 3;
      border-radius: 15px;
      @media (max-width: 991px) {
        border-radius: 5px;
      }
    }
    .out-of-stock {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 10px;
      width: 120px;
      height: 120px;
      border-radius: 120px;
      background: ${(props) => props?.theme?.color?.typo?.gray};
      z-index: 3;
      text-transform: uppercase;
      padding-inline: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      @media (max-width: 1600px) {
        font-size: ${(props) => props?.theme?.fontSize?.sm}px;
        width: 100px;
        height: 100px;
        line-height: 22px;
        padding-inline: 8px;
      }
      @media (max-width: 768px) {
        font-size: ${(props) => props?.theme?.fontSize?.xs}px;
        line-height: 22px;
        width: 80px;
        height: 80px;
      }
    }
    .route {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
    }
    .description {
      padding-inline: 8px;
      .px-2 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      @media (max-width: 991px) {
        .price {
          row-gap: 0 !important;
        }
      }
      @media (max-width: 600px) {
        .price {
          span {
            font-size: 13px;
          }
        }
      }
    }
  }
  div {
    padding: 0;
    &:first-child {
      /* height: 100%; */
      flex-direction: column;
      /* justify-content: center; */
      overflow: hidden;
      border-radius: ${(props) =>
        props?.theme?.buttonVariable?.borderRadius.xxxs}px;
    }
    .image-promo {
      border-radius: 15px;
      overflow: hidden;
      img {
        border-radius: 15px;
      }
    }
    .image-promo,
    .description {
      position: relative;
      margin-bottom: 30px;
      /* padding-bottom: 20px; */
      .display-center {
        display: flex;
        align-items: center;
        justify-content: center;
        &.eye-con {
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
      .eye {
        position: absolute;
        right: 15px;
        top: 15px;
        padding: 8px;
        background: ${(props) => props?.theme?.color?.typo?.line};
        border-radius: 50%;
        width: 36px;
        height: 36px;
        cursor: pointer;
        transition: all 0.7s ease;
        z-index: 5;
        &.d-text {
          display: none;
          right: 46px;
          width: auto;
          height: auto;
          background: none;
        }
      }

      .active {
        &:hover {
          .eye {
            background: ${(props) => props?.theme?.color?.typo?.dark};
            fill: ${(props) => props?.theme?.color?.typo?.light} !important;
          }
          .eye.d-text {
            background: none;
            display: block !important;
          }
        }
      }
      .percent-dis {
        position: absolute;
        top: 15px;
        left: 15px;
        background: ${(props) =>
          props?.theme?.color?.typo?.secondary || "#ddd"};
        padding: 10px;
        height: 34px;
        border-radius: 30px;
        @media (max-width: 562px) {
          h4 {
            font-size: 10px;
          }
        }
        @media (max-width: 425px) {
          span {
            font-size: 11px !important;
          }
        }
      }
      .gift-text-1 {
        position: absolute;
        width: 100%;
        bottom: 0;
        border: none;
        height: 35px;
        @media (max-width: 425px) {
          p {
            font-size: 12px;
          }
        }
        @media (min-width: 1400px) {
          p {
            font-size: 14px;
          }
        }
      }

      .gift-main-con {
        width: 100%;
        position: absolute;
        bottom: 1px;
        border: none;
        height: 35px;
       
        @media(max-width: 760px){
          // bottom: 1px !important;
        }
        background: ${(props) => props?.theme?.color?.typo?.primary_light2};
        .gift-text {
          padding: 0px;
          width: 100%;
          overflow: initial;
          display: flex;
          justify-content: center;
          align-items: center;
          // position: relative;
          @media (max-width: 768px) {
            align-items: flex-start !important;
            padding-left: 15%;
          }
        }
        h6 {
          /* padding-left: 10px; */
          width: 100%;
          &.receive-text {
            line-height: 13px;
            @media (max-width: 768px) {
              justify-content: flex-start !important;
              width: 75%;
            }
            @media (max-width: 388px) {
              width: 60%;
            }
          }
          @media (min-width: 1400px) {
            font-size: 14px;
          }
          /* font-size: 13px; */
          @media (max-width: 425px) {
            font-size: 10px;
            margin-left: -7px;
          }
        }
        .free-gift {
          position: absolute;
          bottom: 3px;
          right: 3px;
          border: 1px solid
            ${(props) => props?.theme?.color?.card?.border || "#333"};
          border-radius: 5px;
          img {
            width: 60px;
            height: 60px;
            border-radius: 5px;
            @media (max-width: 1300px) {
              top: -15px;
              width: 50px;
              height: 50px;
            }
          }
          .free-item {
            display: flex;
            position: absolute;
            right: 0;
            top: -10px;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: ${(props) => props?.theme?.color?.typo?.primary_light2};
            width: 22px;
            height: 22px;
            @media (max-width: 425px) {
              top: -16px;
            }
          }
          @media (max-width: 425px) {
            h5 {
              font-size: 11px;
            }
          }
        }
    }
  }
`;
