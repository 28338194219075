import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import {
  FormContainerCom,
  ButtonCom,
  InputCom,
  TextCom,
  ModalCom,
} from "components";
import { useHandleAuth, useHandleOther } from "hook";
import { StyledAuthCon } from "theme";

type ChangePassProps = {};

export const ChangePass: React.FC<ChangePassProps> = (props: any) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { translate } = useHandleOther();
  const { handleResetPassword, handleChangePassword } = useHandleAuth(props);

  const [visable, isVisable]: any = useState({
    status: false,
    text: translate(
      "change-password-successful",
      "Change password successful!"
    ),
  });
  const handleModal = () => {
    isVisable(!visable.status);
  };

  let initialValues: any = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  let validate_obj = (yup?: any) => {
    let obj = {
      old_password: yup
        .string()
        .required(
          translate("old-password-is-required", "Old Password is Required")
        ),
      new_password: yup
        .string()
        .required(
          translate("new-password-is-required", "New Password is Required")
        ),
      confirm_password: yup
        .string()
        .required(
          translate(
            "confirm-password-is-required",
            "Confirm Password is Required"
          )
        )
        .oneOf(
          [yup.ref("new_password"), null],
          translate("password-must-match", "Password Must Match")
        ),
    };

    if (location?.state?.from === "forgot") {
      delete obj.old_password;
    }

    return obj;
  };

  const validationSchema: any = (yup: any) => yup.object(validate_obj(yup));
  const onDataSubmit = (values?: any, actions?: any) => {
    actions.setSubmitting(true);
    setTimeout(async () => {
      if (location?.state?.from === "forgot") {
        handleReset(values);
      } else {
        let res = await handleChange(values);
      }
      actions.setSubmitting(false);
    }, 1000);
  };

  const handleReset = async (values?: any) => {
    let post_data = {
      otp: location?.state?.otp,
      mobile: location?.state?.mobile,
      password: values?.new_password,
    };
    let res = await handleResetPassword(post_data);
    if (res?.payload?.status === "success") {
      isVisable({
        ...visable,
        status: true,
        text: translate(
          "rest-password-successful",
          "Reset password successful"
        ),
      });
    }
  };

  const handleChange = async (values?: any) => {
    let post_data = {
      old_password: values?.old_password,
      new_password: values?.new_password,
    };
    handleChangePassword(post_data);
    // if (res?.payload?.status === "success") {
    //   isVisable({ ...visable, status: true });
    // }
  };

  const handleRouteChange = (route?: any, params?: any) => {
    navigate(route);
  };

  return (
    <StyledAuthCon>
      <div className="container">
        <div className="auth_con" style={{ paddingBlock: "30px" }}>
          <div className="row">
            <div className="col-lg-6 form_wrap change-con">
              <div className="form_card">
                <TextCom
                  size="xxxl"
                  textAlign="center"
                  weight="xl"
                  className="title_txt mb-3"
                >
                  {translate("change-password", "Change Password")}
                </TextCom>
                <FormContainerCom
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values?: any, actions?: any) =>
                    onDataSubmit(values, actions)
                  }
                >
                  {(formikProps?: any) => (
                    <>
                      {location?.state?.from !== "forgot" && (
                        <InputCom
                          label={translate("old-password", "Old Password")}
                          name="old_password"
                          type="password"
                          required
                        />
                      )}
                      <InputCom
                        label={translate("new-password", "New Password")}
                        name="new_password"
                        type="password"
                        required
                      />
                      <InputCom
                        label={translate(
                          "confirm-password",
                          "Confirm Password"
                        )}
                        name="confirm_password"
                        type="password"
                        required
                      />
                      <div className="form_foot_sec mt-4">
                        <ButtonCom
                          color="light"
                          bgcolor="primary_light2"
                          btnBorderRadius="xxxs"
                          text={
                            formikProps.isSubmitting
                              ? translate("saving", "Saving")
                              : translate("save", "Save")
                          }
                        />
                      </div>
                    </>
                  )}
                </FormContainerCom>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalCom isModal={visable?.status} handleIsModal={() => handleModal()}>
        <div className="signup_success_con">
          <TextCom
            size="xl"
            weight="xl"
            textAlign="center"
            className="wel_txt mb-2"
          >
            {translate("successful", "Successful")}
          </TextCom>
          <TextCom textAlign="center" className="desc_txt mb-3">
            {location?.state?.title || visable?.text}{" "}
          </TextCom>
          <div className="btn_wrap">
            <ButtonCom
              color="light"
              bgColor="primary"
              btnBorderRadius="xxxs"
              text={translate("login", "Login")}
              onClick={() => handleRouteChange("/user/signin")}
              className="m-auto"
            />
          </div>
        </div>
      </ModalCom>
    </StyledAuthCon>
  );
};
