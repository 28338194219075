import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FiUser, FiHeart, FiFileText } from "react-icons/fi";

import {
  TextCom,
  CardCom,
  ButtonCom,
  Container,
  FormContainerCom,
  InputCom,
  NewTabCom,
  StaticSidebar,
  BreadcrumbCom,
} from "components";
import {
  useOther,
  useHandleOther,
  useHandleAuth,
  useAuth,
  useCart,
} from "hook";
import { StyledProfileEditCon } from "theme";

type ProfileEditProps = {};

export const ProfileEdit: React.FC<ProfileEditProps> = (props) => {
  const { translate } = useHandleOther();
  const { handleUpdateProfile } = useHandleAuth(props);
  const { getProfile_data } = useAuth();
  const { getShippingLocations_data } = useCart();

  const dataSource = [
    {
      title: translate("profile", "profile"),
      key: "profile",
      desc: " ",
      link: "/user/profile",
      icon: <FiUser color="#262626" />,
    },
    {
      title: translate("wishlist", "Wishlist"),
      key: "wishlist",
      link: "/user/wishlist",
      icon: <FiHeart color="#262626" />,
    },
    {
      title: translate("order-history", "Order History"),
      key: "order-history",
      link: "/user/my_orders",
      icon: <FiFileText color="#262626" />,
    },
  ];

  const onHandleDataSubmit = async (values: any, actions: any) => {
    actions.setSubmitting(true);

    setTimeout(async () => {
      let postData: any = {
        name: values?.name,
        email: values?.email,
        street: values?.address,
        state_id: Number(values?.state) || getProfile_data?.state?.id,
        township_id: Number(values?.township) || getProfile_data?.township?.id,
      };
      handleUpdateProfile(postData);
      actions.setSubmitting(false);
    }, 1000);
  };

  let initialValues = {
    name: getProfile_data?.name,
    email: getProfile_data?.email,
  };

  const validationSchema = (yup: any) =>
    yup.object({
      name: yup
        .string()
        .required(`${translate("name_required", "Name is required")}`),
      phone: yup.string(),
      email: yup.string().email(),
      state: yup.string(),
      township: yup.string(),
      address: yup.string(),
    });

  const sidebar_data = [
    {
      name: translate("my-profile", "My Profile"),
      link: "/user/profile",
    },
    {
      name: translate("my-order", "My Order"),
      link: "/user/my_orders",
    },
    {
      name: translate("my-wishlist", "My Wishlist"),
      link: "/user/wishlist",
    },
    {
      name: translate("change-password", "My Wishlist"),
      link: "/user/change_password",
    },
    {
      name: translate("logout", "Logout"),
      link: "logout",
    },
  ];

  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    {
      name: translate("profile-edit", "Profile Edit"),
      route: "#",
    },
  ];

  let item_con = document.querySelectorAll(".item_con");
  if (item_con[1]) {
    item_con[1].classList.add("active");
  }

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      <StyledProfileEditCon>
        <Container className="profile-edit-con container">
          <div className="row">
            <div className="col-md-12 col-lg-3">
              <StaticSidebar
                data={sidebar_data}
                route=""
                classname="side-bar"
              />
            </div>
            <div className="col-md-12 col-lg-9">
              <div className="title">
                <TextCom size="xxl" weight="lg">
                  {translate("edit-profile", "Edit profile")}
                </TextCom>
              </div>
              <FormContainerCom
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: any, actions: any) => {
                  onHandleDataSubmit(values, actions);
                }}
              >
                {(formikProps: any) => {
                  const { values } = formikProps;
                  return (
                    <div className="info">
                      <div>
                        <InputCom
                          name="name"
                          type="text"
                          label={translate("name", "Name")}
                          required
                        />
                        <InputCom
                          name="phone"
                          placeholder={getProfile_data?.mobile}
                          type="text"
                          label={translate("phone", "Phone")}
                          disabled
                        />
                        <InputCom
                          type="email"
                          name="email"
                          label={translate("email", "Name")}
                        />
                        <InputCom
                          placeholder={
                            getProfile_data?.state?.name || "Choose State"
                          }
                          label={translate(
                            "states-divisions",
                            "State/Division"
                          )}
                          name="state"
                          input_as="select"
                        >
                          {
                            <option value="" selected disabled hidden>
                              {getProfile_data?.state?.name ||
                                translate("select", "Select")}
                            </option>
                          }
                          {getShippingLocations_data &&
                            getShippingLocations_data[0]?.state_ids?.map(
                              (item: any) => {
                                return (
                                  <option value={item?.id}>{item?.name}</option>
                                );
                              }
                            )}
                        </InputCom>
                        <InputCom
                          placeholder={
                            getProfile_data?.township?.name || "Choose Township"
                          }
                          label={translate("township", "Township")}
                          name="township"
                          input_as="select"
                          defaultValue=""
                        >
                          {
                            <option value="" selected disabled hidden>
                              {getProfile_data?.township?.name ||
                                translate(
                                  "choose-after-state/divison",
                                  "Choose After State/Divison"
                                )}
                            </option>
                          }
                          {(values?.state || getProfile_data?.state) &&
                            getShippingLocations_data &&
                            getShippingLocations_data[0]?.state_ids
                              ?.filter(
                                (state: any) =>
                                  Number(state.id) ===
                                  Number(
                                    values.state || getProfile_data?.state?.id
                                  )
                              )[0]
                              ?.township_ids?.map((town: any) => {
                                return (
                                  <option value={town?.id}>{town?.name}</option>
                                );
                              })}
                        </InputCom>
                        <InputCom
                          placeholder={getProfile_data?.address}
                          name="address"
                          type="text"
                          input_as="textarea"
                          rows="4"
                          label={translate("address", "Address")}
                        />
                      </div>
                      <div className="buttons d-flex gap-3">
                        <Link to="/user/profile">
                          {/* <ButtonCom type="outline" bgcolor={}>{translate("cancel", "Cancel")}</ButtonCom> */}
                          <ButtonCom
                            text={translate("cancel", "Cancel")}
                            type="outline"
                            color="light"
                            bgcolor="primary_light2"
                          />
                        </Link>
                        <ButtonCom
                          type="submit"
                          text={translate("save", "Save")}
                          color="light"
                          bgcolor="primary_light2"
                        />
                      </div>
                    </div>
                  );
                }}
              </FormContainerCom>
            </div>
          </div>
        </Container>
      </StyledProfileEditCon>
    </>
  );
};
