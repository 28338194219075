import axios from 'axios'

import { configKey } from './config'
import { authStore } from 'service'

const client: any = axios.create()

client.interceptors.request.use(async (config?: any) => {
  config.baseURL = configKey.apiUrl

  let res = await authStore.getAuth()

  config.headers['access-token'] = res?.access_token?.[0]?.token || ''

  return config
}, (error?: any) => {
  return Promise.reject(error)
})

client.interceptors.response.use(async (response?: any) => {
  if (!response.data) {
    return Promise.reject(response)
  }
  return response
}, (error?: any) => {
  if (axios.isAxiosError(error)) {
    return error.response
  } else {
    return Promise.reject(error)
  }
})

export default client