import React, { useEffect } from "react";
import { BiSend } from "react-icons/bi";

import {
  TextCom,
  CardCom,
  FormContainerCom,
  InputCom,
  ButtonCom,
  BreadcrumbCom,
  StaticSidebar,
} from "components";
import { useHandleOther, useOther } from "hook";
import Icon from "asset/icon/pladco";
import "./style.scss";

export type IFaqProps = {};

export const FAQ: React.FC<IFaqProps> = (props) => {
  const { translate } = useHandleOther();
  const { getFaq_data, dispatch, OtherAction, langStore } = useOther();
  let langCode = langStore?.code;

  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    {
      name: translate("faq", "FAQ"),
      route: "#",
    },
  ];

  const static_data = [
    {
      name: translate("terms-and-cons", "Terms & Condition"),
      id: "terms_and_condition",
      link: "/page/terms_and_condition",
    },
    {
      name: translate("privacy-policy", "Privacy Policy"),
      id: "privacy_policy",
      link: "/page/privacy_policy",
    },
    {
      name: translate("faq", "FAQ"),
      id: "faq",
      link: "/faq",
    },
    // {
    //   name: translate(
    //     "deliver-and-payment-method",
    //     "Delivery & Payment Method "
    //   ),
    //   id: "delivery_and_payment",
    //   link: "/page/delivery_and_payment",
    // },
    {
      name: translate("how-to-register", "How to register"),
      id: "how_to_register",
      link: "/page/how_to_register",
    },
    {
      name: translate("how-to-buy", "How to buy"),
      id: "how_to_buy",
      link: "/page/how_to_buy",
    },
  ];

  useEffect(() => {
    dispatch(OtherAction.getFaq(langStore?.code));
  }, [langCode]);

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      <div className="container faq-container">
        <div className="row">
          <div className="col-md-12 col-lg-9">
            <TextCom as="h1" size="xxxxl" weight="xl" className="page-title">
              {translate(
                "frequently-asked-questions",
                "frequently-asked-questions"
              )}
            </TextCom>
            {getFaq_data &&
              getFaq_data?.map((item: any, key: any) => (
                <div className="ques-con mb-2" key={key}>
                  <TextCom as="h1" size="xxl" weight="xl">
                    {item?.ques}
                  </TextCom>
                  <TextCom
                    dangerouslySetInnerHTML={{
                      __html: item?.ans,
                    }}
                  />
                </div>
              ))}
          </div>
          <div className="col-md-12 col-lg-3">
            <StaticSidebar data={static_data} route="" />
          </div>
        </div>
      </div>
    </>
  );
};
