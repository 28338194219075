import React, { useEffect, useState } from "react";
import { array } from "yup";


export const uom_price = (product, uom, quantity) => {
  const discountamount_array = [];
  let quantity_check = 0;
  product?.price?.map((value) => {
    if (quantity_check < value.quantity) {
      quantity_check = value.quantity;
    }
  })

  // console.log(product);
  // console.log(uom, "aot");
  // console.log(quantity);
  let uom_string = null;

  if (uom == null) {
    uom_string = product?.uom?.find(x=> x.display == true)?.name;
    if (uom_string == null) {
      product?.uom?.length == 1 
      ?
      uom_string = product?.uom[0].name
      :
      uom_string = product?.uom?.find(x=> x.default == true)?.name
    }
  }
  else if (typeof uom == "string") {
    uom_string = uom;
  }
  else if (typeof uom == "number") {
    // console.log(uom, "hello")
    // console.log(uom_string, "hello")
    // console.log(product, "hello")
    // console.log(product.uom[0].name, "hello")

    // console.log(product.uom.find(x => x.id === uom).name, "hello")
    uom_string = product?.uom?.find(x => x.id === uom)?.name;
  }
  else {
    return null;
  }

  const original_price = product?.price?.map((value) => {
    // console.log(quantity_check);
    // console.log(quantity)
    // console.log(value.uom)
    // console.log(uom_string);
    if (quantity_check <= quantity && value.uom == uom_string) {
      return value.price;
    }
    else {
      return undefined;
    }
  }).filter((value) => value !== undefined);

  // console.log(original_price);
  for (let i = 0; i < product.promotion.length; i++) {
    if (product.promotion[i].promotion_type == "discount") {
      discountamount_array.push(product.promotion[i].discount_percent);
      break
    }
  }
  // let total_price = original_price[0] * quantity;
  let total_price = original_price[0];
  let discounttype = "";
  discountamount_array[0] ? discounttype = "percent" : discounttype = "fix"
  return promotion_price([total_price, discounttype, discountamount_array[0] ? discountamount_array[0] : 0]);
}

export const promotion_price = (props) => {
  let total_price = props[0];
  let discounttype = props[1];
  let discountamount = props[2];
  let pricing = { price: "", discounttype: discounttype, discountamount: discountamount, hello: "", };
  if (discounttype == "percent") {
    pricing.price = total_price - (total_price * discountamount) / 100;
  }
  else {
    pricing.price = total_price - discountamount;
  }
  return pricing
}

export const cap_price_calculate = (getProductById_data, uom) => {
  let cap_price = getProductById_data?.cap_data[0]
  ?.find(
    (x) =>
      x.id ===
      getProductById_data?.cap_data?.selected_variant_ids[0]
  )
  ?.color_info.find(
    (y) =>
      y.id ===
      getProductById_data?.cap_data?.selected_variant_ids[1]
  )?.price;
  let reference_ratio = getProductById_data?.uom?.find((z)=> z.type == "reference")?.ratio;
  let selected_ratio = getProductById_data?.uom?.find((z)=> z.id == uom)?.ratio;
  let selected_type = getProductById_data?.uom?.find((z)=> z.id == uom)?.type;

  let final_cap_price 

  if (selected_type == "smaller") {
    final_cap_price = cap_price/selected_ratio;
  }
  else if(selected_type == "bigger"){
    final_cap_price = cap_price*selected_ratio;
    return final_cap_price
  }
  else{
    final_cap_price = cap_price
  }
  
  return final_cap_price
}