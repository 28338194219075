import styled from "styled-components";

export const StyledOrderlistCon = styled.div`
  padding-bottom: 50px;   
  padding-top: 30px;   
   @media(max-width: 991px){
    padding-top: 0;
  }
  .title {
            margin-bottom: 20px;
            border-bottom: 1px solid ${props => props?.theme?.color?.typo?.line || '#ddd'};
            p{
                border-bottom: 2px solid ${props => props?.theme?.color?.typo?.primary};
                padding-bottom: 10px;
                width: fit-content;
                margin-bottom: -1px;
            }
            }

  .title{
      line-height: 46px; 
      margin-bottom: 30px!important;
      @media(max-width: 768px){
        font-size: 22px;
        margin-bottom: 20px!important;
      }
  }

  .status-filter{
    select{
      border-radius: 5px;
    }
  }
  .order-history-content{
    /* padding-right: 0; */
    .table-con{
      border: 1px solid ${props => props?.theme?.color?.border || '#D9D9D9'};
      border-radius: 10px;
    }
    table{
      /* border: 1px solid ${props => props?.theme?.color?.typo?.border || '#D9D9D9'}; */
      border-radius: 10px;
      overflow: hidden;
      thead{
        th{
          padding-block: 14px;
          @media(max-width: 991px){
            padding-left: 10px;
          }
        }
      }
      tr{
        border-bottom: 1px solid ${props => props?.theme?.color?.border || '#D9D9D9'};
      }
      button{
        color: ${props => props?.theme?.color?.typo?.light || '#fff'}!important;
        @media(max-width: 767px){
          font-size: ${props => props?.theme?.fontSize?.sm || 14}px;
          padding-inline: 8px;
        }
      }
    }
    .product_name_wrap.status {
      @media(max-width: 768px){
        font-size: ${props => props?.theme?.fontSize?.sm || 14}px;
      }
    }
    p{margin: 0;}
    .order-card{
      padding: 0;
      flex-direction: column;
      margin-bottom: 20px;
      .order-title{
        padding: 15px;
        width: 100%;
        justify-content: space-between;
        background: ${props => props?.theme?.color?.typo?.bg1 || '#f9f9f9'};
        border-bottom:  1px solid ${props => props?.theme?.color?.typo?.line || '#D9D9D9'};
        .status{
          border-radius: 15px;
          padding-inline: 10px;
          font-style: italic;
          background: ${props => props?.theme?.color?.typo?.status || '#FF4200'};
          line-height: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 22px;
        }
        @media(max-width: 1024px){
          padding: 8px;
        }
      }
      .order-detail{
        .detail{
          padding: 15px;
          width: 85%;
          span{ margin-left: 50px;}
          .d-flex{ 
            justify-content: space-between;
            div.point{
              font-style: italic;
            }
            .detail-info{
              display: flex;
              gap: 30px;
              @media(max-width: 1100px){
                gap: 8px;
              }
            }
            @media(max-width: 991px){
              flex-direction: column;
              gap: 10px;
              p{font-size: 15px;}
            }
          }
          @media(max-width: 1024px){
              padding-inline: 8px;
          }
          @media(min-width: 992px) and (max-width: 1024px){
            p{ font-size: 15px;}
          }
          @media(max-width: 375px){
            padding-right: 0;
          }
        }
        .detail-btn{
          width: 15%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-left: 1px solid ${props => props?.theme?.color?.typo?.line || '#d9d9d9'};
          button{
            min-width: fit-content;
            padding: 0;
            border-color: transparent;
            svg{margin-right: 3px;}
            text-transform: uppercase;
            @media(max-width: 1200px){
              svg{ 
                margin-right: 0;
                width: 17px;
                height: 17px;
              }
              font-size: 14px;
            }
            @media(max-width: 375px){
             flex-direction: column;
            }
          }
          @media(max-width: 1200px){
            width: 16%;
          }
          @media(max-width: 991px){
            width: 30%;
          }
        }
      }
    }
    .no-item{
    padding-block: 50px;
   }
  }
`