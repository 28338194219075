import styled from "styled-components";

export const StyledPreview = styled.div`
  /* position: relative; */
  .preview-content {
    gap: 30px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .select-uom {
    border-radius: 5px;
  }
  select.select-uom {
    padding: 5px;
    height: auto;
  }

  &.preview {
    width: 60vw;
    gap: 20px;
    max-height: 70vh;
    /* overflow-y: scroll; */
    .button-con {
      button {
        padding-inline: 8px;
      }
    }
    @media (max-width: 1200px) {
      width: 80vw;
    }
    .preview-content {
      padding: 30px 30px 0 30px;
      @media (max-width: 768px) {
        padding: 20px 20px 0 20px;
      }
    }
  }
  .detail {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      text-decoration: underline;
      cursor: pointer;
    }
    margin-bottom: 15px;
  }

  hr {
    height: 1px;
    /* width: 100%; */
    background: ${(props) => props?.theme?.color?.typo?.line || "#d9d9d9"};
    margin-bottom: 15px;
    opacity: 0.2;
  }
  .attribute-con {
    .select-variant {
      min-width: 300px;
      padding-block: 5px;
      @media (max-width: 500px) {
        min-width: 100%;
      }
    }
    .att-card {
      border: 1px solid
        ${(props) => props?.theme?.color?.typo?.line || "#d9d9d9"};
    }
  }
  /*   
  .stock-msg{
    opacity: 1;
    visibility: visible;
    &.hide{
      opacity: 0;
      visibility: hidden;
    }
  } */

  .v_message {
    span {
      &:not(:last-child)::after {
        content: ", ";
      }
    }
  }

  .remind {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${(props) => props?.theme?.color?.modal?.light_green};
    height: 0;
    overflow: hidden;
    transition: all 0.5s ease;
    margin: 10px 0 15px 0;
    position: relative;
    .mx-3 {
      cursor: pointer;
    }
    p {
      margin-inline: 20px;
      padding-block: 5px;
    }
    &.active {
      height: fit-content;
      padding: 20px 0;
    }
    svg {
      position: absolute;
      top: 5px;
      right: -5px;
    }
  }
  .view-detail {
    padding-top: 15px;
    border-top: 1px solid ${(props) => props?.theme?.color?.line || "#d9d9d9"};
    position: absolute;
    bottom: 0;
    width: 100%;
    background: ${(props) => props?.theme?.color?.typo?.light || "#fff"};
    z-index: 999999;
  }
  .price-mod {
    width: fit-content;
    .d-flex {
      justify-content: space-between;
      .field-wrapper {
        /* width: 55%;
        @media(max-width: 425px){
          width: 58%;
        } */
      }
    }
    .wrapper {
      flex-wrap: wrap;
    }
    p {
      line-height: 32px;
      min-width: fit-content;
    }
  }
  &.preview {
    .content-wrap {
      min-height: 60vh;
      max-height: 65vh;
      overflow-y: scroll;
      padding-bottom: 70px;
      ::-webkit-scrollbar {
        display: none;
      }
      .desc {
        margin-bottom: 1px;
      }
      .price-mod {
        p {
          margin-bottom: 0px !important;
        }
      }
    }
  }

  /* slick-slide set background for transparent images (zoom image transparent issue)*/
  .preview-content {
    .image_wrap {
      .slick-slide {
        background: white;
      }
      .slide-con {
        img {
          background: white;
        }
      }
    }
  }

  .select-wrap {
    position: relative;
    width: max-content;
    @media (max-width: 500px) {
      width: 100%;
    }
    select {
      -webkit-appearance: none;
      &:not(:disabled) {
        color: ${(props) => props?.theme?.color?.typo?.text || "#d9d9d9"};
        background: transparent;
      }
    }
    .arrow {
      position: absolute;
      top: 30%;
      right: 5px;
    }
  }

  .select-wrap {
    &.uom {
      /* margin-left: 10px; */
      min-width: 190px;
      /* width: 45%; */
      /* @media(max-width: 425px){
        width: 58%;
      } */
    }
  }
`;
export const ImageWrap = styled.div`
  width: 33%;
  margin-bottom: 5px;
  /* position: relative; */
  /* overflow: hidden; */
  border-radius: 5px;
  img {
    width: 100%;
  }
  /* overflow: hidden; */
  .skeleton-wrap {
    overflow: unset !important;
    .skeleton {
      /* max-width: 300px; */
    }
  }
  .slide-con {
  }
  /* .slick-slider,.rsslider-con{
    @media(min-width: 992px){
      max-width: 300px!important;
    }
  } */
  .slide-nav {
    .slick-list {
      .slick-track {
        .slick-current {
          img {
            border: 1px solid ${(props) => props?.theme?.color?.dark || "#000"};
            border-radius: 3px;
          }
        }
      }
    }
  }
  .slide-con {
    .slick-slide {
      padding: 0;
      /* @media(min-width: 992px){
      max-width: 300px!important;
      } */
    }
    margin-bottom: 40px;
  }
  @media (max-width: 991px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 0;
  }
  .percent-dis {
    position: absolute;
    top: 10px;
    right: 20px;
    background: ${(props) => props?.theme?.color?.typo?.secondary || "#ddd"};
    padding: 10px;
    height: 34px;
    border-radius: 30px;
    /* border: 1px dashed ${(props) => props?.theme?.color?.typo?.light}; */
    h4{
      span{
        margin-left: 2px;
      }
    }
    @media (max-width: 562px) {
      h4 {
        font-size: 14px;
        span{
          font-size: 10px !important;
        }
      }
    }
    pointer-event: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gift-main-con {
    width: 100%;
    border: none;
    padding: 0;
    margin-top: -10px;
    margin-bottom: 10px;
    height: 45px;
    background: ${(props) =>
      props?.theme?.color?.typo?.primary_light2 || "#ddd"};
    display: flex;
    align-items: center;
    justify-content: center;
    .gift-text {
      position: absolute;
      padding: 0px;
      width: 100%;
      align-items: unset;
      h6 {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 16px;
        width: 100%;
      }
    }
    h6 {
      padding-left: 10px;
    }
    .free-gift {
      width: fit-content;
      align-items: center;
      position: absolute;
      bottom: -15px;
      right: 0;
      z-index: 9999;
      border: 1px solid
        ${(props) => props?.theme?.color?.card?.border || "#333"};
      border-radius: 5px;
      h5 {
        margin-left: 20px;
        text-transform: uppercase;
      }
      img {
        width: 74px;
        height: 74px;
        border-radius: 5px;
      }
      .free-item {
        display: flex;
        position: absolute;
        right: 0;
        top: -10px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: ${(props) => props?.theme?.color?.typo?.primary_light2};
        width: 22px;
        height: 22px;
      }
    }
  }
`;
export const ContentWrap = styled.div`
  width: 60%;
  /* padding-top: 10px; */
  padding-right: 10px;
  position: relative;

  @media (max-width: 768px) {
    max-height: unset;
    min-height: unset;
    width: 100%;
  }
  p {
    text-align: left;
  }
  .desc {
    /* margin-bottom: 15px; */
  }
  .qty-btn-wrap {
    .center {
      .qty-input {
        position: unset;
      }
    }
  }
  .qty-text {
    @media (max-width: 1400px) {
      font-size: ${(props) => props?.theme?.fontSize?.sm}px;
    }
    @media (max-width: 1024px) {
      /* display: none; */
    }
  }
  .save-money {
    display: inline-block;
    background: ${(props) => props?.theme?.color?.typo?.status};
    padding: 2px 5px;
    border-radius: 5px;
  }

  .button-con {
    position: relative;
    margin-bottom: 1.5rem;
    /* border: 1px solid red; */
    width: fit-content;
    .qty-wrap {
      justify-content: space-between;
    }
    .add-to-cart-btns {
      button {
        /* border-radius: 15px!important; */
        &:hover,
        &:focus {
          box-shadow: 2px 2px 7px 0px #302f2f4d;
        }
      }
      div:first-child {
        button:hover {
          background-color: ${(props) =>
            props?.theme?.color?.typo?.primary_light2}!important;
          p {
            color: ${(props) => props?.theme?.color?.typo?.light}!important;
          }
          svg {
            color: ${(props) => props?.theme?.color?.typo?.light}!important;
          }
        }
      }
    }
    .disable-layer {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: ${(props) => props?.theme?.color?.typo?.gray};
      opacity: 0.5;
    }
    button {
      min-width: fit-content;
      gap: 5px;
      div {
        margin-right: 0;
      }
      p {
        /* text-transform: uppercase; */
        text-align: center;
      }
      @media (max-width: 1400px) {
        /* padding-inline: 10px; */
        p {
          font-size: 14px;
        }
      }
      @media (max-width: 425px) {
        p {
          font-size: 12px;
          text-align: center;
        }
        min-width: 20%;
        padding-block: 5px;
      }
      &:hover,
      &:focus {
        background: ${(props) => props?.theme?.color?.typo?.dark || "#text"};
        p,
        svg {
          color: ${(props) => props?.theme?.color?.typo?.light || "#fff"};
        }
        box-shadow: 2px 2px 5px
          ${(props) => props?.theme?.color?.typo?.border || "#eee"};
      }
    }
    .qty-btn-wrap {
      flex-direction: row-reverse;
      border-radius: 25px;
      overflow: hidden;
      .left_btn_wrap,
      .right_btn_wrap {
        @media (max-width: 1200px) {
          width: fit-content;
          padding-inline: 5px;
        }
      }
      input {
        @media (max-width: 1200px) {
          /* max-width: 40px; */
        }
      }
      @media (max-width: 1200px) {
        max-width: 120px;
      }
      @media (max-width: 425px) {
        width: 50%;
      }
      .center {
        order: 1;
        border: none;
      }
      .left_btn_wrap,
      .right_btn_wrap {
        background: ${(props) =>
          props?.theme?.color?.typo?.background || "#d2d2d2"};
      }
    }
  }

  .wishlist-btn {
    width: fit-content;
    margin-bottom: 10px;
    flex-direction: row-reverse;
    /* position: absolute;
    right: 0;
    top: 10px; */
    .heart {
      margin-left: 0;
    }
  }

  .call-us-btn {
    .icon {
      width: 28px;
      height: 28px;
      border: 1px solid #333;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .other-btns {
    display: flex;
    align-items: center;
    gap: 1rem;
    @media (max-width: 425px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      .icon,
      .heart {
        width: 30px;
        height: 30px;
        min-width: unset;
        min-height: unset;
        svg,
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .hover-bounce {
    transition: 0.3s;
  }
  .hover-bounce:hover {
    margin-bottom: 3px;
    padding-bottom: 5px;
    scale: 1.4;
    transition: 0.3s;
  }

  .discount-con {
    width: 70%;
    padding-right: 13px !important;
    .percent {
      font-size: 20px !important;
    }
    .promotion-name {
      margin-left: 9px;
    }
    img {
      margin-left: 25px;
      border: 1px solid
        ${(props) => props?.theme?.color?.typo?.line || "#d2d2d2"};
      border-radius: 5px;
      width: 30%;
      margin-top: 5px;
    }
  }
`;
