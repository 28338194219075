import styled from 'styled-components'

interface StyledMainContainerProps {
  props?: any,
  bgColor?: any
}

export const StyledMain = styled.main`
  min-height: 50vh;
  margin-top: 152px;
  @media (max-width: 991px) {
    margin-top: 62px;
  }
`

export const StyledContainer = styled.div < StyledMainContainerProps > `
  background: ${props => props?.theme?.color?.container?.[props?.bgColor] || props?.bgColor || props?.theme?.color?.container?.light};
`
