import Icon from 'asset/icon/pladco'
export const languages = [
  {
    lang: 'English',
    code: 'en',
    lan_icon: Icon.LanguageImg,
  },
  {
    lang: 'မြန်မာစာ',
    code: 'my',
    lan_icon: Icon.LanguageImg,
  },
]
