import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import {
  TextCom,
  Container,
  StaticSidebar,
  BreadcrumbCom,
  Title,
} from "components";
import { useOther, useHandleOther } from "hook";
import "./style.scss";

export type IStaticProps = {};
export const GenericPage: React.FC<IStaticProps> = (props) => {
  const { translate } = useHandleOther();
  const { id } = useParams();
  const { getPageCode_data, langStore, dispatch, OtherAction } = useOther();
  const langCode = langStore?.code;
  const location = useLocation();

  useEffect(() => {
    dispatch(
      OtherAction.getPageCode({
        code: id,
        lang: langCode,
      })
    );
  }, [id, langCode]);
  const static_data = [
    {
      name: translate("terms-and-cons", "Terms & Condition"),
      id: "terms_and_condition",
      link: "/page/terms_and_condition",
    },
    {
      name: translate("privacy-policy", "Privacy Policy"),
      id: "privacy_policy",
      link: "/page/privacy_policy",
    },
    {
      name: translate("faq", "FAQ"),
      id: "faq",
      link: "/faq",
    },
    // {
    //   name: translate(
    //     "deliver-and-payment-method",
    //     "Delivery & Payment Method "
    //   ),
    //   id: "delivery_and_payment",
    //   link: "/page/delivery_and_payment",
    // },
    {
      name: translate("how-to-register", "How to register"),
      id: "how_to_register",
      link: "/page/how_to_register",
    },
    {
      name: translate("how-to-buy", "How to buy"),
      id: "how_to_buy",
      link: "/page/how_to_buy",
    },
  ];

  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    {
      name: translate(
        getPageCode_data?.name || location?.pathname?.split("/")[2],
        getPageCode_data?.name || location?.pathname?.split("/")[2]
      ),
      route: "#",
    },
  ];

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      <Container className="generic-page container">
        <div className="row">
          <div className="col-md-12 col-lg-9">
            {getPageCode_data?.name && (
              <TextCom as="h1" size="xxxxl" weight="xl" className="page-title">
                {getPageCode_data?.name}
              </TextCom>
            )}
            {getPageCode_data?.image && (
              <img
                src={getPageCode_data?.image}
                alt="page-img"
                style={{ maxWidth: "100%", marginBottom: "30px" }}
              />
            )}
            <TextCom
              dangerouslySetInnerHTML={{
                __html: getPageCode_data?.description,
              }}
            />
          </div>
          <div className="col-md-12 col-lg-3">
            <StaticSidebar data={static_data} route="" />
          </div>
        </div>
      </Container>
    </>
  );
};
