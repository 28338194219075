import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  FiEdit,
  FiKey,
  FiPlus,
  FiUser,
  FiHeart,
  FiFileText,
} from "react-icons/fi";
import { RiDeleteBin7Line } from "react-icons/ri";
import { MdLocationOn } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { TbCrown } from "react-icons/tb";
import { FaQuestion } from "react-icons/fa";

import {
  useHandleOther,
  useAuth,
  useCart,
  useHandleCart,
  useTheme,
} from "hook";
import {
  TextCom,
  ButtonCom,
  Container,
  NewTabCom,
  ModalCom,
  FormContainerCom,
  InputCom,
  BreadcrumbCom,
  StaticSidebar,
} from "components";
import { StyledProfileCon } from "theme";

type ProfileProps = {};
export const Profile: React.FC<ProfileProps> = (props) => {
  const navigate = useNavigate();
  const { translate, langStore } = useHandleOther();
  const { themeContext } = useTheme();
  const { getProfile_data, AuthAction } = useAuth();
  const {
    CartAction,
    dispatch,
    getShippingLocations_data,
    getShippingAddressById_data,
    getShippingAddress_data,
  } = useCart();

  const {
    handleCreateShippingAddress,
    handleEditShippingAddress,
    handleDeleteShippingAddress,
  } = useHandleCart();

  const langCode = langStore?.code;
  const [isModal, setIsModal] = useState(false);
  const [modalType, setModalType] = useState({
    type: "",
    id: "",
  });
  const [deletenoti, setDeletenoti] = useState(false);
  useEffect(() => {
    dispatch(AuthAction.getProfile());
    dispatch(CartAction.getShippingAddress());
    dispatch(CartAction.getShippingLocations("MM"));
  }, []);

  const dataSource = [
    {
      title: translate("profile", "profile"),
      key: "profile",
      desc: " ",
      icon: <FiUser color="#262626" />,
    },
    {
      title: translate("wishlist", "Wishlist"),
      key: "wishlist",
      link: "/user/wishlist",
      icon: <FiHeart color="#262626" />,
    },
    {
      title: translate("order-history", "Order History"),
      key: "order-history",
      link: "/user/my_orders",
      icon: <FiFileText color="#262626" />,
    },
  ];

  let initialValues = {
    name:
      modalType.type === "edit"
        ? getShippingAddressById_data && getShippingAddressById_data[0]?.name
        : "",
    phone:
      modalType.type === "edit"
        ? getShippingAddressById_data && getShippingAddressById_data[0]?.mobile
        : "",
    state:
      modalType.type === "edit"
        ? getShippingAddressById_data &&
          getShippingAddressById_data[0]?.state?.id
        : "",
    township:
      modalType.type === "edit"
        ? getShippingAddressById_data &&
          getShippingAddressById_data[0]?.township?.id
        : "",
    address:
      modalType.type === "edit"
        ? getShippingAddressById_data &&
          getShippingAddressById_data[0]?.address1
        : "",
  };

  const handleModal = async (type: any, id?: any) => {
    setModalType({ type: type, id: id });
    setIsModal(!isModal);
  };

  useEffect(() => {
    if (modalType?.type === "edit") {
      dispatch(CartAction.getShippingAddressById(modalType?.id));
    }
  }, [modalType?.type, modalType?.id]);

  const onHandleDataSubmit = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    setTimeout(async () => {
      let postData = {
        name: values?.name,
        address: values?.address,
        state: values?.state,
        township: values?.township,
        user_phone: values?.phone,
      };
      if (modalType.type === "edit") {
        handleEditShippingAddress({
          ...postData,
          edit_id: getShippingAddressById_data[0]?.id,
        });
      } else handleCreateShippingAddress(postData);
      actions.setSubmitting(false);
    }, 1000);
    setIsModal(!isModal);
  };
  const validationSchema = (yup: any) =>
    yup.object({
      name: yup
        .string()
        .required(`${translate("name_required", "Name is required")}`),
      phone: yup.string().required("Phone no is requried"),
      state: yup.string(),
      township: yup.string(),
      address: yup.string(),
    });
  const [ID, setid] = useState(0);
  const onHandleDelete = async (from?: any, id?: number) => {
    if (from === "delete" && id) {
      setid(id);
      setDeletenoti((prev) => !prev);
    } else {
      handleDeleteShippingAddress(ID);
      setDeletenoti((prev) => !prev);
    }
  };

  const handleCancel = (formdata?: any) => {
    formdata.resetForm();
    setIsModal(!isModal);
  };

  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    {
      name: translate("profile", "Profile"),
      route: "#",
    },
  ];

  const sidebar_data = [
    {
      name: translate("my-profile", "My Profile"),
      link: "/user/profile",
    },
    {
      name: translate("my-order", "My Order"),
      link: "/user/my_orders",
    },
    {
      name: translate("my-wishlist", "My Wishlist"),
      link: "/user/wishlist",
    },
    {
      name: translate("change-password", "My Wishlist"),
      link: "/user/change_password",
    },
    {
      name: translate("logout", "Logout"),
      link: "logout",
    },
  ];

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      <StyledProfileCon>
        <Container className="profile-con container">
          <div className="row profile-row">
            <div className="col-md-12 col-lg-3">
              <StaticSidebar
                data={sidebar_data}
                route=""
                classname="side-bar"
              />
            </div>
            <div className="col-md-12 col-lg-9">
              <div className="title d-flex justify-content-between">
                <TextCom size="xxl" weight="lg">
                  {translate("my-profile", "My Profile")}
                </TextCom>
                <div className="edit-btn-con">
                  <ButtonCom
                    onClick={() => navigate("/user/profile_edit")}
                    color="light"
                    bgcolor="primary_light2"
                    btnBorderRadius="xxs"
                    text={
                      <div className="d-flex justify-content-center align-items-center">
                        <FiEdit color="white" className="mx-2" />
                        {translate("edit-profile", "Edit profile")}
                      </div>
                    }
                  ></ButtonCom>
                </div>
              </div>
              <div className="name mb-3">
                <TextCom color="border">{translate("name", "Name")}</TextCom>
                <TextCom>{getProfile_data?.name || "Username"}</TextCom>
              </div>
              <div className="phone mb-3">
                <TextCom color="border">{translate("phone", "phone")}</TextCom>
                <TextCom>{getProfile_data?.mobile || "09xxxxxxxxx"}</TextCom>
              </div>
              <div className="email mb-3">
                <TextCom color="border">{translate("email", "Email")}</TextCom>
                <TextCom>
                  {getProfile_data?.email || "xxxxxxxx@xxxx.com"}
                </TextCom>
              </div>
              {getProfile_data?.state?.name && (
                <div className="state mb-3">
                  <TextCom color="border">
                    {translate("state/division", "State/Division")}
                  </TextCom>
                  <TextCom>{getProfile_data?.state?.name}</TextCom>
                </div>
              )}
              {getProfile_data?.township?.name && (
                <div className="township mb-3">
                  <TextCom color="border">
                    {translate("township", "Township")}
                  </TextCom>
                  <TextCom>{getProfile_data?.township?.name}</TextCom>
                </div>
              )}
              {getProfile_data?.street && (
                <div className="add mb-3">
                  <TextCom color="border">
                    {translate("address", "Address")}
                  </TextCom>
                  <TextCom>{getProfile_data?.street}</TextCom>
                </div>
              )}
              <TextCom as="a" href="/user/change_password" link color="primary">
                {translate("change-password", "Change password")}
              </TextCom>
            </div>
          </div>
        </Container>
      </StyledProfileCon>
    </>
  );
};
