import styled from 'styled-components'

export const StyledVariantCon = styled.div`
`

export const StyledVariantItemCon = styled.div`
  &.photo-var{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .select-variant{
    width: fit-content;
    /* padding-left:5px; */
    select{
      padding-left: 5px;
      padding-block: 3px;
      height: auto;
      font-weight: 400;
    }
    select:disabled{
      background: lightgrey;
      color: white;
    }
    border-radius: 5px;
  }
  .att-card{
    width: fit-content;
  }
  .card_wrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    min-height: 40px;
    border-radius: 20px;
    padding: 3px 10px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: .3s ease-in-out;
    
    &:hover{
      border-color: #707070;
    }
  }
`