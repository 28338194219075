import React, { useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoMdArrowDropup } from "react-icons/io";
import { GrFormDown } from "react-icons/gr";

import {
  useHandleOther,
  useOther,
  useAuth,
  useCart,
  useHandleProduct,
  useProduct,
} from "hook";

import {
  PrimaryHeaderCom,
  TextCom,
  InputCom,
  ButtonCom,
  SearchCom,
  ModalCom,
} from "../../../common";
import { LangSwitch } from "./languageSwitch";

import { moneyFormat } from "utils";
import Icon from "asset/icon/pladco";
import { TbBrandAppleArcade } from "react-icons/tb";
import { math } from "polished";
import Image from "asset/icon/pladco";

type PrimaryHeaderProps = {};
export const PrimaryHeader: React.FC<PrimaryHeaderProps> = (props) => {
  const navigate = useNavigate();
  const { translate, languages, handleChangeLanguage } = useHandleOther();
  const { handleProductFilterChange } = useHandleProduct(props);
  const { langStore, dispatch, getContactUsPage_data } = useOther();
  const {
    getProductCategory_data,
    getBrandList_data,
    getWishlist_data,
    ProductAction,
    getProduct_data,
  } = useProduct();
  const { getProfile_data, AuthAction } = useAuth();
  const { createCart_data, CartAction, getShippingAddressById_data } =
    useCart();
  const { getCart_data } = useCart();
  const location = useLocation();
  let [productName, setProductName] = useState("");
  let [search_active, setSearch_active] = useState(false);
  let langCode = langStore?.code;
  const [state, setState] = useState({
    modalVisible: false,
  });

  const HandleSignOut = async () => {
    await dispatch(AuthAction.signOut());
    if (createCart_data?.order_id) {
      await dispatch(
        CartAction.deleteAllInCart({ order_id: createCart_data?.order_id })
      );
      await dispatch(CartAction.resetCart());
    }

    navigate(`/`);
  };

  const handleCheck = (e: any, value: any) => {
    handleChangeLanguage(value);
    e.target.classList.toggle("active");
  };

  // let [searching, setSearching] = useState(true);
  // const handleSearch = (e: any) => {
  //   let req_obj = {
  //     lang: langCode,
  //     keywords: encodeURI(productName),
  //     searching: false,
  //   };

  //   productName && dispatch(ProductAction.getProduct(req_obj));
  //   productName !== "" && navigate(`/search/${encodeURI(productName)}`);
  //   productName && setShow(!show);
  //   setProductName("");
  // };

  // let [show, setShow] = useState(false);
  // const handleSuggest = (e: any) => {
  //   setShow(true);
  //   let input: any = document.querySelector(".search-con input");
  //   let req_obj = {
  //     lang: langCode,
  //     page_number: 1,
  //     per_page: 10,
  //     keywords: e,
  //     sort_by: "",
  //     searching: true,
  //   };
  //   dispatch(ProductAction.getProduct(req_obj));
  //   input.addEventListener("blur", () => {
  //     setTimeout(() => {
  //       setShow(false);
  //     }, 1000);
  //   });
  // };
  // const handleCLick = (route: any) => {
  //   navigate(route);
  // };

  const handleGotoCart = () => {
    navigate(`/order/cart`);
  };

  let user_icon = document.querySelector(".user");
  let user_menu = document.querySelector(".user-menu");
  user_icon?.addEventListener("mouseover", () => {
    user_menu?.classList.add("active");
  });
  user_icon?.addEventListener("mouseout", () => {
    user_menu?.classList.remove("active");
  });

  const [route, setRoute] = useState("");
  const handleRouteChange = (route: any, brand?: any) => {
    if (route !== "#") {
      navigate(route, { replace: true, state: brand ? brand : "" });
      setRoute(route);
      !brand && window.location.reload();
    }
  };

  const onHandleChange = (filter_change_obj: any) => {
    handleProductFilterChange(filter_change_obj);
  };

  const handleCategoryChange = (item?: any, parentCategory?: any) => {
    navigate(`/products?category_ids=${item.category_id}`, {
      replace: true,
      state: parentCategory,
    });
    window.location.reload();
    if (item?.sub_category?.length === 0) {
      onHandleChange({
        type: "category",
        key: item?.key,
        max_price: item?.price_filter_max,
      });
    }
  };

  const handleWishlist = () => {
    if (getProfile_data) {
      navigate(`/user/wishlist`);
    } else {
      handleModal(true);
    }
  };

  const handleModal = (modalVisible: boolean) => {
    setState({
      ...state,
      modalVisible,
    });
  };

  let header_ref = useRef<HTMLDivElement>(null);

  // window.addEventListener("scroll", () => {
  //   if (window.scrollY > 20) {
  //     header_ref?.current?.classList.add("scroll-header");
  //   } else {
  //     header_ref?.current?.classList.remove("scroll-header");
  //   }
  // });

  var lastScrollTop = 0;

  // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
  window.addEventListener(
    "scroll",
    function () {
      // or window.addEventListener("scroll"....
      var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > lastScrollTop) {
        // downscroll code
        header_ref?.current?.classList.add("scroll-header");
      } else if (st < lastScrollTop) {
        // upscroll code
        header_ref?.current?.classList.remove("scroll-header");
      } // else was horizontal scroll
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    },
    false
  );

  return (
    <PrimaryHeaderCom>
      <div className="container primary-header-con" ref={header_ref}>
        <div
          className="logo-con"
          onClick={() => {
            handleRouteChange("/");
          }}
        >
          <img src={getContactUsPage_data?.logo1} alt="logo" />
          {/* <Link to="/" >
          </Link> */}
        </div>
        <div className="right-con">
          <div className="user-con">
            <SearchCom />
            <div className="account-con">
              <div className="d-flex flex-column align-items-center gap-1">
                <img
                  src={Icon.User}
                  alt="user-icon"
                  className="profile-icon"
                  onClick={() => {
                    getProfile_data?.name && navigate("/user/profile");
                  }}
                />
                {!getProfile_data?.name ? (
                  <div className="d-flex">
                    <Link to={"/user/signin"}>
                      <TextCom
                        size="sm"
                        style={{
                          borderRight: "1px solid gray",
                          paddingRight: "5px",
                          lineHeight: "16px",
                        }}
                      >
                        {translate("login", "Login")}
                      </TextCom>
                    </Link>
                    <Link to={"/user/signup"}>
                      <TextCom
                        size="sm"
                        style={{
                          paddingLeft: "5px",
                          lineHeight: "16px",
                        }}
                      >
                        {translate("signup", "Sign Up")}
                      </TextCom>
                    </Link>
                  </div>
                ) : (
                  <TextCom size="sm" textAlign="center">
                    {translate("my-chain-pro", "My ChainPro")}
                  </TextCom>
                )}
              </div>
              {getProfile_data?.name && (
                <div className="user-dropdown">
                  <IoMdArrowDropup className="arrow" />
                  {getProfile_data?.name && (
                    <ul className="user">
                      <li>
                        <Link to={"/user/profile"} className="d-flex">
                          <img src={Icon.User} alt="user-icon" />
                          <TextCom>{getProfile_data?.name}</TextCom>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/user/wishlist"} className="d-flex">
                          <img src={Icon.WishlistInfo} alt="user-icon" />
                          <TextCom>
                            {translate("my-wishlist", "Wishlist")}
                          </TextCom>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/user/my_orders"} className="d-flex">
                          <img src={Icon.fileIcon} alt="order" />
                          <TextCom>
                            {translate("order-history", "Order History")}
                          </TextCom>
                        </Link>
                      </li>
                      {/* <li>
                        <div onClick={HandleSignOut} className="d-flex">
                          <img src={Icon.logoutIcon} alt="order" />
                          <TextCom>{translate("logout", "Logout")}</TextCom>
                        </div>
                      </li> */}
                    </ul>
                  )}
                </div>
              )}
            </div>
            <div className="cart">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div
                  className="img-wrap"
                  style={{ position: "relative" }}
                  onClick={() => {
                    handleGotoCart();
                  }}
                >
                  <img src={Icon.Cart} alt="cart-icon" />
                  {getCart_data?.order_lines?.length > 0 ? (
                    <div className="cart-badge">
                      <TextCom color="light" size="sm">
                        {getCart_data?.cart_item_count > 999
                          ? Math.ceil(999) + "+"
                          : getCart_data?.cart_item_count}
                      </TextCom>
                    </div>
                  ) : (
                    <div className="cart-badge">
                      <TextCom color="light" size="sm">
                        {0}
                      </TextCom>
                    </div>
                  )}
                </div>
                <TextCom size="sm" textAlign="center">
                  <TextCom as="span" size={langCode === "en" ? "sm" : "md"}>
                    {getCart_data
                      ? moneyFormat(
                          Math.ceil(getCart_data?.total || 0),
                          langCode
                        )
                      : moneyFormat(0, langCode)}{" "}
                  </TextCom>
                  {translate("ks", "Ks")}
                </TextCom>
                {getCart_data && getCart_data?.order_lines?.length > 0 && (
                  <div className="cart-popup">
                    <IoMdArrowDropup className="arrow" />
                    <ul className="wrap">
                      {getCart_data?.order_lines?.map((item: any, key: any) => (
                        <li key={key}>
                          <div className="d-flex gap-2">
                            {item?.is_delivery_line === true ? (
                              <img
                                src={Image.Delivery}
                                className="deli-image"
                              />
                            ) : (
                              <img
                                src={item?.product_image || Icon.DefaultCard}
                              />
                            )}
                            <div>
                              <TextCom size="sm" weight="lg">
                                {item?.name}
                                <br />
                                {item?.note}
                              </TextCom>
                              <TextCom size="sm">
                                {translate("qty", "Qty")} :{" "}
                                <TextCom as="span">
                                  {moneyFormat(item?.quantity, langCode)}
                                </TextCom>
                              </TextCom>
                              <TextCom
                                size="sm"
                                weight="lg"
                                color="primary_light2"
                              >
                                <TextCom as="span" color="primary" weight='lg'>
                                  {moneyFormat(item?.price_subtotal, langCode)}
                                </TextCom>{" "}
                                {translate("ks", "Ks")}
                              </TextCom>
                            </div>
                          </div>
                          {item?.promotion_lines && (
                            <div className="d-flex promo gap-2">
                              <img
                                src={
                                  item?.promotion_lines[0]?.product_image
                                    ? item?.promotion_lines[0]?.product_image
                                    : item?.promotion_lines[0]?.reward_type ===
                                      "discount"
                                    ? Icon.PromotionTag
                                    : Icon.DefaultCard
                                }
                                className={
                                  item?.promotion_lines[0]?.reward_type ===
                                  "discount"
                                    ? "discount"
                                    : ""
                                }
                              />
                              <div>
                                <TextCom size="sm" weight="lg">
                                  {item?.promotion_lines[0]?.name}
                                </TextCom>
                                <TextCom
                                  size="sm"
                                  weight="lg"
                                  color="primary_light2"
                                >
                                  {item?.promotion_lines[0]?.price_subtotal ===
                                  0 ? (
                                    <TextCom
                                      as="span"
                                      className="free"
                                      size="xs"
                                      weight="lg"
                                    >
                                      {translate("free", "Free")}
                                    </TextCom>
                                  ) : (
                                    <>
                                      {
                                        <TextCom as="span" color="primary" weight='lg'>
                                          {moneyFormat(
                                            item?.promotion_lines[0]
                                              ?.price_subtotal,
                                            langCode
                                          )}
                                        </TextCom>
                                      }{" "}
                                      {translate("ks", "Ks")}
                                    </>
                                  )}
                                </TextCom>
                              </div>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                    <div className="total">
                      <div className="d-flex justify-content-between align-items-center">
                        <TextCom size="sm" weight="lg">
                          {translate("cart-total", "Cart Total")}
                        </TextCom>
                        <TextCom size="sm" weight="lg">
                          <TextCom as='span' weight='lg'>
                            {getCart_data
                              ? moneyFormat(
                                  Math.ceil(getCart_data?.total),
                                  langCode
                                )
                              : moneyFormat(0, langCode)}
                          </TextCom>{" "}
                          {translate("ks", "Ks")}
                        </TextCom>
                      </div>
                      <div className="d-flex gap-2">
                        <ButtonCom
                          text={translate("go-to-cart", "Go To Cart")}
                          bgcolor="primary_light2"
                          onClick={() => {
                            handleGotoCart();
                          }}
                        />
                        <ButtonCom
                          text={translate("checkout", "Checkout")}
                          bgcolor="primary_light2"
                          onClick={() => {
                            if (
                              getShippingAddressById_data &&
                              getShippingAddressById_data !== null &&
                              getShippingAddressById_data[0]
                            ) {
                              navigate(`../order/payment`);
                            } else {
                              navigate(`../order/checkout`);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {getProfile_data && (
              <div className="wishlist">
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  onClick={handleWishlist}
                >
                  <img src={Icon.Active_wishlistheart} />
                  <TextCom size="sm" textAlign="center">
                    {translate("wishlist", "Wishlist")}
                  </TextCom>
                </div>
                <div
                  className="wish-badge"
                  style={langStore?.code === "my" ? { right: "3px" } : {}}
                >
                  <TextCom color="light" size="xs" className="">
                    {getWishlist_data ? getWishlist_data?.length : 0}
                  </TextCom>
                </div>
                <ModalCom
                  isModal={state.modalVisible}
                  handleIsModal={handleModal}
                  isCloseBtn={true}
                >
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 6,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      <img
                        className="d-flex justify-content-center align-items-center m-auto mb-3"
                        src={Icon.WishlistInfo}
                        alt="wishlist-info"
                      />
                      <TextCom
                        style={{ textAlign: "center", marginBottom: 25 }}
                      >
                        {translate(
                          "please-login-to-get-wishlist",
                          "Please Login to get Wishlist"
                        )}
                      </TextCom>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textTransform: "uppercase",
                        }}
                      >
                        <ButtonCom
                          text="Login"
                          bgColor="dark"
                          color="light"
                          btnBorderRadius="xxxs"
                          onClick={() => handleRouteChange("/user/signin")}
                        />
                      </div>
                    </div>
                  </div>
                </ModalCom>
              </div>
            )}
            <LangSwitch selectStyle />
          </div>
          <div className="navigation">
            <ul
              className="main-nav"
              style={getProfile_data ? {} : { gap: "2.5rem" }}
            >
              <li className="d-flex justify-content-center align-items-center gap-2 product-nav nav-link">
                <TextCom className="navlink" size="xl" weight="md">
                  {translate("product", "Product")}
                </TextCom>
                <GrFormDown size={20} />
                <div className="large-menu">
                  <div className="container">
                    <div className="wrap">
                      {getProductCategory_data?.data?.map(
                        (category: any, key: any) => (
                          <div key={key}>
                            <div className="cate">
                              <TextCom
                                size="xl"
                                weight="md"
                                onClick={() => {
                                  handleCategoryChange(category, {
                                    id: category?.category_id,
                                    name: category?.category_name,
                                  });
                                }}
                                className="main-cate mb-2"
                              >
                                {category?.category_name}
                              </TextCom>
                              {category?.sub_category?.length > 0 && (
                                <ul>
                                  {category?.sub_category?.map(
                                    (sub: any, key: any) => (
                                      <li className="mb-2" key={key}>
                                        <TextCom
                                          onClick={() => {
                                            handleCategoryChange(sub, {
                                              main_id: category?.category_id,
                                              main_name:
                                                category?.category_name,
                                              id: sub?.category_id,
                                              name: sub?.category_name,
                                            });
                                          }}
                                        >
                                          {sub?.category_name}
                                        </TextCom>
                                        {sub?.sub_category?.length > 0 && (
                                          <ul className="sub2">
                                            {sub?.sub_category?.map(
                                              (sub2: any, key: any) => (
                                                <TextCom
                                                  onClick={() =>
                                                    handleCategoryChange(sub2, {
                                                      main_id:
                                                        category?.category_id,
                                                      main_name:
                                                        category?.category_name,
                                                      p_id: sub?.category_id,
                                                      p_name:
                                                        sub?.category_name,
                                                      id: sub2?.category_id,
                                                      name: sub2?.category_name,
                                                    })
                                                  }
                                                  key={key}
                                                >
                                                  {sub2?.category_name}
                                                </TextCom>
                                              )
                                            )}
                                          </ul>
                                        )}
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                            </div>
                          </div>
                        )
                      )}
                      <hr />
                    </div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-center align-items-center gap-2 product-nav nav-link">
                <TextCom className="navlink" size="xl" weight="md">
                  {translate("brand", "Brand")}
                </TextCom>
                <GrFormDown size={20} />
                <ul className="drop-down scrollable drop-down-scroll">
                  <li>
                    <IoMdArrowDropup className="arrow" color="#fff" />
                  </li>
                  {getBrandList_data?.all_data?.map((brand: any, key: any) => (
                    <li className="brand" key={key}>
                      <TextCom
                        size="lg"
                        weight="md"
                        className="main-cate"
                        onClick={() =>
                          handleRouteChange(`/products?brand_id=${brand?.ID}`, {
                            id: brand?.ID,
                            name: brand?.name,
                          })
                        }
                      >
                        {brand?.name}
                      </TextCom>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="nav-link">
                <TextCom
                  className={`navlink
                    ${location?.pathname === "/promotion" ? "active" : ""}`}
                  size="xl"
                  onClick={() => handleRouteChange("/promotion")}
                  weight="md"
                >
                  {translate("promotion", "Promotion")}
                </TextCom>
              </li>
              {getProfile_data && (
                <li className="nav-link">
                  <TextCom
                    className={`navlink
                      ${
                        location?.pathname === "/user/my_orders" ? "active" : ""
                      }`}
                    size="xl"
                    onClick={() => handleRouteChange("/user/my_orders")}
                    weight="md"
                  >
                    {translate("order-history", "Order History")}
                  </TextCom>
                </li>
              )}
              <li className="nav-link">
                <TextCom
                  className={`navlink ${
                    location?.pathname === "" ? "active" : ""
                  }`}
                  size="xl"
                  onClick={() => handleRouteChange("/aboutus")}
                  weight="md"
                >
                  {translate("why-chainpro", "Why ChianPro")}
                </TextCom>
              </li>
              <li className="d-flex justify-content-center align-items-center gap-2 product-nav nav-link">
                <TextCom
                  className="navlink"
                  size="xl"
                  weight="md"
                  onClick={() => handleRouteChange("#")}
                >
                  {translate("chainpro-care", "ChainPro Care")}
                </TextCom>
                <GrFormDown size={20} />
                <ul className="drop-down care">
                  <li>
                    <IoMdArrowDropup className="arrow" color="#fff" />
                  </li>
                  <li>
                    <TextCom size="xl" weight="md" as="a" href="/faq">
                      {translate("faq", "FAQ")}
                    </TextCom>
                  </li>
                  <li>
                    <TextCom
                      size="xl"
                      weight="md"
                      as="a"
                      href="/page/terms_and_condition"
                    >
                      {translate("terms-and-cons", "Terms & Conditions")}
                    </TextCom>
                  </li>
                  <li>
                    <TextCom
                      size="xl"
                      weight="md"
                      as="a"
                      href="/page/privacy_policy"
                    >
                      {translate("privacy-policy", "Privacy & Policy")}
                    </TextCom>
                  </li>
                  <li>
                    <TextCom
                      size="xl"
                      weight="md"
                      as="a"
                      href="/page/how_to_register"
                    >
                      {translate("how-to-register", "How to register")}
                    </TextCom>
                  </li>
                  <li>
                    <TextCom
                      size="xl"
                      weight="md"
                      as="a"
                      href="/page/how_to_buy"
                    >
                      {translate("how-to-buy", "How to buy")}
                    </TextCom>
                  </li>
                </ul>
              </li>
              <li className="nav-link">
                <TextCom
                  className={`navlink ${
                    location?.pathname === "/contactus" ? "active" : ""
                  }`}
                  size="xl"
                  onClick={() => handleRouteChange(`/contactus`)}
                  weight="md"
                >
                  {translate("contact-us", "Contact us")}
                </TextCom>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </PrimaryHeaderCom>
  );
};
