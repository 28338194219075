import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

import {
  StyledBreadcrumbCon,
  StyledBreadcrumbItemCon,
  StyledBreadcrumbItem,
} from "theme";

type BreadcrumbComProps = {
  data: any;
  isContainer: boolean;
  bgColor: string;
};

export const BreadcrumbCom: React.FC<BreadcrumbComProps> = ({
  data,
  isContainer = true,
  ...props
}) => {
  const navigate = useNavigate();

  const handleRoute = (x: any) => {
    if (x?.route !== "#") {
      navigate(x?.route === "/" ? "/" : `${x?.route}${x?.id}`, {
        replace: true,
        state: { id: x?.id, name: x?.name },
      });
      window.location.reload();
    }
  };

  return (
    <StyledBreadcrumbCon {...props}>
      <div className={isContainer ? "container" : "container-fluid"}>
        <StyledBreadcrumbItemCon>
          {data?.length > 0 &&
            data?.map(
              (x: any, i: number) =>
                x !== undefined &&
                x?.name !== undefined && (
                  <StyledBreadcrumbItem
                    key={i}
                    {...x}
                    onClick={() => handleRoute(x)}
                  >
                    {/* <a href="#"></a> */}
                    {x?.name}
                    <div className="divider">
                      /{/* <MdKeyboardArrowRight /> */}
                    </div>
                  </StyledBreadcrumbItem>
                )
            )}
        </StyledBreadcrumbItemCon>
      </div>
    </StyledBreadcrumbCon>
  );
};
