import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaQuestion } from "react-icons/fa";

import { ButtonCom, ModalCom, TextCom } from "../../common";
import {
  useHandleCart,
  useHandleOther,
  useAuth,
  hidePreview,
  useCart,
} from "hook";
import { StyledAddtoCartCon } from "theme";

type AddToCartButtonModProps = {
  navigate?: any;
  params?: any;
  w_item?: any;
  type?: string;
  htmlFor?: string;
  text?: string;
  bgColor?: string;
  color?: string;
  outline?: any;
  btntype?: any;
  borderColor?: string;
  btnBorderRadius?: string;
  onClick?: any;
  set?: any;
  w_index?: any;
  type_for?: any;
  disabled?: any;
  SVGL?: any;
  uomid?: any;
  setLoadState?: any;
};

export const AddToCartButtonMod: React.FC<AddToCartButtonModProps> = ({
  // navigate,
  params,
  w_item,
  w_index,
  type,
  type_for,
  set,
  disabled,
  uomid,
  setLoadState,
  ...props
}) => {
  const navigate: any = useNavigate();
  const { handleAddToCart } = useHandleCart();
  const { translate } = useHandleOther();
  const { getProfile_data } = useAuth();
  const { getCart_data } = useCart();
  const [state, setstate] = useState({
    modalVisible: false,
    desc: "",
  });
  const [buystate, setBuystate] = useState({
    modalVisible: false,
    desc: "",
  });

  const [buynow, setBuynow] = useState(false);

  const onHandleAddToCart = async () => {
    if (type !== "buynow") {
      setLoadState(true);
    }

    let res = await handleAddToCart(
      params?.id || params || w_item?.product_template_id,
      w_item,
      w_index,
      type_for,
      Number(uomid)
    );

    if (res?.payload?.status === "success") {
      if (type !== "buynow") {
        setLoadState(false);
      }
      if (type === "buynow") {
        handleRoute("/order/checkout");
        // if (getProfile_data) {
        //   handleRoute("/order/checkout");
        // } else {
        //   handleRoute("/order/payment");
        // }
      } else {
        if (set) {
          set(true);
        } else {
          handleModal(
            true,
            translate("product_succcessfully_cart", "Product successfully cart")
          );
        }
      }
    } else {
      if (type !== "buynow") {
        setLoadState(false);
      }
      // showToast({ type: 'error', placement: 'bottom', title: 'Something wrong add to cart process!' })
    }
  };

  if (buynow === true) {
    onHandleAddToCart();
    setBuynow(false);
  }

  const handleModal: any = (modalVisible: boolean, desc: string) => {
    setstate({
      ...state,
      modalVisible,
      desc,
    });
  };

  const handleBuynowModal: any = (modalVisible: boolean, desc: string) => {
    setBuystate({
      ...state,
      modalVisible,
      desc,
    });
  };

  const handleRoute: any = (route: any, params: any) => {
    navigate(route, { ...params, replace: true });
    handleModal(false);
    hidePreview();
  };

  return (
    <StyledAddtoCartCon>
      <ButtonCom
        type="outline"
        bgcolor={props?.bgColor || "transparent"}
        btnHeight={39}
        onClick={() =>
          type_for === "buynow" &&
          getCart_data !== undefined &&
          getCart_data !== null &&
          getCart_data?.order_lines?.length !== 0
            ? setBuystate({
                ...state,
                modalVisible: true,
              })
            : onHandleAddToCart()
        }
        {...props}
        disabled={disabled}
      />
      <div
        className="disable-layer"
        style={disabled === true ? { display: "block" } : { display: "none" }}
      ></div>
      <ModalCom
        isModal={state.modalVisible}
        handleIsModal={() => handleModal(false)}
      >
        <div>
          <div
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 26,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <TextCom
              color="text"
              style={{ textAlign: "center", marginBottom: 20 }}
            >
              {state?.desc}
            </TextCom>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ButtonCom
                color="light"
                bgcolor="primary_light2"
                btnBorderRadius="xxxs"
                text={translate("go-to-cart", "Go to cart")}
                onClick={() => handleRoute("/order/cart")}
              />
            </div>
          </div>
        </div>
      </ModalCom>
      <ModalCom
        isModal={buystate.modalVisible}
        handleIsModal={() => handleBuynowModal(false)}
      >
        <div className="d-flex flex-column justify-content-center align-items-center gap-3">
          <FaQuestion size={30} />
          <TextCom color="text" style={{ textAlign: "center" }}>
            {translate(
              "confim-buynow",
              "Are you sure you want to remove all from cart and buy this item?"
            )}
          </TextCom>
          <div className="modal_btn_con d-flex justify-content-center align-items-center gap-3 mt-2">
            <ButtonCom
              text={translate("cancel", "Cancel")}
              bgcolor="primary_light3"
              color="primary_light2"
              // btntype="outline"
              // borderColor="primary_light2"
              onClick={() => {
                setBuystate({
                  ...state,
                  modalVisible: false,
                });
                setBuynow(false);
              }}
              btnBorderRadius="xxxs"
              style={{ minWidth: "100px" }}
            />
            <ButtonCom
              color="light"
              bgcolor="primary_light2"
              text="Confirm"
              btnBorderRadius="xxxs"
              onClick={() => {
                setBuynow(true);
                setBuystate({
                  ...state,
                  modalVisible: false,
                });
              }}
              style={{ minWidth: "100px" }}
            />
          </div>
        </div>
      </ModalCom>
    </StyledAddtoCartCon>
  );
};
