import React, { useEffect } from "react";
import Media from "react-media";

import {
  TextCom,
  CardCom,
  ButtonCom,
  Container,
  NewsCard,
  BreadcrumbCom,
  Title,
} from "components";
import { useOther, useHandleOther } from "hook";

import { BsCalendar4Event } from "react-icons/bs";
import "./style.scss";

export type IAboutUsProps = {};

export const AboutUs: React.FC<IAboutUsProps> = (props) => {
  const { dispatch, OtherAction, langStore, getPageCode_data } = useOther();
  const { translate } = useHandleOther();
  const langCode = langStore?.code;

  useEffect(() => {
    dispatch(OtherAction.getPageCode({ code: "about-us", lang: langCode }));
    // dispatch(OtherAction.getNewsActivityList({ lang: langCode }));
  }, [langCode]);

  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    {
      name: translate("about-us", "About us"),
      route: "#",
    },
  ];

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      <Container className="container aboutus">
        <section className="row about-section">
          <div className="col-lg-6">
            <Title text={getPageCode_data?.name} />
            {/* <TextCom as="h1" size="xxxl" weight="600">
              {getPageCode_data?.name}
            </TextCom> */}
            <TextCom
              dangerouslySetInnerHTML={{
                __html: getPageCode_data?.description,
              }}
            />
          </div>
          <div className="col-lg-6">
            <img
              className="img-fluid border-radius"
              src={getPageCode_data?.image}
              alt=""
            />
          </div>
        </section>
        {getPageCode_data?.link && (
          <div className="mt-3">
            <iframe
              src={getPageCode_data?.link}
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              className="mt-2"
            ></iframe>
          </div>
        )}
      </Container>
    </>
  );
};
