import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";

import { useHandleOther } from "hook";
import { StyledLanguageSwitch } from "theme";
import { TextCom } from "components";

type LangSwitchProps = {
  selectStyle?: boolean;
  clickChangeStyle?: boolean;
  setDrawer?: any;
};

export const LangSwitch: React.FC<LangSwitchProps> = (props: any) => {
  const { translate, languages, handleChangeLanguage, langStore } =
    useHandleOther();
  const langCode = langStore?.code;

  const handleCheck = (e: any, value: any) => {
    handleChangeLanguage(value);
    e.target.classList.toggle("active");
    props?.setDrawer(false);
  };

  let en = languages?.[0];
  let mm = languages?.[1];

  return (
    <StyledLanguageSwitch>
      {props?.selectStyle ? (
        //select dropdown style
        <div className="language-select">
          <div className="current-lang d-flex justify-content-center align-items-center gap-2">
            <img src={langStore?.lan_icon} alt={langStore?.code} />
            <TextCom size="sm">{langStore?.lang}</TextCom>
            <BsChevronDown size="13" />
            <ul className="drop-down">
              {languages?.map((lang: any, key: any) => (
                <li
                  className={`d-flex ${
                    lang?.code === langStore?.code ? "active" : ""
                  }`}
                  onClick={(e: any) =>
                    lang?.code !== langStore?.code ? handleCheck(e, lang) : ""
                  }
                  key={key}
                >
                  {/* <img src={lang?.lan_icon} /> */}
                  <TextCom size="sm" weight="sm">
                    {lang?.lang}
                  </TextCom>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : props?.clickChangeStyle ? (
        <div
          className="lang-change d-flex flex-column align-items-center"
          onClick={(e) => {
            handleCheck(e, langStore?.code === "en" ? mm : en);
          }}
        >
          <img src={langStore?.lan_icon} alt={langStore?.code} />
          <TextCom size="sm">{langStore?.lang}</TextCom>
        </div>
      ) : (
        //default switch style
        <ul className="language-switch">
          {languages.length > 0 &&
            languages.map((lang: any, i: any) => {
              return (
                <li
                  className={`${
                    langStore?.code === lang?.code ? "active" : ""
                  }`}
                  key={i}
                  onClick={(e: any) => handleCheck(e, lang)}
                >
                  <TextCom>{lang?.lang}</TextCom>
                  {/* <img src={lang?.lan_icon} alt={lang?.lang} /> */}
                </li>
              );
            })}
        </ul>
      )}
    </StyledLanguageSwitch>
  );
};
