import styled from "styled-components";

export const StyledProductGroupDetail = styled.div`
.title-section{
    margin-bottom: 30px;
    .wrap{
      border-bottom: 1px solid ${props => props?.theme?.color?.typo?.line || '#ddd'};
    }
    h1{
        border-bottom: 2px solid ${props => props?.theme?.color?.typo?.primary};
        padding-bottom: 15px;
        width: fit-content;
        margin-bottom: -1px;
        @media(max-width: 991px){
          line-height: 40px;
          padding-bottom: 8px;
        }
    }
}
  /* padding-block: 50px; */
`