import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { FormContainerCom, ButtonCom, InputCom, TextCom } from "components";
import { useHandleAuth, useOther, useHandleOther } from "hook";
import { StyledAuthCon } from "theme";

type SignInProps = {};

export const SignIn: React.FC<SignInProps> = (props) => {
  const navigate = useNavigate();
  const { langStore } = useOther();
  const { handleSignIn } = useHandleAuth(props);
  const { translate } = useHandleOther();
  let langCode = langStore?.code;
  let location = useLocation();

  let initialValues: any = {
    phone: "",
    password: "",
  };

  const validationSchema = (yup: any) =>
    yup.object({
      phone: yup
        .string()
        .required(
          translate("enter-your-phone-number", "Enter Your Phone Number")
        ),
      password: yup
        .string()
        .required(translate("password-is-required", "Password is Required")),
    });

  const onDataSubmit = (values?: any, actions?: any) => {
    actions.setSubmitting(true);
    setTimeout(async () => {
      let post_data: any = {
        langCode,
        headers: {
          login: values?.phone,
          password: values?.password,
        },
      };
      handleSignIn(post_data);
      actions.setSubmitting(false);
      actions.resetForm();
    }, 1000);
  };

  const handleRouteChange = (route?: any, params?: any) => {
    navigate(route);
  };

  return (
    <StyledAuthCon>
      <div className="log-in-con">
        <div className="auth_con container">
          <div className="row row-wrap justify-content-center align-items-center">
            {/* <div className="col-lg-6 left-sec">
              <div className="d-flex">
                <TextCom weight="xl" size="xxl" color="background">
                  {translate("hello-friend", "Hello, friend")}
                </TextCom>
                <TextCom textAlign="center" className="center-text">
                  You don't have an account? Enter your personal details and
                  join with us
                </TextCom>
                <ButtonCom
                  btntype="outline"
                  btnBorderRadius="5"
                  onClick={() => handleRouteChange("/user/signup")}
                >
                  {translate("sign-up", "Sign up")}
                </ButtonCom>
              </div>
            </div> */}
            <div className="col-lg-6 form_card">
              <TextCom
                size="xxl"
                weight="xl"
                className="title_txt"
                textAlign="center"
              >
                {translate("sign-in", "Sign in")}
              </TextCom>
              <FormContainerCom
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values?: any, actions?: any) =>
                  onDataSubmit(values, actions)
                }
              >
                {(formikProps?: any) => (
                  <>
                    <InputCom
                      label={translate("phone", "Phone")}
                      name="phone"
                      type="tel"
                      required
                    />
                    <InputCom
                      label={translate("password", "Password")}
                      name="password"
                      type="password"
                      required
                    />
                    <div className="form_foot_first mt-2">
                      <TextCom
                        link
                        color="primary"
                        onClick={() =>
                          handleRouteChange("/user/forgot_password")
                        }
                      >
                        {translate("forgot-password", "Forgot password")}?
                      </TextCom>
                    </div>
                    <div className="form_foot_sec">
                      <ButtonCom
                        type="submit"
                        color="light"
                        bgcolor="primary_light2"
                        btnBorderRadius="xxxs"
                        text={
                          formikProps.isSubmitting
                            ? translate("signin....", "Sign In....")
                            : translate("signin", "Sign In")
                        }
                      />
                    </div>
                    {/* <div className="d-flex justify-content-center gap-3 mt-5">
                      <TextCom>
                        {translate(
                          `dont-have-a-account`,
                          `Don't have a account `
                        )}
                        ?
                      </TextCom>
                      <TextCom
                        as="a"
                        link
                        color="primary"
                        onClick={() => handleRouteChange("/user/signup")}
                      >
                        {translate("signup", "Sign Up")}
                      </TextCom>
                    </div> */}
                  </>
                )}
              </FormContainerCom>
              <TextCom textAlign="center" className="mt-2">
                {translate("dont-have-account", "Dont have an account?")}
                <TextCom
                  as="span"
                  color="primary"
                  onClick={() => handleRouteChange("/user/signup")}
                  style={{ marginLeft: "5px" }}
                  textDecoration="underline"
                >
                  {translate("sign-up", "Sign Up")}
                </TextCom>
              </TextCom>
            </div>
          </div>
        </div>
      </div>
    </StyledAuthCon>
  );
};
