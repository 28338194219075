import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  Container,
  TextCom,
  ProductCard,
  ProductCardMap,
  BreadcrumbCom,
  ButtonCom,
  Title,
} from "components";
import "./style.scss";
import { useProduct, useHandleOther } from "hook";
import { StyledPromoList } from "theme";
import Image from "../../asset/icon/pladco";

export type IPromoListProps = {};

export const PromoList: React.FC<IPromoListProps> = (props) => {
  const { getPromotion_data, ProductAction, getPromotionById_data, dispatch } =
    useProduct();
  const { translate, langStore } = useHandleOther();
  const param = useParams();
  const langCode = langStore?.code;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(ProductAction.getPromotion(langCode));
    dispatch(
      ProductAction.getPromotionById({
        id: param?.promoId ? param?.promoId : "",
        langCode,
      })
    );
  }, [langCode]);

  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    {
      name: getPromotionById_data?.name
        ? getPromotionById_data?.name
        : translate("promotions", "Promotions"),
      route: "#",
    },
  ];

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      <StyledPromoList className="container promolist">
        {param?.promoId && getPromotionById_data?.name ? (
          <>
            <section className="promo-section">
              {getPromotionById_data?.promotion_banner && (
                <div className="promotion-banner">
                  <img
                    className="img-fluid"
                    src={getPromotionById_data?.promotion_banner}
                    alt=""
                  />
                </div>
              )}
              <div className="promotion-desc">
                <Title
                  text={translate(
                    getPromotionById_data?.name,
                    getPromotionById_data?.name
                  )}
                />
                {/* <div className="title">
                  <TextCom as="h1" size="xxl" weight="xl">
                    {translate(
                      getPromotionById_data?.name,
                      getPromotionById_data?.name
                    )}
                  </TextCom>
                </div> */}
                <TextCom as="h2">
                  {translate(
                    getPromotionById_data?.description,
                    getPromotionById_data?.description
                  )}
                </TextCom>
              </div>
              <div className="promotion-products">
                <div className="row">
                  {getPromotionById_data?.product_list?.length > 0 && (
                    <ProductCardMap
                      data={getPromotionById_data?.product_list}
                      no_item={false}
                      className="col-lg-3 col-sm-4 col-6 p-card"
                    />
                  )}
                </div>
              </div>
            </section>
          </>
        ) : getPromotion_data?.length > 0 ? (
          getPromotion_data?.map((promotion: any, key: any) => (
            <section className="promo-section" key={key}>
              {promotion?.promotion_banner && (
                <div className="promotion-banner">
                  <img
                    className="img-fluid"
                    src={promotion?.promotion_banner}
                    alt=""
                  />
                </div>
              )}
              <div className="promotion-desc">
                <Title text={translate(promotion?.name, promotion?.name)} />
                {/* <div className="title">
                  <TextCom as="h1" size="xxl" weight="xl">
                    {translate(promotion?.name, promotion?.name)}
                  </TextCom>
                </div> */}
                <TextCom as="h2">
                  {translate(promotion?.description, promotion?.description)}
                </TextCom>
              </div>
              <div className="promotion-products">
                <div className="row">
                  <ProductCardMap
                    data={promotion?.product_list}
                    no_item={false}
                    className="col-lg-3 col-sm-4 col-6 p-card"
                  />
                </div>
              </div>
            </section>
          ))
        ) : (
          <div className="no-item">
            <Title text={translate("promotion", "Promotion")} />
            {/* <TextCom size="xxxl" weight="xl" className="title">
              {translate("promotion", "Promotion")}
            </TextCom> */}
            <div className="d-flex flex-column justify-content-center align-items-center gap-4 pt-5 pb-5">
              <img
                src={Image.ComingSoon}
                alt="empty-bag"
                className="img-fluid"
              />
              <ButtonCom
                text={translate("continue-shopping", "Continue Shopping")}
                bgColor="primary_light2"
                color="light"
                onClick={() => navigate("/")}
                btnBorderRadius="xxxs"
              />
            </div>
          </div>
        )}

        {/* <TextCom as='h1' size='xxxxl' textAlign='center' weight='xl'>Package Promotion</TextCom>
        <Container className='container-fluid'>
          <div className='row justify-content-center'>
            <ProductCardMap data={slide_data} no_item={false} no_border={true} className='col-md-3 col-sm-6 col-6' />
          </div>
        </Container> */}
      </StyledPromoList>
    </>
  );
};
